import React, { useState } from "react";
import logo from "./../../../../assets/img/password-reset.svg";
import { useFormik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import * as auth from "../_redux/authRedux";
import { resetPassword } from "../_redux/authCrud";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";
import { i18n } from "../../../pages/private/languageSelector";
import showPasswordIcon from "../../../../assets/img/iconShowPassword.svg";
import hidePasswordIcon from "../../../../assets/img/iconEye.svg";
import fatoratyLogo from "../../../../assets/img/dark-logo-fatoraty.svg";

const initialValues = {
  password: "",
  confirmPassword: "",
};

const PasswordReset = () => {
  const history = useHistory();
  const { token } = useParams();
  // eslint-disable-next-line
  const [loading, setLoading] = useState(false);
  const [passwordShow, setPasswordShow] = useState(false);
  const [passwordShowConfirm, setPasswordShowConfirm] = useState(false);

  const passwordValidation = i18n("Validation.INVALID_PASSWORD");
  const LoginSchema = Yup.object().shape({
    password: Yup.string()
      .min(8, i18n("Validation.Minimum8Symbols"))
      .max(20, i18n("Validation.Maximum50Symbols"))
      .matches(/^(?=.*[0-9])(?=.*[a-z]).{8,20}$/, passwordValidation)
      .required(i18n("Validation.RequiredField")),
    confirmPassword: Yup.string()
      .min(8, i18n("Validation.Minimum8Symbols"))
      .max(20, i18n("Validation.Maximum50Symbols"))
      .matches(/^(?=.*[0-9])(?=.*[a-z]).{8,20}$/, passwordValidation)
      .required(i18n("Validation.RequiredField")),
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      enableLoading();

      values.password !== values.confirmPassword
        ? setStatus(i18n("Validation.PasswordDoesntMatch"))
        : setTimeout(() => {
            resetPassword(token, values.password)
              .then(({ data: { token } }) => {
                disableLoading();
                history.push("/password-reset-next");
                toast.success("Password Reset Successfully");
              })
              .catch((err) => {
                disableLoading();
                setSubmitting(false);
                setStatus(
                  err.message === "Request failed with status code 404" ? i18n("Validation.InvalidToken") : i18n("Validation.ValidationError")
                );
              });
          }, 1000);
      disableLoading();
    },
  });

  const passwordShowHandler = () => {
    setPasswordShow(passwordShow ? false : true);
  };
  const passwordShowHandlerConfirm = () => {
    setPasswordShowConfirm(passwordShowConfirm ? false : true);
  };
  const selectedLanguage = localStorage.getItem("language");

  return (
    <div
      className="forget-password-next w-100 d-flex justify-content-center align-items-center flex-column"
      style={{ background: "#F2F2F2", height: "100vh" }}
    >
      {formik.status ? (
        <div
          className=" alert alert-custom p-0  text-center alert-light-danger alert-dismissible"
          style={{
            background: "#FBDDDD",
            borderTopLeftRadius: "10px",
            borderTopRightRadius: "10px",
            borderBottomLeftRadius: "0px",
            borderBottomRightRadius: "0px",
            marginBottom: "-0.1rem",
            minWidth: "350px",
          }}
        >
          <div className="alert-text py-2 font-weight-bold">{formik.status}</div>
        </div>
      ) : null}
      <div
        className="login-form "
        style={{
          minWidth: "350px",
          width: "auto",
          background: "white",
          textAlign: selectedLanguage === "arabic" ? "right" : "left",
          borderRadius: "10px",
          padding: "25px 18px 25px 18px",
        }}
      >
        <div>
          <div className={`form-title ${selectedLanguage === "arabic" ? "text-right" : "text-left"} `}>
            <img src={fatoratyLogo} alt="logo" height={"27px"} />
            <p className="mx-0 mt-5 auth-heading-main"> {i18n("ResetPassword.PleaseEnterANewPassword")}</p>
          </div>
          <form onSubmit={formik.handleSubmit}>
            <div className={`form-group with-icon ${selectedLanguage === "arabic" ? "rtl" : "ltr"}`}>
              <field>
                <input
                  placeholder={i18n("global.NewPassword")}
                  type={passwordShow ? "text" : "password"}
                  className="form-control"
                  name="password"
                  {...formik.getFieldProps("password")}
                />
                <label>{i18n("global.NewPassword")}</label>
                <span
                  onClick={passwordShowHandler}
                  style={{
                    float: selectedLanguage === "arabic" ? "left" : " right",
                    marginTop: "-30px",
                    position: "relative",
                    zIndex: "1",
                    cursor: "pointer",
                    width: "auto",
                  }}
                >
                  <img src={passwordShow ? showPasswordIcon : hidePasswordIcon} alt="eyeIcon" />
                </span>
                {formik.touched.password && formik.errors.password ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.password}</div>
                  </div>
                ) : null}
              </field>
            </div>
            <div className={`form-group with-icon ${selectedLanguage === "arabic" ? "rtl" : "ltr"}`}>
              <field>
                <input
                  placeholder={i18n("global.ConfirmNewPassword")}
                  type={passwordShowConfirm ? "text" : "password"}
                  className="form-control"
                  name="confirmPassword"
                  {...formik.getFieldProps("confirmPassword")}
                />
                <label>{i18n("global.ConfirmNewPassword")}</label>
                <span
                  onClick={passwordShowHandlerConfirm}
                  style={{
                    float: selectedLanguage === "arabic" ? "left" : " right",
                    marginTop: "-30px",
                    position: "relative",
                    zIndex: "1",
                    cursor: "pointer",
                    width: "auto",
                  }}
                >
                  <img src={passwordShowConfirm ? showPasswordIcon : hidePasswordIcon} alt="eyeIcon" />
                </span>
                {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.confirmPassword}</div>
                  </div>
                ) : null}
              </field>
            </div>

            <div className="d-flex flex-wrap justify-content-center" style={{ marginTop: "-1.5rem" }}>
              <button type="submit" className={`btn font-weight-bold px-9 py-4 my-3 w-100 auth-btn`} style={{ width: "auto" }}>
                <span> {i18n("ResetPassword.Submit")}</span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
export default connect(null, auth.actions)(PasswordReset);
