// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useEffect, useMemo, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, { PaginationProvider } from "react-bootstrap-table2-paginator";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../../_redux/products/productsActions";
import {
  getSelectRow,
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses,
} from "../../../../_metronic/_helpers";
import * as uiHelpers from "../ProductsUIHelpers";
import * as columnFormatters from "./column-formatters";
import { Card, CardBody, Pagination } from "../../../../_metronic/_partials/controls";
import { useProductsUIContext } from "../ProductsUIContext";
import { i18n } from "../../private/languageSelector";
import ProductHeader from "./ProductHeader";
import noProductIcon from "../../../../assets/img/noProduct.svg";
import PageNavigator from "../../../components/pagination/PageNavigator";
import { ProductEditPage } from "../ProductEditPage";
import { productsSlice } from "../../../../_redux/products/productsSlice";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

export function ProductsTable({ openAddProductPage, setOpenAddProductPage, isProductDuplicate, setIsProductDuplicate }) {
  // Products UI Context
  const productsUIContext = useProductsUIContext();
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [isActionSelected, setIsActionSelected] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  // const [openAddProductPage, setOpenAddProductPage] = useState(false);

  const productsUIProps = useMemo(() => {
    return {
      ids: productsUIContext.ids,
      setIds: productsUIContext.setIds,
      queryParams: productsUIContext.queryParams,
      setQueryParams: productsUIContext.setQueryParams,
      openEditProductDialog: productsUIContext.openEditProductDialog,
      openDeleteProductDialog: productsUIContext.openDeleteProductDialog,
      openViewProductDialog: productsUIContext.openViewProductDialog,
      openViewProductActionDialog: productsUIContext.openViewProductActionDialog,
    };
  }, [productsUIContext]);

  // Getting curret state of products list from store (Redux)
  const { currentState, productFilterState } = useSelector(
    (state) => ({
      currentState: state.products,
      productFilterState: state?.products?.productFilterState,
    }),
    shallowEqual
  );
  const { language } = useSelector((state) => state.auth);

  const { totalCount, entities, listLoading } = currentState;
  // Products Redux state
  const dispatch = useDispatch();
  useEffect(() => {
    // clear selections list
    productsUIProps.setIds([]);
    // server call by queryParams
    dispatch(actions.fetchProducts(productFilterState, productsUIProps.queryParams));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productsUIProps.queryParams, dispatch]);
  const { showProductsLayout } = useSelector((state) => state.products);

  const handleEditProductClick = (row, e) => {
    setSelectedProduct(row);
    if (e?.target && (e?.target?.tagName === "A" || e?.target?.tagName === "I")) {
      return;
    } else {
      // row?._id && productsUIProps.openEditProductDialog(row._id);
      row?._id && setOpenAddProductPage(true);
      dispatch(productsSlice.actions.showProductsLayout(false));
    }
  };
  // Table columns

  const columns = [
    {
      dataField: "sr_no",
      text: i18n("Category.ID"),
      sort: true,
      headerAlign: "start",
      headerStyle: {
        textAlign: "start",
        fontWeight: "400",
        fontSize: "14px",
        color: "#828282",
      },
      align: language === "arabic" ? "right" : "left",
      style: {
        paddingTop: "15px",
        paddingBottom: "15px",
        minWidth: "70px",
      },
      sortCaret: sortCaret,
      // headerSortingClasses,
      formatter: (cell, row) => (
        <>
          <div style={!row.active ? { color: "red" } : {}}>{cell}</div>
        </>
      ),
    },
    {
      dataField: "barcode",
      text: i18n("MENU.BARCODE"),
      sort: true,
      headerAlign: "start",
      headerStyle: {
        textAlign: "start",
        fontWeight: "400",
        fontSize: "14px",
        color: "#828282",
      },
      align: language === "arabic" ? "right" : "left",
      style: {
        paddingTop: "15px",
        paddingBottom: "15px",
        minWidth: "70px",
      },
      sortCaret: sortCaret,
      // headerSortingClasses,
      formatter: (cell, row) => (
        <>
          <div style={!row.active ? { color: "red" } : {}}>{cell && cell.length > 10 ? cell?.substring(0, 7) + "..." : cell}</div>
        </>
      ),
    },
    {
      dataField: "name",
      text: i18n("MENU.NAME"),
      sort: true,
      headerAlign: "start",
      headerStyle: {
        textAlign: "start",
        fontWeight: "400",
        fontSize: "14px",
        color: "#828282",
      },
      align: language === "arabic" ? "right" : "left",
      style: {
        paddingTop: "15px",
        paddingBottom: "15px",
        minWidth: "100px",
      },
      sortCaret: sortCaret,
      // headerSortingClasses,
      formatter: (cell, row) => (
        <>
          <div style={!row.active ? { color: "red" } : {}}>{cell && cell.length > 35 ? cell?.substring(0, 35) + "..." : cell}</div>
        </>
      ),
    },
    {
      dataField: "description",
      text: i18n("MENU.DESCRIPTION"),
      sort: true,
      classes: "f-module",
      // style: {
      //   minWidth: "50%",
      //   maxWidth: "100%",
      //   paddingRight: "100px",
      // },
      headerAlign: "start",
      headerStyle: {
        textAlign: "start",
        fontWeight: "400",
        fontSize: "14px",
        color: "#828282",
      },
      align: language === "arabic" ? "right" : "left",
      style: {
        paddingTop: "15px",
        paddingBottom: "15px",
        minWidth: "70px",
      },
      sortCaret: sortCaret,
      // headerSortingClasses,
      formatter: (cell, row) => (
        <>
          <div style={!row.active ? { color: "red" } : {}}>{cell && cell.length > 15 ? cell?.substring(0, 9) + "..." : cell}</div>
        </>
      ),
    },
    {
      dataField: "status",
      text: i18n("MENU.STATUS"),
      sort: true,
      headerAlign: "start",
      headerStyle: {
        textAlign: "start",
        fontWeight: "400",
        fontSize: "14px",
        color: "#828282",
      },
      align: language === "arabic" ? "right" : "left",
      style: {
        paddingTop: "15px",
        paddingBottom: "15px",
        minWidth: "100px",
      },
      sortCaret: sortCaret,
      // headerSortingClasses,
      formatter: (cell, row) => (
        <>
          <div style={!row.active ? { color: "red" } : {}}>{cell}</div>
        </>
      ),
    },
    {
      dataField: "category",
      text: i18n("MENU.CATEGORY"),
      sort: true,
      headerAlign: "start",
      headerStyle: {
        textAlign: "start",
        fontWeight: "400",
        fontSize: "14px",
        color: "#828282",
      },
      align: language === "arabic" ? "right" : "left",
      style: {
        paddingTop: "15px",
        paddingBottom: "15px",
        minWidth: "100px",
      },
      sortCaret: sortCaret,
      // headerSortingClasses,
      formatter: (cell, row) => (
        <>
          <div style={!row.active ? { color: "red" } : {}}>{cell}</div>
        </>
      ),
    },
    {
      dataField: "createdVia",
      text: i18n("MENU.CREATEDVIA"),
      sort: true,
      headerAlign: "start",
      headerStyle: {
        textAlign: "start",
        fontWeight: "400",
        fontSize: "14px",
        color: "#828282",
      },
      align: language === "arabic" ? "right" : "left",
      style: {
        paddingTop: "15px",
        paddingBottom: "15px",
        minWidth: "100px",
      },

      sortCaret: sortCaret,
      // headerSortingClasses,
      formatter: (cell, row) => (
        <>
          <div style={!row.active ? { color: "red" } : {}}>{cell}</div>
        </>
      ),
    },
    {
      dataField: "price",
      text: i18n("MENU.PRICE"),
      sort: true,
      sortCaret: sortCaret,
      // headerSortingClasses,
      headerClasses: "text-price ",
      headerAlign: "start",
      headerStyle: {
        textAlign: "start",
        fontWeight: "400",
        fontSize: "14px",
        color: "#828282",
      },
      align: language === "arabic" ? "right" : "left",
      style: {
        paddingTop: "15px",
        paddingBottom: "15px",
        minWidth: "70px",
      },
      formatter: (value, row) => (
        <>
          <div style={!row.active ? { color: "red" } : {}}>
            {value
              ? parseFloat(value)
                  .toFixed(2)
                  .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
              : ""}{" "}
          </div>
        </>
      ),
    },
    {
      dataField: "action",
      text: "",
      formatter: columnFormatters.ActionsColumnFormatter,
      formatExtraData: {
        // openEditProductDialog: productsUIProps.openEditProductDialog,
        // openDeleteProductDialog: productsUIProps.openDeleteProductDialog,
        // openViewProductDialog: productsUIProps.openViewProductDialog,
        openViewProductActionDialog: productsUIProps.openViewProductActionDialog,
      },
      classes: `${language === "arabic" ? "text-left" : "text-right"}  pr-0 relative`,
      headerClasses: `${language === "arabic" ? "text-left " : "text-right "} text-width`,
    },
  ];
  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: productsUIProps.queryParams.pageSize,
    page: productsUIProps.queryParams.pageNumber,
  };

  let filteredProducts = entities?.filter((product) => {
    let searchText = searchValue?.toLowerCase();
    return (
      product?.name?.toLowerCase()?.includes(searchText) ||
      product?.description?.toLowerCase()?.includes(searchText) ||
      product?.sr_no?.toLowerCase()?.includes(searchText) ||
      product?.barcode?.toLowerCase()?.includes(searchText)
    );
  });

  return (
    <>
      {!openAddProductPage ? (
        <Card className="p-0">
          <CardBody className="p-0">
            <PaginationProvider pagination={paginationFactory(paginationOptions)}>
              {({ paginationProps, paginationTableProps }) => {
                return (
                  // <Pagination isLoading={listLoading} paginationProps={paginationProps}>

                  // </Pagination>
                  <>
                    <ProductHeader
                      searchValue={searchValue}
                      setSelectedProduct={setSelectedProduct}
                      setSearchValue={setSearchValue}
                      setOpenAddProductPage={setOpenAddProductPage}
                    />
                    <BootstrapTable
                      wrapperClasses="table-responsive"
                      bordered={false}
                      classes={`table table-head-custom table-vertical-center overflow-hidden ${language === "arabic" ? "rtl" : "ltr"}`}
                      bootstrap4
                      remote
                      keyField="_id"
                      columns={columns}
                      data={entities === null ? [] : filteredProducts}
                      defaultSorted={uiHelpers.defaultSorted}
                      onTableChange={getHandlerTableChange(productsUIProps.setQueryParams)}
                      selectRow={getSelectRow({
                        entities,
                        ids: productsUIProps.ids,
                        setIds: productsUIProps.setIds,
                      })}
                      rowEvents={{
                        onClick: (e, row, rowIndex) => {
                          if (e.target.type !== "checkbox") {
                            handleEditProductClick(row, e);
                          }
                        },
                      }}
                      {...paginationTableProps}
                    >
                      <PleaseWaitMessage entities={entities} />
                      <NoRecordsFoundMessage entities={entities} />
                    </BootstrapTable>
                    {entities?.length > 0 && (
                      <div className="pb-3 d-flex justify-content-end mr-3 ml-3">
                        <PageNavigator
                          page={paginationProps?.page}
                          limit={paginationProps?.sizePerPage}
                          handlePageChange={paginationProps?.onPageChange}
                          handleLimitChange={paginationProps?.onSizePerPageChange}
                          data={filteredProducts}
                        />
                      </div>
                    )}

                    {entities && entities?.length < 1 && (
                      <>
                        <div className="d-flex flex-column justify-content-center align-items-center mt-5 mb-5">
                          <div className="category-icon-bg d-flex justify-content-center align-items-center ">
                            <img src={noProductIcon} alt="noCustomerIcon" />
                          </div>
                          <p className="py-3 you-have-no-category">{i18n("MENU.YouHaveNoProducts")}</p>
                        </div>

                        <div className="pb-3 mt-5 d-flex justify-content-end mr-3 ml-3">
                          <PageNavigator
                            page={paginationProps?.page}
                            limit={paginationProps?.sizePerPage}
                            handlePageChange={paginationProps?.onPageChange}
                            handleLimitChange={paginationProps?.onSizePerPageChange}
                            data={filteredProducts}
                          />
                        </div>
                      </>
                    )}
                  </>
                );
              }}
            </PaginationProvider>
          </CardBody>
        </Card>
      ) : (
        <>
          <div className={language === "arabic" ? "rtl" : "ltr"}>
            <ProductEditPage
              id={selectedProduct?._id ? selectedProduct?._id : undefined}
              view={selectedProduct?._id ? true : false}
              productName={selectedProduct?.englishName}
              setOpenAddProductPage={setOpenAddProductPage}
              isProductDuplicate={isProductDuplicate}
              setIsProductDuplicate={setIsProductDuplicate}
            />
          </div>
        </>
      )}
    </>
  );
}
