import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { Input, SwitchToggler, ReactSelector, TextArea } from "../../../../../_metronic/_partials/controls";
import * as storesActions from "../../../../../_redux/stores/storeActions";
import * as posActions from "../../../../../_redux/pos/posActions";
import { AsYouType } from "libphonenumber-js/max";
import { incrementCharacter } from "./../../../../../helpers/helpers";
import { i18n } from "../../../private/languageSelector";
import SubscriptionDialog from "../SubscriptionDialog";
import InvoiceDetailsPreview from "./invoiceDetailsPreview";
import ImageUploading from "react-images-uploading";
import SideInvoicePreview from "./SideInvoicePreview";
import { storesSlice } from "../../../../../_redux/stores/storeSlice";

let asYouType = new AsYouType();
Yup.addMethod(Yup.mixed, "validatePhoneNumber", function(msg) {
  return this.test({
    name: "validatePhoneNumber",
    message: msg,
    test: (value) => {
      if (value) {
        asYouType.reset();
        asYouType.input(value);
        return asYouType.getNumber().isValid();
      } else {
        return true;
      }
    },
  });
});
// Validation schema
export const AddStoreSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, "Minimum 3 Alphabets")
    .max(40, "Maximum 40 Alphabets")
    .required("English name is required"),
  // address: Yup.string().required("Address is required"),
  address: Yup.string()
    .min(0, "")
    .max(250, "Maximum 250 Alphabets"),
  // mobile: Yup.string()
  //   .validatePhoneNumber("Invalid Phone Number")
  //   .required("Phone number is required"),
  description: Yup.string()
    .min(0, "")
    .max(500, "Maximum 500 Alphabets"),
  storeDetails: Yup.string()
    .min(0, "")
    .max(500, "Maximum 500 Alphabets"),
  taxNumber: Yup.string()
    .matches(/^\d+$/, "Only numbers are allowed")
    .min(0, "")
    .max(15, "Maximum 15 Digits"),
  // invoiceLanguage: Yup.string(),
  identityPrefix: Yup.string().required("Store code is required"),
  showCustomerInformation: Yup.boolean(),
  showNotes: Yup.boolean(),
  // image: Yup.string(),
});

const initAddState = {
  name: "",
  // mobile: "",
  address: "",
  description: "",
  identityPrefix: "",
  storeDetails: "",
  taxNumber: "",
  invoiceLanguage: { label: "English", value: "en" },
  showCustomerInformation: false,
  showNotes: false,
  // image: "",
};

export function StoresEditForm({ edit, store, openStoresAddDialog, setOpenStoresAddDialog, setShowDeleteStoreModal }) {
  const dispatch = useDispatch();
  const [isFormFilled, setIsFormFilled] = useState(false);
  const [showUnsavedChangesModal, setShowUnsavedChangesModal] = useState(false);
  const [showInvoiceDialog, setShowInvoiceDialog] = useState(false);
  const [image, setImage] = useState();

  let languageOptions = [
    { label: "English", value: "en" },
    { label: "العربية", value: "ar" },
  ];
  const onHide = () => {
    setShowInvoiceDialog(false);
  };
  const { storeCode } = useSelector((state) => ({
    storeCode: state.pos.generatedCode,
  }));
  let generatedStoreCode = storeCode?.storeIdentityPrefix;
  useEffect(() => {
    dispatch(posActions.fetchLatestStorePosCode());
  }, [dispatch, openStoresAddDialog]);

  const handleCloseStoreDialog = (dirty, resetForm, setFieldValue) => {
    if (dirty) {
      setShowUnsavedChangesModal(true);
    } else {
      resetForm();
      setFieldValue("name", "");
      // setFieldValue("mobile", "");
      setFieldValue("address", "");
      setFieldValue("image", "");
      setFieldValue("taxNumber", "");
      setFieldValue("storeDetails", "");
      setOpenStoresAddDialog(false);
      dispatch(storesSlice.actions.showLayout(true));
    }
  };
  const { language } = useSelector((state) => state.auth);
  const { user } = useSelector((state) => ({
    user: state?.auth?.user,
  }));
  const onChange = (imageList, addUpdateIndex) => {
    setImage(imageList);
  };
  useEffect(() => {
    if (store?.image?.length > 0) {
      setImage([{ image_url: store?.image }]);
    }
  }, [store]);
  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={
          edit
            ? {
                ...store,
                name: store?.name === "Store 1" ? user?.companyId?.companyName : store?.name ? store?.name : store?.storeId,
                address: store?.address ? store?.address : "",
                description: store?.description ? store?.description : "",
                storeDetails: store?.storeDetails ? store?.storeDetails : "",
                taxNumber: store?.taxNumber ? store?.taxNumber : "",
                // image: store?.image ? store?.image : "",
                showCustomerInformation: store?.showCustomerInformation ? store?.showCustomerInformation : false,
                showNotes: store?.showNotes ? store?.showNotes : false,
                invoiceLanguage:
                  store?.invoiceLanguage === "en"
                    ? { label: "English", value: "en" }
                    : store?.invoiceLanguage && store?.invoiceLanguage === "ar"
                    ? { label: "العربية", value: "ar" }
                    : { label: "English", value: "en" },

                // mobile: store?.mobile ? store?.mobile : "",
              }
            : { ...initAddState, identityPrefix: incrementCharacter(generatedStoreCode) }
        }
        validationSchema={AddStoreSchema}
        onSubmit={(values) => {
          if (edit) {
            let updatedValues = {
              ...values,
              image:
                image && image[0]?.image_url && !image[0]?.image_url?.startsWith("http")
                  ? image[0]?.image_url
                  : image && image[0]?.image_url && image[0]?.image_url?.startsWith("http")
                  ? undefined
                  : "",
              invoiceLanguage: values?.invoiceLanguage?.value,
            };
            dispatch(storesActions.updateStore(store._id, updatedValues));
          } else {
            let updatedValues = { ...values, image: image && image[0]?.image_url, invoiceLanguage: values?.invoiceLanguage?.value };
            dispatch(storesActions.createStore(updatedValues));
          }
          setOpenStoresAddDialog(false);
          dispatch(storesSlice.actions.showLayout(true));
        }}
      >
        {({ handleSubmit, values, setValues, dirty, resetForm, setFieldValue, touched }) => {
          if (values.name?.length > 0) {
            setIsFormFilled(true);
          } else setIsFormFilled(false);

          return (
            <>
              <Form className={`form form-label-right ${language === "arabic" ? "rtl" : "ltr"}`}>
                {/* <Card> */}
                <div className="invoice-items row">
                  <div className="col-12">
                    {/* subHeader */}
                    <div
                      className={`store-sub-header ${language === "arabic" ? "arabic-lang " : ""}`}
                      style={{ marginLeft: "-7rem", marginTop: "-7rem" }}
                    >
                      {/* <div className={`date-header store-invoice-header w-100 ${language === "arabic" ? "rtl" : "ltr"} justify-content-start`}> */}
                      <div className={`date-header store-invoice-header w-100  justify-content-start`}></div>
                    </div>
                    {/* Actions */}
                  </div>
                  <div className="col-sm-6 col-12 store-form-wrapper">
                    <div className="d-flex justify-content-between align-items-center my-5">
                      <div style={{ fontWeight: 500, fontSize: "18px", color: "#333333" }}>
                        {!edit ? i18n("Company.CreateStore") : i18n("Company.EditStore")}
                      </div>

                      {/* <div className="active-text" onClick={() => setShowInvoiceDialog(true)}>
                        INVOICE PREVIEW
                      </div> */}
                      <div className="d-flex  align-items-center">
                        <div className="cancel-text" onClick={() => handleCloseStoreDialog(dirty, resetForm, setFieldValue)}>
                          {i18n("SubscriptionModals.CANCEL")}
                        </div>
                        <div
                          className={` ${language === "arabic" ? "mr-5" : "ml-5"} ${isFormFilled ? "active-text" : "save-text"}`}
                          onClick={() => handleSubmit()}
                        >
                          {i18n("UserProfile.SAVE")}
                        </div>
                      </div>
                    </div>
                    {/* logo */}
                    <div className="w-100 mx-auto p-5" style={{ background: "#FFFFFF", maxHeight: "170px" }}>
                      {/* {(!store?.image && image?.length <= 0 && (image === "undefined" || image === undefined)) ||
                      (!image && image === "undefined") ||
                      image === undefined ? (
                        <div
                          className="mx-auto d-flex justify-content-center align-items-center mt-4"
                          style={{ width: "100px", height: "100px", background: "#F2F2F2" }}
                        >
                          <img src={storeImg} alt="store" className="store-logo-sm" />
                        </div>
                      ) : image && image[0]?.data_url && edit && (image !== undefined || image !== "undefined") ? (
                        <div
                          className="mx-auto d-flex justify-content-center align-items-center mt-4"
                          style={{ width: "100px", height: "100px", background: "#F2F2F2" }}
                        >
                          <img src={image && image[0]?.data_url} alt="store" className="store-logo-sm" />
                        </div>
                      ) : (
                        <></>
                      )} */}
                      <ImageUploading value={image} onChange={onChange} dataURLKey="image_url">
                        {({ imageList, onImageUpload, onImageRemoveAll }) => (
                          <>
                            {imageList?.map((image, index) => {
                              return (
                                <>
                                  {/* {image && image["image_url"] && (image !== undefined || image !== "undefined") ? (
                                    <div
                                      className="mx-auto d-flex justify-content-center align-items-center mt-4"
                                      style={{ width: "100px", height: "100px" }}
                                    >
                                      <img src={image["image_url"]} alt="store" className="store-logo-sm" />
                                    </div>
                                  ) : null} */}
                                </>
                              );
                            })}
                            {image?.length > 0 ? (
                              <div
                                className="remove-logo-text text-center mb-0"
                                onClick={() => {
                                  onImageRemoveAll();
                                  setImage();
                                }}
                              >
                                {i18n("Company.RemoveLogo")}
                              </div>
                            ) : (
                              <div className="active-text text-center mb-0" onClick={onImageUpload}>
                                {i18n("Company.UploadLogo")}
                              </div>
                            )}
                          </>
                        )}
                      </ImageUploading>
                    </div>

                    {/* invoicepreview modal */}
                    <InvoiceDetailsPreview onHide={onHide} openInvoiceDialog={showInvoiceDialog} values={values} image={image} />
                    <div className="w-100 p-5 my-5" style={{ background: "#FFFFFF" }}>
                      {/* form data */}
                      <div className="form-group">
                        <Field name="name" component={Input} placeholder={i18n("global.StoreName")} label={i18n("global.StoreName")} maxLength={40} />
                        <div className="store-limit-text">{values?.name?.length}/40</div>
                      </div>
                      <div className="form-group mb-4">
                        <Field
                          name="identityPrefix"
                          label={i18n("global.StoreCode")}
                          component={Input}
                          placeholder={i18n("global.EnterStoreCode")}
                          withFeedbackLabel={true}
                          readOnly={edit}
                          disabled={true}
                        />
                      </div>
                      <div className="form-group" style={{ marginTop: "3.5rem" }}>
                        <Field
                          name="address"
                          component={Input}
                          placeholder={i18n("global.StoreAddress")}
                          label={i18n("global.StoreAddress")}
                          maxLength={250}
                        />
                        <div className=" store-limit-text">{values?.address?.length}/250</div>
                      </div>
                      {/* <div className="form-group">
                      <Field
                        component={Mobile}
                        name="mobile"
                        international
                        defaultCountry="SA"
                        style={{ display: "flex" }}
                        value={values?.mobile}
                        onChange={(value) => {
                          setValues({
                            ...values,
                            mobile: value,
                          });
                        }}
                        switchIt={true}
                      />
                    </div> */}
                      <div className="form-group">
                        {/* <Field name="taxNumber" type="number" component={Input} placeholder="Tax ID Number" label="Tax ID Number" maxLength={15} /> */}
                        <Field
                          name="taxNumber"
                          type="text"
                          component={Input}
                          placeholder={i18n("global.TaxIdNumber")}
                          label={i18n("global.TaxIdNumber")}
                          maxLength={15}
                          onChange={(e) => {
                            const numericValue = e.target.value.replace(/\D/g, ""); // Only numbers
                            setFieldValue("taxNumber", numericValue);
                          }}
                        />
                        <span className="tax-number-text">{i18n("global.TaxFeildInfo")}</span>
                      </div>
                      <div className="form-group">
                        {values?.storeDetails?.length > 0 ? (
                          <div
                            className={`${document?.activeElement?.name === "storeDetails" ? "store-small-text-active" : "store-small-text-normal"}`}
                          >
                            {i18n("global.StoreDetails")}
                          </div>
                        ) : (
                          <div className="store-small-text">{i18n("global.StoreDetails")}</div>
                        )}
                        <Field
                          name="storeDetails"
                          style={{ fieldSizing: "content", height: "auto" }}
                          component={TextArea}
                          placeholder={i18n("global.StoreDetails")}
                          label={i18n("global.StoreDetails")}
                          maxLength={500}
                        />
                        <div className=" store-limit-text">{values?.storeDetails?.length}/500</div>
                      </div>
                      <div className="form-group">
                        {values?.description?.length > 0 ? (
                          <div
                            className={`${document?.activeElement?.name === "description" ? "store-small-text-active" : "store-small-text-normal"}`}
                          >
                            {i18n("global.InvoiceFooter")}
                          </div>
                        ) : (
                          <div className="store-small-text"> {i18n("global.InvoiceFooter")}</div>
                        )}
                        <Field
                          name="description"
                          style={{ fieldSizing: "content", height: "auto" }}
                          component={TextArea}
                          placeholder={i18n("global.InvoiceFooter")}
                          label={i18n("global.InvoiceFooter")}
                          maxLength={500}
                        />
                        <div className=" store-limit-text">{values?.description?.length}/500</div>
                      </div>

                      <div className="d-flex">
                        <div className="form-group input-select w-100">
                          <div className={`${"store-small-text-normal"}`} style={{ marginBottom: "-1.23rem" }}>
                            {i18n("global.InvoiceLanguage")}
                          </div>
                          <Field
                            name="invoiceLanguage"
                            placeholder={i18n("global.InvoiceLanguage")}
                            component={ReactSelector}
                            options={languageOptions}
                            onChange={(option) => {
                              setFieldValue("invoiceLanguage", option);
                            }}
                            touched2={touched}
                          />
                        </div>
                      </div>
                      <div className=" d-flex justify-content-between align-items-center">
                        <div>{i18n("global.ShowNotes")}</div>
                        <div style={{ marginTop: "-2rem" }}>
                          <SwitchToggler
                            className="default-switch"
                            id="showNotes"
                            name="showNotes"
                            checked={values?.showNotes}
                            // value="showNotes"
                            onChange={(e) => {
                              setFieldValue("showNotes", e.target.checked);
                            }}
                          />
                        </div>
                      </div>
                      <div className=" d-flex justify-content-between mt-5 align-items-center">
                        <div>{i18n("global.ShowCustomerInfo")}</div>
                        <div style={{ marginTop: "-2rem" }}>
                          <SwitchToggler
                            className="default-switch"
                            id="showCustomerInformation"
                            name="showCustomerInformation"
                            checked={values?.showCustomerInformation}
                            // value="showCustomerInformation"
                            onChange={(e) => {
                              setFieldValue("showCustomerInformation", e.target.checked);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="p-5 w-100 preview-invoice-card">
                      <div className="w-100">
                        <div className="active-text text-center" onClick={() => setShowInvoiceDialog(true)}>
                          {i18n("Company.InvoicePreview")}
                        </div>
                      </div>
                    </div>
                    {edit ? (
                      <div className="p-5 mt-4 w-100 store-delete-card">
                        {" "}
                        <div className="w-100">
                          <div className="text-center remove-logo-text">
                            <div onClick={() => setShowDeleteStoreModal(true)}>{i18n("Discount.DELETE")}</div>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                  <div className="col-6" style={{ maxWidth: "350px", marginTop: "4rem" }}>
                    <SideInvoicePreview store={store} values={values} image={image} />
                  </div>{" "}
                </div>
                {/* </Card> */}

                <SubscriptionDialog
                  modalWidth={"350px"}
                  modalHeight={"185px"}
                  modalBackgroundColor={"#FFFFFF"}
                  show={showUnsavedChangesModal}
                  onHide={() => {
                    setShowUnsavedChangesModal(false);
                    dispatch(storesSlice.actions.showLayout(true));
                  }}
                  title={i18n("SubscriptionModals.UnsavedChanges")}
                  titleWidth={"140px"}
                  titleHeight={"20px"}
                  titleFontWeight={"700"}
                  titleFontSize={"16px"}
                  titleLineHeight={"20px"}
                  titleColor={"#333333"}
                  message={i18n("SubscriptionModals.AreYouSureYouWantToContinueWithoutSavingChanges")}
                  messageWidth={"320px"}
                  messageHeight={"44px"}
                  messageFontSize={"16px"}
                  messageFontWeight={"400"}
                  messageLineHeight={"21.79px"}
                  messageColor={"#333333"}
                  primaryLabel={i18n("SubscriptionModals.CANCEL")}
                  primaryAction={() => {
                    setShowUnsavedChangesModal(false);
                    dispatch(storesSlice.actions.showLayout(true));
                  }}
                  primaryLabelWidth={"55px"}
                  primaryLabelHeight={"20px"}
                  primaryLabelFontWeight={"700"}
                  primaryLabelFontSize={"14px"}
                  primaryLabelLineHeight={"20px"}
                  primaryLabelColor={"#828282"}
                  secondaryAction={() => {
                    setShowUnsavedChangesModal(false);
                    setOpenStoresAddDialog(false);
                    setIsFormFilled(false);
                    resetForm();
                    dispatch(storesSlice.actions.showLayout(true));
                  }}
                  secondaryLabel={i18n("SubscriptionModals.CONTINUE")}
                  secondaryLabelWidth={"75px"}
                  secondaryLabelHeight={"20px"}
                  secondaryLabelFontWeight={"700"}
                  secondaryLabelFontSize={"14px"}
                  secondaryLabelLineHeight={"20px"}
                  secondaryLabelColor={"#2D9CDB"}
                  isCenteredOnMobile
                />
              </Form>
            </>
          );
        }}
      </Formik>
    </>
  );
}
