import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { connect, useDispatch } from "react-redux";
import * as auth from "../_redux/authRedux";
import { login, updateUserProfile } from "../_redux/authCrud";
import { i18n } from "../../../pages/private/languageSelector";
import { Storage } from "react-jhipster";
import { FATORATAY_AUTH } from "../../../../../src/helpers/constants";
import { useHistory } from "react-router-dom";
import axios from "axios";
import showPasswordIcon from "../../../../assets/img/iconShowPassword.svg";
import hidePasswordIcon from "../../../../assets/img/iconEye.svg";
import AuthCard from "../../../components/Card/AuthCard";
import * as analyticsActions from "../../../../_redux/analytics/analyticsSlice";

const initialValues = {
  email: "",
  password: "",
  rememberMe: false,
};

function Login(props) {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [platformInfo, setPlatformInfo] = useState({
    platform: "",
    ipAddress: "",
    timeZone: "",
  });
  const dispatch = useDispatch();
  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email(i18n("Validation.WrongEmailFormat"))
      .min(3, i18n("Validation.Minimum3Symbols"))
      .max(50, i18n("Validation.Maximum50Symbols"))
      .required(i18n("Validation.EmailIsRequired")),
    password: Yup.string()
      .min(8, i18n("Validation.Minimum8Symbols"))
      .max(50, i18n("Validation.Maximum50Symbols"))
      .required(i18n("Validation.RequiredField")),
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      enableLoading();
      setTimeout(() => {
        login(values.email, values.password, platformInfo)
          .then(({ data: { token, user } }) => {
            disableLoading();
            if (values.rememberMe) {
              Storage.local.set(FATORATAY_AUTH, token);
            } else {
              Storage.session.set(FATORATAY_AUTH, token);
            }
            props.login(token, values.rememberMe);
            let language = localStorage.getItem("language") || "english";
            updateUserProfile(user?._id, {
              ...user,
              language: language,
            });
          })
          .then(() => {
            dispatch(analyticsActions.setShowEmailConfirmationDialog(true));

            history.push("/");
          })
          .catch((error) => {
            disableLoading();
            setSubmitting(false);
            setStatus(error?.response?.data?.message);
          });
      }, 1000);
    },
  });
  const [passwordShow, setPasswordShow] = useState(false);
  const passwordShowHandler = () => {
    setPasswordShow(passwordShow ? false : true);
  };

  const getPlatformInfo = async () => {
    const response = await axios.get("https://api.ipify.org?format=json");
    const countryInfo = await axios.get(`https://ipapi.co/${response?.data?.ip}/json/`);
    const browserInfo = await axios.get("https://api.ipregistry.co/?key=td089a1mybgzdxyb");
    setPlatformInfo({
      platform: browserInfo?.data?.user_agent?.type === "browser" ? "Web" : "App",
      ipAddress: countryInfo?.data?.ip,
      timeZone: countryInfo?.data?.timezone,
    });
  };
  useEffect(() => {
    getPlatformInfo();
  }, []);
  const selectedLanguage = localStorage.getItem("language");

  return (
    <div style={{ minWidth: "349px", width: "100%" }}>
      <AuthCard
        id={"kt_login_forgot"}
        error={formik.status?.includes("Invalid Email or Password") ? i18n("Validation.InvalidEmailOrPassword") : formik.status}
        text={i18n("login.DontHaveAnAccountYet")}
        link={"/auth/registration"}
        linkText={i18n("login.RegisterNow")}
        selectedLanguage={selectedLanguage}
      >
        <div id="kt_login_signin_form">
          <div className={`form-title ${selectedLanguage === "arabic" ? "text-right" : "text-left"}`}>
            <h1>{i18n("login.LoginToFatoraty")}</h1>
            <p>{i18n("login.EnterYourUserNameAndPassword")}</p>
          </div>
          <form onSubmit={formik.handleSubmit} className="form fv-plugins-bootstrap fv-plugins-framework">
            <div className="form-group with-icon">
              <field>
                <input
                  placeholder={i18n("global.Email")}
                  type="email"
                  className={`form-control thick-border ${formik.touched.email && formik.errors.email ? "red-border" : ""}`}
                  name="email"
                  {...formik.getFieldProps("email")}
                />
                <label>{i18n("global.Email")}</label>
                {formik.touched.email && formik.errors.email ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.email}</div>
                  </div>
                ) : null}
              </field>
            </div>
            <div className="form-group with-icon">
              <field>
                <input
                  placeholder={i18n("global.Password")}
                  type={passwordShow ? "text" : "password"}
                  className={`form-control thick-border ${formik.touched.password && formik.errors.password ? "red-border" : ""}`}
                  name="password"
                  {...formik.getFieldProps("password")}
                />
                <label>{i18n("global.Password")}</label>
                <span
                  onClick={passwordShowHandler}
                  style={{
                    float: selectedLanguage === "arabic" ? "left" : " right",
                    marginTop: "-30px",
                    position: "relative",
                    zIndex: "1",
                    cursor: "pointer",
                    width: "auto",
                  }}
                >
                  <img src={passwordShow ? showPasswordIcon : hidePasswordIcon} alt="eyeIcon" />
                </span>
                {formik.touched.password && formik.errors.password ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.password}</div>
                  </div>
                ) : null}
              </field>
            </div>

            <div className="d-flex flex-wrap justify-content-between align-items-center mb-3">
              <label className="checkbox ">
                <input
                  type="checkbox"
                  name="rememberMe"
                  className={`${selectedLanguage === "arabic" ? "ml-2" : "mr-2"}`}
                  {...formik.getFieldProps("rememberMe")}
                  {...(formik.values.rememberMe ? { checked: true } : {})}
                />
                <span className={`${selectedLanguage === "arabic" ? "ml-2" : "mr-2"}`}></span>
                {i18n("login.RememberMe")}
              </label>
              <Link to="/auth/forgot-password" className="text-dark-50 text-hover-primary mr-2" id="kt_login_forgot">
                {i18n("login.ForgotPassword")}
              </Link>
            </div>
            <button
              id="kt_login_signin_submit"
              type="submit"
              disabled={formik.isSubmitting}
              className={`btn font-weight-bold px-9 py-4 my-3 w-100 auth-btn`}
              style={{ width: "auto" }}
            >
              <span>{i18n("login.SignIn")}</span>
              {loading && <span className="ml-3 spinner spinner-white"></span>}
            </button>

            {/* <div className="d-block w-100 text-center">
            <Link to="/en/privacy-policy" className="text-dark-50 text-hover-primary mr-2" id="kt_login_forgot">
              {i18n("login.PrivacyPolicy")}
            </Link>
          </div> */}
          </form>
        </div>
      </AuthCard>
    </div>
  );
}

export default connect(null, auth.actions)(Login);
