import React, { useState, useEffect } from "react";
import "./PdfInvoicePageHtml.css";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { Modal } from "react-bootstrap";
import IconQr from "../../../../assets/img/icon-qr.svg";
import IconPdf from "../../../../assets/img/icon-pdf.svg";
import IconPrint from "../../../../assets/img/icon-print.svg";
import IconClose from "../../../../assets/img/closeIconInvoice.svg";
import IconOptions from "../../../../assets/img/optionsIcon.svg";
import { Dropdown } from "react-bootstrap";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import IconFatoraty from "../../../../assets/img/icon-fatoraty.svg";
import * as actions from "../../../../_redux/invoices/invoicesActions";
import CompanyLogo from "../../../../_metronic/_assets/img/default-picture.png";
import { PosInvoicePreview } from "./PosInvoicePreview";
import { i18n } from "../../private/languageSelector";
export const InvoiceMobileDialog = ({ id, show, onHide, setShowShareModal, setShowDeleteModal }) => {
  const dispatch = useDispatch();

  const [invoiceData, setInvoiceData] = useState();
  const [companyData, setCompanyData] = useState();
  const [showActionsDropdown, setShowActionsDropdown] = useState(false);
  const [products, setProducts] = useState([]);
  const [filterTaxess, setFilterTaxess] = useState([]);

  const { invoice } = useSelector(
    (state) => ({
      invoice: state?.invoices?.invoiceForEdit,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (id) {
      dispatch(actions.fetchInvoice(id));
    }
  }, [dispatch, id, show]);

  useEffect(() => {
    if (invoice !== undefined || invoice !== "undefined" || invoice?.length > 0) {
      setProducts([invoice?.invoiceData?.products]);
    }
  }, [invoice]);

  useEffect(() => {
    if (invoice) {
      let customerName =
        invoice?.invoiceData?.channel === "web"
          ? invoice?.invoiceData?.customerName
          : invoice?.invoiceData?.customerName?.first
          ? invoice?.invoiceData?.customerName?.first + " " + invoice?.invoiceData?.customerName?.last
          : "";

      let receieverName =
        invoice?.invoiceData?.channel === "web"
          ? invoice?.invoiceData?.receiverInformation?.name
          : invoice?.invoiceData?.receiverInformation?.name?.first
          ? invoice?.invoiceData?.receiverInformation?.name?.first + " " + invoice?.invoiceData?.receiverInformation?.name?.last
          : "";
      setInvoiceData({
        ...invoice,
        invoiceData: {
          ...invoice?.invoiceData,
          customerName: customerName,
          receiverInformation: {
            ...invoice?.invoiceData?.receiverInformation,
            name: receieverName,
          },
        },
      });
      setCompanyData({ ...invoice?.company });
    }
  }, [dispatch, invoice, show]);
  const { language } = useSelector((state) => state.auth);

  const ActionsDropdown = () => {
    return (
      <>
        <Dropdown align="end">
          <Dropdown.Toggle as="div" className="my-toggle-dropdown" id="dropdown-toggle-my-cart">
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip id="language-panel-tooltip" style={{ visibility: "hidden" }}>
                  Select Option
                </Tooltip>
              }
            >
              <img className="h-25px rounded" src={IconOptions} alt="language selector" role="button" id="dropdownMenuLink" />
            </OverlayTrigger>
          </Dropdown.Toggle>
          <Dropdown.Menu className="py-2 mt-1 dropdown-menu rounded">
            <Dropdown.Item
              className="d-flex align-items-center dropdown-item"
              href="#"
              onClick={() => {
                setShowShareModal(true);
                onHide();
              }}
            >
              <span className="ml-2 mr-2">{i18n("Customer.Share")}</span>
            </Dropdown.Item>
            <Dropdown.Item
              className="d-flex align-items-center dropdown-item"
              href="#"
              onClick={() => {
                setShowDeleteModal(true);
                onHide();
              }}
            >
              <span className="ml-2 mr-2">{i18n("Customer.Delete")}</span>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </>
    );
  };

  // function calculateDiscounts(products) {
  //   const discountsMap = {};
  //   if (products) {
  //     products.forEach((product) => {
  //       product.discounts.forEach((discount) => {
  //         if (discount.isSelected) {
  //           const key = discount.name;
  //           if (!discountsMap[key]) {
  //             discountsMap[key] = 0;
  //           }
  //           if (discount.type === "%") {
  //             discountsMap[key] += parseFloat((discount.value * product.price) / 100);
  //           } else {
  //             discountsMap[key] += parseFloat(discount.value);
  //           }
  //         }
  //       });
  //     });
  //   }

  //   return discountsMap;
  // }

  function calculateDiscounts(products) {
    const discountsMap = {};
    if (products) {
      products.forEach((product) => {
        const quantity = product.quantity || 1; // Default to 1 if quantity is not provided
        let remainingPrice = product.price * quantity; // Start with the total price

        product.discounts.forEach((discount) => {
          // if (discount.isSelected) {
          const key = discount.name;
          if (!discountsMap[key]) {
            discountsMap[key] = 0;
          }

          let discountValue = 0;
          if (discount.type === "%") {
            discountValue = Number((discount.value * remainingPrice) / 100); // Apply percentage discount
          } else {
            // discountValue = parseFloat(discount.value); // Apply fixed discount
            discountsMap[key] = Number(discount.value);
          }

          discountsMap[key] += discountValue; // Add to the map
          remainingPrice -= discountValue; // Deduct this discount from the remaining price
          // }
        });
      });
    }
    return discountsMap;
  }

  const filterTaxes = (products) => {
    if (products) {
      const allProductTaxes = [];
      for (let i = 0; i < products?.length; i++) {
        if (products[i] && products[i].taxes) {
          products[i].taxes.forEach((tax) => {
            allProductTaxes.push(tax);
          });
        }
      }
      let taxesUnique = [];
      const uniqueTaxesByProduct = [];
      const uniqueArray = allProductTaxes.map((tax) => {
        const arr = allProductTaxes.filter((item) => item._id === tax._id);
        if (arr.length) {
          let includedValue = arr.reduce((a, b) => {
            return a + b.includedValue;
          }, 0);
          let excludedValue = arr.reduce((a, b) => {
            return a + b.exclusiveVaue;
          }, 0);
          taxesUnique = [...taxesUnique, arr];
          uniqueTaxesByProduct.push({ ...tax, includedValue, excludedValue });
        }
      });
      const newuniqueArray = uniqueTaxesByProduct.filter(function(value, index, self) {
        return index === self.findIndex((t) => t._id === value._id);
      });

      return newuniqueArray?.length ? newuniqueArray : allProductTaxes;
    }
  };
  useEffect(() => {
    if (products) {
      let includedTaxes = filterTaxes(products?.flat());
      setFilterTaxess([...includedTaxes]);
    }
  }, [products]);
  const aggregatedDiscounts = calculateDiscounts(invoice?.invoiceData?.products || []);
  return (
    <div>
      <Modal
        size="lg"
        // className="modal-drawer invoice-drawer-info"
        className={`${language === "arabic" ? "modal-dialog-arabic modal-drawer" : " modal-drawer"}  my-profile invoice-drawer`}
        show={show}
        onHide={() => {}}
        backdrop={true}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header className="bg-white">
          <div className={`d-flex justify-content-between align-items-center ${language === "arabic" ? "flex-row-reverse" : "flex-row"}`}>
            <div onClick={() => onHide(false)} className="cursor-pointer">
              <img src={IconClose} alt="close" />
            </div>
            <div className="cursor-pointer" onClick={() => setShowActionsDropdown(!showActionsDropdown)}>
              {/* <img src={IconOptions} alt="options" /> */}
              {ActionsDropdown()}
            </div>
          </div>
        </Modal.Header>

        <Modal.Body className="overlay overlay-block cursor-default p-0">
          {/* {invoice?.invoiceData?.channel === "web" ? ( */}
          <div>
            <div className="html-invoice-mobile">
              {/* //////header section////// */}
              {/* <div className="d-flex justify-content-between header"> */}
              {/* <div className="header-img">
                    <img src={IconFatoraty} alt="loading" />
                  </div>
                  <div className="d-flex">
                    <div className="mt-5 mr-5">
                      <img
                        src={IconPdf}
                        onClick={(e) => {
                          e.preventDefault();
                          window.open(`${process.env.REACT_APP_URL}view/invoice/${invoiceData?._id}`, "_blank");
                        }}
                        alt="loading"
                      />
                    </div>
                    <div className="mr-3 mt-5">
                      <img
                        src={IconPrint}
                        onClick={(e) => {
                          e.preventDefault();
                          window.open(`${process.env.REACT_APP_URL}view/invoice/${invoiceData?._id}`, "_blank");
                        }}
                        alt="loading"
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <div className="header-img-company">
                    <img style={{ maxWidth: "250px" }} src={companyData?.image ? companyData?.image : CompanyLogo} alt="loading" />
                  </div> */}

              {/* company detail */}

              {/* <div className="company-detail">
                    <b>{companyData?.companyName ? companyData?.companyName : ""}</b>
                    <p className="mb-0">{companyData?.taxNumber ? "Tax No. " + companyData?.taxNumber : ""}</p>
                    <p className="mb-0">{companyData?.crNumber ? "C.R. " + companyData?.crNumber : ""}</p>
                    <p className="mb-0">{companyData?.mobile ? companyData?.mobile : ""}</p>
                    <p className="mb-0">{companyData?.email ? companyData?.email : ""}</p>
                    <p className="mb-0">{companyData?.city ? companyData?.city : ""}</p>
                  </div> */}

              {/* Invoice Type */}

              {/* <div className="tax-invoice">
                    <b>{invoiceData?.invoiceData?.type === "refund" ? "Refund Invoice" : "Tax Invoice"}</b>
                  </div> */}

              {/* Customer Information */}

              {/* <div className="customer-information">
                    <div className="customer-name">
                      <b>Customer Information</b>
                    </div>
                    <div className="customer-detail">
                      <div>
                        <div className="title">Name:</div>
                        <div className="detail">
                          {invoiceData?.invoiceData?.customerName
                            ? invoiceData?.invoiceData?.customerName
                            : invoiceData?.invoiceData?.receiverInformation?.name
                            ? invoiceData?.invoiceData?.receiverInformation?.name
                            : ""}
                        </div>
                      </div>
                      <br />
                      <div>
                        <div className="title">Email:</div>
                        <div className="detail">
                          {invoiceData?.invoiceData?.customerEmail
                            ? invoiceData?.invoiceData?.customerEmail
                            : invoiceData?.invoiceData?.receiverInformation?.email
                            ? invoiceData?.invoiceData?.receiverInformation?.email
                            : ""}
                        </div>
                      </div>
                      <br />
                      <div>
                        <div className="title">Mobile:</div>
                        <div className="detail">{invoiceData?.invoiceData?.receiverInformation?.mobile}</div>
                      </div>
                      <br />
                      <div>
                        <div className="title">Tax No.:</div>
                        <div className="detail">{invoiceData?.invoiceData?.receiverInformation?.taxNumber}</div>
                      </div>
                      <br />
                    </div>
                  </div> */}

              {/* Invoice Information */}

              {/* <div className="invoice-information">
                    <div className="invoice-name">
                      <b>Invoice Information</b>
                    </div>
                    <div className="invoice-detail">
                      <div>
                        <div className="title">Number:</div>
                        <div className="detail">{invoiceData?.invoiceNumber}</div>
                      </div>
                      <br />
                      <div>
                        <div className="title">Date:</div>
                        <div className="detail">{moment(invoiceData?.invoiceData?.invoiceDate).format("MM/DD/YYYY")}</div>
                      </div>
                      <br />
                      <div>
                        <div className="title">Status:</div>
                        <div
                          className="detail"
                          style={{
                            color: invoiceData?.status === "paid" ? "#3CAEA3" : "",
                          }}
                        >
                          {invoiceData?.status ? invoiceData?.status.charAt(0).toUpperCase() + invoiceData?.status.slice(1) : ""}
                        </div>
                      </div>
                      <br />
                      <div>
                        <div className="title">Cashier:</div>
                        <div className="detail">
                          {invoiceData?.invoiceData?.senderInformation?.name?.first
                            ? invoiceData?.invoiceData?.senderInformation?.name?.first + " " + invoiceData?.invoiceData?.senderInformation?.name?.last
                            : ""}
                        </div>
                      </div>
                      <br />
                    </div>
                  </div> */}
              {/* //////invoice products Table////// */}
              {/* <div className="table-div">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Item No.</th>
                          <th>Item Name</th>
                          <th>Item Description</th>
                          <th>Unit Price</th>
                          <th>Qty</th>
                          <th>Taxable Amount</th>
                          <th>Discount Amount</th>
                          <th>Tax Rates</th>
                          <th>Tax Amount</th>
                          <th>Item Subtotal Including VAT</th>
                        </tr>
                      </thead>
                      <tbody>
                        {invoiceData?.invoiceData?.products?.map((item, index) => (
                          <React.Fragment key={index}>
                            <tr>
                              <td>{index + 1}.</td>
                              <td>{item.name ? item.name : ""}</td>
                              <td style={{ minWidth: "100px" }}>{item.description ? item.description : ""}</td>
                              <td>{Number(item?.price).toFixed(2)}</td>
                              <td>{item?.quantity}</td>
                              <td>
                                {item?.price && item?.quantity
                                  ? (item?.price * item?.quantity - +item?.discount).toFixed(2)
                                  : Number(item?.price).toFixed(2)}
                              </td>
                              <td>{item?.discount ? Number(item?.discount)?.toFixed(2) : 0}</td>
                              <td>{item?.taxRate ? item?.taxRate : 0} %</td>
                              <td>{item?.tax ? Number(item?.tax).toFixed(2) : 0.0}</td>
                              <td style={{ minWidth: "150px" }}>
                                {item?.addedTax && item?.discount
                                  ? (item?.addedTax + +item?.price?.toLocaleString() * item?.quantity - Number(item?.discount))?.toFixed(2)
                                  : item?.discount
                                  ? (item?.price?.toLocaleString() * item?.quantity - Number(item?.discount))?.toFixed(2)
                                  : item?.addedTax
                                  ? (item?.addedTax + +item?.price?.toLocaleString() * item?.quantity)?.toFixed(2)
                                  : 0.0}
                              </td>
                            </tr>
                            {item && item.refundedQuantity ? (
                              <tr>
                                <td colSpan="1"></td>
                                <td colSpan="9" style={{ color: "red" }}>
                                  {item?.refundedQuantity} X Refunded On {moment(invoiceData?.invoiceData.invoiceDate).format("dddd MMM DD, YYYY")}
                                </td>
                              </tr>
                            ) : (
                              ""
                            )}
                          </React.Fragment>
                        ))}
                      </tbody>
                    </table>
                  </div> */}

              {/* ////// Total ////// */}

              {/* <div className="total-information">
                    <div className="total-name">
                      <b>Total</b>
                    </div>
                    <div className="total-detail">
                      <div>
                        <div className="title">Subtotal (Excluding Tax)</div>
                        <div className="detail">
                          {companyData?.currency} {Number(invoiceData?.invoiceData?.productsTotal).toFixed(2)}
                        </div>
                      </div>
                      <br />
                      <div>
                        <div className="title">Discount</div>
                        <div className="detail">
                          {companyData?.currency} {Number(invoiceData?.invoiceData?.discountTotal).toFixed(2)}
                        </div>
                      </div>
                      <br />
                      <div>
                        <div className="title">Taxable Amount</div>
                        <div className="detail">
                          {companyData?.currency} {Number(invoiceData?.invoiceData?.productsTotal).toFixed(2)}
                        </div>
                      </div>
                      <br />
                      <div>
                        <div className="title">Tax Amount</div>
                        <div className="detail">
                          {companyData?.currency} {Number(invoiceData?.invoiceData?.taxTotal).toFixed(2)}
                        </div>
                      </div>
                      <div>
                        <div className="title">
                          <b>Total (Including Tax)</b>
                        </div>
                        <div className="detail">
                          <b>
                            {companyData?.currency} {Number(invoiceData?.invoiceData?.totalSales).toFixed(2)}
                          </b>
                        </div>
                      </div>
                      <br />
                    </div>
                  </div> */}

              {/* ///// invoice starts //// */}

              <div className="invoice-main-wrap">
                <div className="invoice-total">
                  <div>
                    <div style={{ color: "#EB5757" }}>
                      {invoiceData?.invoiceData?.invoiceType === "refund" ? (
                        <div className={`d-flex align-items-start ${language !== "arabic" ? "flex-row" : "flex-row-reverse"}`}>
                          <div>{i18n("global.Refund")}</div>
                          <div className="mx-2">
                            {invoiceData?.invoiceData?.parentinvoiceNumber ? invoiceData?.invoiceData?.parentinvoiceNumber : ""}
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="text-center py-3">
                      <div style={{ fontSize: "35px" }}>
                        {" "}
                        {invoice?.invoiceData?.invoiceType !== "refund"
                          ? Number(invoiceData?.invoiceData?.totalaftertax).toFixed(2)
                          : Number(invoiceData?.invoiceData?.paidTotal).toFixed(2)}
                      </div>
                      <div className="invoice-date-section">{i18n("DASHBOARD.Total")}</div>
                    </div>
                  </div>
                </div>
                {/* ///// cashier info //// */}

                <div className="invoice-total">
                  <div>
                    <div className={`d-flex align-items-start ${language !== "arabic" ? "flex-row" : "flex-row-reverse"}`}>
                      {i18n("DASHBOARD.Cashier")}
                      <div className="mx-2">
                        {invoiceData?.invoiceData?.senderInformation?.name?.first
                          ? invoiceData?.invoiceData?.senderInformation?.name?.first + " " + invoiceData?.invoiceData?.senderInformation?.name?.last
                          : ""}
                      </div>
                    </div>
                    <div className={`d-flex align-items-start ${language !== "arabic" ? "flex-row" : "flex-row-reverse"}`}>
                      <div> {i18n("DASHBOARD.POS")} </div>
                      <div className="mx-2"> {invoice?.invoiceData?.pos ? invoice?.invoiceData?.pos : ""}</div>
                    </div>
                    <div>
                      {invoiceData?.invoiceData?.customerName ? (
                        <div className={`d-flex align-items-start ${language !== "arabic" ? "flex-row" : "flex-row-reverse"}`}>
                          <div> {i18n("DASHBOARD.Customer")}</div>
                          <div className="mx-2"> {invoiceData?.invoiceData?.customerName}</div>
                        </div>
                      ) : invoiceData?.invoiceData?.receiverInformation?.name ? (
                        <div className={`d-flex align-items-start ${language !== "arabic" ? "flex-row" : "flex-row-reverse"}`}>
                          <div> {i18n("DASHBOARD.Customer")}</div>
                          <div className="mx-2">{invoiceData?.invoiceData?.receiverInformation?.name}</div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
                {/* ///// product info //// */}

                <div className="invoice-total">
                  {invoiceData?.invoiceData?.products?.map((product, index) => {
                    return (
                      <>
                        <div
                          className={`mt-2 d-flex align-items-center justify-content-between ${
                            language !== "arabic" ? "flex-row" : "flex-row-reverse"
                          }`}
                          key={index}
                        >
                          <div>{product?.name}</div>
                          <div>{product?.totalPrice ? Number(product?.totalPrice)?.toFixed(2) : 0.0} </div>
                        </div>
                        <div
                          className={`d-flex justify-content-between allign-items-center ${language !== "arabic" ? "flex-row" : "flex-row-reverse"}`}
                        >
                          <div className="invoice-date-section">
                            {" "}
                            {product?.price && (
                              <>
                                {product?.quantity} &times; {Number(product?.price).toFixed(2)}
                              </>
                            )}
                          </div>
                        </div>
                      </>
                    );
                  })}
                </div>
                {/* ///// subtotal //// */}
                {Number(invoice?.invoiceData?.discountTotal) > 0 && (
                  <div className="invoice-total">
                    <div className={`d-flex align-items-center justify-content-between ${language !== "arabic" ? "flex-row" : "flex-row-reverse"}`}>
                      <div className=" bold-text">{i18n("DASHBOARD.Subtotal")}</div>
                      <div style={{ fontWeight: "700" }}>{Number(invoiceData?.invoiceData?.productsTotal).toFixed(2)}</div>
                    </div>

                    {Object.keys(aggregatedDiscounts).map((discountName, index) => {
                      const totalDiscount = aggregatedDiscounts[discountName];
                      return (
                        <div
                          key={index}
                          className={` d-flex align-items-center justify-content-between ${language !== "arabic" ? "flex-row" : "flex-row-reverse"}`}
                        >
                          <div className="">
                            <div>{discountName}</div>
                          </div>
                          <div className="">-{Number(totalDiscount).toFixed(2)}</div>
                        </div>
                      );
                    })}
                  </div>
                )}

                {/* ///// tax //// */}
                {(filterTaxess?.length > 0 || Number(invoiceData?.invoiceData?.taxTotal) > 0) && (
                  <div className="invoice-total">
                    <div className={`d-flex align-items-center justify-content-between ${language !== "arabic" ? "flex-row" : "flex-row-reverse"}`}>
                      <div className="bold-text">{i18n("DASHBOARD.TotalBeforeTax")}</div>
                      <div style={{ fontWeight: "700" }}>
                        {invoice?.invoiceData?.totalbeforetax ? Number(invoice?.invoiceData?.totalbeforetax)?.toFixed(2) : 0.0}
                      </div>
                    </div>

                    {filterTaxess?.map((tax) => (
                      <>
                        <div
                          className={`mt-1 d-flex align-items-center justify-content-between ${
                            language !== "arabic" ? "flex-row" : "flex-row-reverse"
                          }`}
                        >
                          <div className="">
                            {`${tax?.name}  ${tax?.taxRate}%`}
                            <span className="light-color ml-1">
                              {tax?.type === "Included in the price" ? <span>({i18n("global.Included")})</span> : ""}
                            </span>
                          </div>
                          <div className="">
                            {Number(tax?.includedValue) > 0 ? Number(tax?.includedValue)?.toFixed(2) : Number(tax?.excludedValue)?.toFixed(2)}
                          </div>
                        </div>
                      </>
                    ))}
                  </div>
                )}

                {/* ///// card //// */}

                <div className="invoice-total">
                  <div className={`d-flex align-items-center justify-content-between ${language !== "arabic" ? "flex-row" : "flex-row-reverse"}`}>
                    <div className=" bold-text">
                      {Number(invoiceData?.invoiceData?.taxTotal) > 0 ? i18n("DASHBOARD.TotalWithTax") : i18n("DASHBOARD.Total")}
                    </div>
                    <div style={{ fontWeight: "700" }}>
                      {" "}
                      {invoice?.invoiceData?.invoiceType !== "refund"
                        ? invoice?.invoiceData?.totalaftertax && Number(invoice?.invoiceData?.totalaftertax)?.toFixed(2)
                        : Number(invoiceData?.invoiceData?.paidTotal).toFixed(2)}
                    </div>
                  </div>

                  {invoiceData?.invoiceData?.payments?.map((payment) => (
                    <>
                      <div
                        className={`d-flex align-items-center mt-3 justify-content-between ${
                          language !== "arabic" ? "flex-row" : "flex-row-reverse"
                        }`}
                      >
                        {invoice?.invoiceData?.payments?.length > 1 && invoice?.invoiceData?.invoiceType !== "refund" ? (
                          <>
                            <div className="method">{i18n("SubscriptionModals.AmountDue")}</div>
                            <div className="amount">{payment?.amount ? Number(payment?.amount)?.toFixed(2) : 0.0}</div>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className={`d-flex align-items-center justify-content-between ${language !== "arabic" ? "flex-row" : "flex-row-reverse"}`}>
                        <>
                          <div className="method">
                            {payment?.method === "visa/master_card"
                              ? "Master/Visa Card"
                              : payment.method === "mada_card"
                              ? "Mada Card"
                              : payment.method === "bank_transfer"
                              ? "Bank "
                              : payment.method === "cash" || payment.method === "Cash"
                              ? i18n("global.Cash")
                              : payment.method === "Card"
                              ? i18n("global.Card")
                              : payment?.method}
                          </div>
                          <div className="amount">
                            {invoiceData?.invoiceData?.payments?.length <= 1 && invoice?.invoiceData?.invoiceType !== "refund"
                              ? Number(invoice?.invoiceData?.paidTotal)?.toFixed(2)
                              : invoice?.invoiceData?.invoiceType !== "refund" && invoiceData?.invoiceData?.payments?.length > 1
                              ? // ?
                                Number(payment?.cashRecieved)?.toFixed(2)
                              : Number(payment?.amount).toFixed(2)}
                          </div>
                        </>
                      </div>
                      <div className={`d-flex align-items-center justify-content-between ${language !== "arabic" ? "flex-row" : "flex-row-reverse"}`}>
                        {Number(payment?.change) > 0 && invoice?.invoiceData?.invoiceType !== "refund" ? (
                          <>
                            <div className="method">{i18n("global.Change")}</div>
                            <div className="amount">{Number(payment?.change)?.toFixed(2)}</div>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </>
                  ))}
                  <div className={`d-flex align-items-center justify-content-between ${language !== "arabic" ? "flex-row" : "flex-row-reverse"}`}>
                    {Number(invoiceData?.invoiceData?.change) > 0 && invoiceData?.invoiceData?.payments?.length === 1 && (
                      <>
                        <div>{i18n("global.Change")}</div>
                        <div className="amount">{invoiceData?.invoiceData?.change} </div>
                      </>
                    )}
                  </div>
                </div>

                {/* ///// date //// */}
                <div
                  className={`d-flex justify-content-between  allign-items-center invoice-date-section ${
                    language !== "arabic" ? "flex-row" : "flex-row-reverse"
                  }`}
                >
                  <div className="detail">
                    {/* {invoiceData?.invoiceData?.dueDate && invoiceData?.invoiceData?.dueDate !== "Invalid date"
                      ? moment(invoiceData?.invoiceData?.dueDate).format("ddd DD MMM YYYY hh:ss")
                      : invoiceData?.invoiceData?.paymentDate
                      ? moment(invoiceData?.invoiceData?.paymentDate).format("ddd DD MMM YYYY hh:ss")
                      : ""} */}
                    {moment(invoice?.invoiceData?.invoiceDate).format("ddd DD MMM YYYY HH:mm")}
                  </div>{" "}
                  <div>
                    {invoiceData?.invoiceData?.newInvoiceNumber
                      ? invoiceData?.invoiceData?.newInvoiceNumber
                      : invoiceData?.invoiceData?.invoiceNumber}
                  </div>
                </div>
              </div>

              {/* ///// invoice ends //// */}

              {/* ////// Payments ////// */}

              {/* <div className="payment-information">
                    <div className="payment-name">
                      <b>Payment Details</b>
                    </div>
                    <div className="payment-detail">
                      <div className="payment-border">
                        <div>
                          <div className="title due">Paid</div>
                          <div className="detail due">
                            {companyData?.currency} {invoiceData?.invoiceData?.paidTotal ? Number(invoiceData?.invoiceData?.paidTotal).toFixed(2) : 0}
                          </div>
                          <div className="payments">
                            {invoiceData?.invoiceData?.payments?.map((payment) => (
                              <>
                                <span className="date">{moment(payment?.date).format("MM/DD/YYYY")}</span>
                                <span className="method">
                                  {payment?.method === "visa/master_card"
                                    ? "Master/Visa Card"
                                    : payment.method === "mada_card"
                                    ? "Mada Card"
                                    : payment.method === "bank_transfer"
                                    ? "Bank "
                                    : payment.method === "cash"
                                    ? "Cash"
                                    : ""}
                                </span>
                                <span className="amount">
                                  {" "}
                                  {companyData?.currency} {Number(payment?.amount).toFixed(2)}
                                </span>
                                <br />
                              </>
                            ))}
                          </div>
                        </div>
                      </div>
                      <br />
                      <div className="payment-border">
                        <div>
                          <div>
                            <div className="title due">Balance Due</div>
                            <div className="detail due">
                              {companyData?.currency} {invoiceData?.invoiceData?.balance ? Number(invoiceData?.invoiceData?.balance).toFixed(2) : 0}
                            </div>
                          </div>
                          <br />
                          <div>
                            <div className="title">Due Date</div>
                            <div className="detail">
                              {invoiceData?.invoiceData?.dueDate && invoiceData?.invoiceData?.dueDate !== "Invalid date"
                                ? moment(invoiceData?.invoiceData?.dueDate).format("MM/DD/YYYY")
                                : ""}
                            </div>
                          </div>
                        </div>
                        <br />
                      </div>
                    </div>
                  </div> */}

              {/* ////// Replacement Policy ////// */}

              {/* <div className="terms-information">
                    <div className="header-name">
                      <b>Replacement Policy:</b>
                    </div>
                    <div className="terms-border">
                      <div className="terms-detail">
                        <div>
                          <p>
                            To view the replacement policy, please visit the following link <br />
                            <a href="https://www.premier.com/ReplacementPolicy" target="_blank" rel="noopener noreferrer">
                              www.premier.com/ReplacementPolicy
                            </a>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div> */}

              {/* ////// Terms And Conditions ////// */}

              {/* <div className="terms-information">
                    <div className="header-name">
                      <b>Terms And Conditions:</b>
                    </div>
                    <div className="terms-border2">
                      <div className="terms-detail">
                        <p>
                          To view the Terms and Conditions, please visit the following link
                          <br />
                          <a href="https://www.premier.com/TermsAndConditions" target="_blank" rel="noopener noreferrer">
                            www.premier.com/TermsAndConditions
                          </a>
                        </p>
                      </div>
                    </div>
                  </div> */}

              {/* ////// Notes ////// */}

              {/* <div className="terms-information">
                    <div className="header-name">
                      <b>Note</b>
                    </div>
                    <div className="terms-border">
                      <div className="terms-detail">
                        <div>
                          <p>{invoiceData?.invoiceData?.generalNotes ? invoiceData?.invoiceData?.generalNotes : ""}</p>
                        </div>
                      </div>
                    </div>
                  </div> */}

              {/* ////// QR Image ////// */}

              {/* <div className="qr-img">
                    <img src={IconQr} alt="loading" style={{ width: "10rem" }} />
                  </div> */}
              {/* </div> */}
            </div>
          </div>
          {/* ) : (
            <PosInvoicePreview invoice={invoice} invoiceData={invoiceData} />
          )} */}
        </Modal.Body>
      </Modal>
    </div>
  );
};
