import React from "react";
import SubscriptionDialog from "../../company/company-edit-Dialog/SubscriptionDialog";
import { i18n } from "../../private/languageSelector";
import { Field, Form, Formik } from "formik";
import { Card, Input } from "../../../../_metronic/_partials/controls";
import { getUserByToken } from "../../../modules/Auth/_redux/authCrud";
import * as usersActions from "../../../../_redux/users/usersActions";
import PinInput from "react-pin-input";

import * as actions from "../../../modules/Auth/_redux/authCrud";

import * as Yup from "yup";

import { Storage } from "react-jhipster";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { FATORATAY_AUTH } from "../../../../helpers/constants";

export default function ActionDialog({
  user,
  showPinCodeDialog,
  values,
  setValues,
  showPasswordDialog,
  onHide,
  primaryAction,
  secondaryAction,
  posPin,
  setPosPin,
  setUser,
}) {
  const passwordValidation = "Password must contain letters and numbers";
  const dispatch = useDispatch();

  const UserSchema = Yup.object().shape({
    currentPassword: Yup.string()
      .min(8, "Minimum 8 symbols")
      .max(20, "Maximum 20 symbols")
      .matches(/^(?=.*[0-9])(?=.*[a-z]).{8,20}$/, passwordValidation),
    newPassword: Yup.string()
      .min(8, "Minimum 8 symbols")
      .max(20, "Maximum 20 symbols")
      .matches(/^(?=.*[0-9])(?=.*[a-z]).{8,20}$/, passwordValidation),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("newPassword"), null], "Passwords do not match")
      .min(8, "Minimum 8 symbols")
      .max(20, "Maximum 20 symbols")
      .matches(/^(?=.*[0-9])(?=.*[a-z]).{8,20}$/, passwordValidation),
  });

  const changePasswordHandler = (values) => {
    if (values.currentPassword && values.newPassword) {
      actions
        .changePassword(values)
        .then(() => {
          toast.success("Password Changed");
          setTimeout(() => {
            Storage.session.remove(FATORATAY_AUTH);
            Storage.local.remove(FATORATAY_AUTH);
            window.location.reload();
          }, 1000);
        })
        .catch((err) => {
          if (err.message === "Request failed with status code 400") {
            toast.error("Please Enter Correct Password");
          }
        });
    }
  };
  const handlePin = (value) => {
    setPosPin(value);
  };

  const passwordMessage = () => {
    return (
      <>
        <Form className={user?.language === "english" ? "text-left ltr" : "text-right rtl"}>
          <div className="form-group">
            <Field
              name="currentPassword"
              component={Input}
              label={i18n("global.CurrentPassword")}
              type={"text"}
              placeholder={i18n("global.CurrentPassword")}
            />
          </div>
          <div className="form-group">
            <Field name="newPassword" component={Input} label={i18n("global.NewPassword")} placeholder={i18n("global.NewPassword")} type={"text"} />
          </div>
          <div className="form-group">
            <Field
              name="confirmPassword"
              component={Input}
              label={i18n("global.ConfirmNewPassword")}
              placeholder={i18n("global.ConfirmNewPassword")}
              type={"text"}
            />
          </div>
        </Form>
      </>
    );
  };

  const pinCodeMessage = () => {
    return (
      <>
        <Form style={{ direction: user?.language === "english" ? "ltr" : "ltr" }}>
          <div className="form-group ltr">
            <PinInput
              length={4}
              initialValue={posPin}
              secret={false}
              focus={false}
              type="numeric"
              style={{ width: "100%" }}
              inputStyle={{
                width: "50px",
                height: "50px",
                border: "0",
                marginLeft: "1rem",
                marginRight: "1rem",
                fontSize: "25px",
                background: "#F2F2F2",
                color: "#333333",
                direction: "ltr",
              }}
              onChange={handlePin}
            />
          </div>
        </Form>
      </>
    );
  };

  return (
    <div>
      {showPinCodeDialog ? (
        <>
          <Formik
            enableReinitialize={true}
            initialValues={{
              currentPassword: "",
              newPassword: "",
            }}
            validationSchema={UserSchema}
            onSubmit={(values) => {
              getUserByToken().then((res) => {
                if (res?.data?.posPin !== "" && posPin.toString().length !== 4) {
                  toast.error("Enter a 4 Digits Pos Pin");
                } else {
                  dispatch(
                    usersActions.updateUserPosPin(user._id, {
                      posPin: posPin ? posPin : user?.posPin,
                    })
                  ).then(() => {
                    getUserByToken().then((res) => {
                      setUser(res?.data);
                    });
                  });
                  toast.success("User Updated Successfully");
                  secondaryAction();
                }
              });
            }}
          >
            {({ handleSubmit, setErrors }) => {
              return (
                <>
                  <SubscriptionDialog
                    modalWidth={"350px"}
                    modalHeight={"185px"}
                    modalBackgroundColor={"#FFFFFF"}
                    show={showPinCodeDialog}
                    onHide={onHide}
                    title={i18n("global.EnterNewPin")}
                    titleWidth={"140px"}
                    titleHeight={"20px"}
                    titleFontWeight={"700"}
                    titleFontSize={"16px"}
                    titleLineHeight={"20px"}
                    titleColor={"#333333"}
                    message={pinCodeMessage()}
                    messageWidth={"320px"}
                    messageHeight={"44px"}
                    messageFontSize={"16px"}
                    messageFontWeight={"400"}
                    messageLineHeight={"21.79px"}
                    messageColor={"#333333"}
                    primaryLabel={i18n("SubscriptionModals.CANCEL")}
                    primaryAction={primaryAction}
                    primaryLabelWidth={"55px"}
                    primaryLabelHeight={"20px"}
                    primaryLabelFontWeight={"700"}
                    primaryLabelFontSize={"14px"}
                    primaryLabelLineHeight={"20px"}
                    primaryLabelColor={"#828282"}
                    secondaryAction={() => {
                      handleSubmit();
                    }}
                    secondaryLabel={i18n("UserProfile.SAVE")}
                    secondaryLabelWidth={"75px"}
                    secondaryLabelHeight={"20px"}
                    secondaryLabelFontWeight={"700"}
                    secondaryLabelFontSize={"14px"}
                    secondaryLabelLineHeight={"20px"}
                    secondaryLabelColor={posPin === user?.posPin ? "#2D9CDB80" : "#2D9CDB"}
                    isCenteredOnMobile
                    secondaryPointerEvents={posPin === user?.posPin || posPin?.length === 0 ? "none" : "auto"}
                    secondaryCursor={posPin === user?.posPin || posPin?.length === 0 ? "auto" : "pointer"}
                  />
                </>
              );
            }}
          </Formik>
        </>
      ) : (
        <>
          <Formik
            enableReinitialize={true}
            initialValues={{
              currentPassword: "",
              newPassword: "",
            }}
            validationSchema={UserSchema}
            onSubmit={(values) => {
              changePasswordHandler(values);
              getUserByToken();
            }}
          >
            {({ handleSubmit, setErrors, values, errors }) => {
              return (
                <>
                  <SubscriptionDialog
                    modalWidth={"350px"}
                    modalHeight={"500px"}
                    modalBackgroundColor={"#FFFFFF"}
                    show={showPasswordDialog}
                    onHide={onHide}
                    title={i18n("global.Password")}
                    titleWidth={"140px"}
                    titleHeight={"20px"}
                    titleFontWeight={"700"}
                    titleFontSize={"16px"}
                    titleLineHeight={"20px"}
                    titleColor={"#333333"}
                    message={passwordMessage()}
                    messageWidth={"320px"}
                    messageHeight={"100%"}
                    messageFontSize={"16px"}
                    messageFontWeight={"400"}
                    messageLineHeight={"21.79px"}
                    messageColor={"#333333"}
                    primaryLabel={i18n("SubscriptionModals.CANCEL")}
                    primaryAction={primaryAction}
                    primaryLabelWidth={"55px"}
                    primaryLabelHeight={"20px"}
                    primaryLabelFontWeight={"700"}
                    primaryLabelFontSize={"14px"}
                    primaryLabelLineHeight={"20px"}
                    primaryLabelColor={"#828282"}
                    secondaryAction={() => {
                      handleSubmit();
                      secondaryAction();
                    }}
                    secondaryLabel={i18n("SubscriptionModals.CONTINUE")}
                    secondaryLabelWidth={"75px"}
                    secondaryLabelHeight={"20px"}
                    secondaryLabelFontWeight={"700"}
                    secondaryLabelFontSize={"14px"}
                    secondaryLabelLineHeight={"20px"}
                    secondaryLabelColor={
                      values?.currentPassword?.length > 0 &&
                      values?.newPassword?.length > 0 &&
                      values?.confirmPassword?.length > 0 &&
                      !errors?.currentPassword &&
                      !errors?.newPassword &&
                      !errors?.confirmPassword
                        ? "#2D9CDB"
                        : "#2D9CDB80"
                    }
                    isCenteredOnMobile
                    secondaryPointerEvents={
                      values?.currentPassword?.length > 0 &&
                      values?.newPassword?.length > 0 &&
                      values?.confirmPassword?.length > 0 &&
                      !errors?.currentPassword &&
                      !errors?.newPassword &&
                      !errors?.confirmPassword
                        ? "auto"
                        : "none"
                    }
                  />
                </>
              );
            }}
          </Formik>
        </>
      )}
    </div>
  );
}
