import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Input, Card, Mobile } from "../../../../../_metronic/_partials/controls";
import IconFatoraty from "../../../../../assets/img/fat-logo.png";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { AsYouType } from "libphonenumber-js/max";
import * as userActions from "../../../../../_redux/users/usersActions";
import toast from "react-hot-toast";
import hidePasswordIcon from "../../../../../assets/img/hidePasswordIcon.svg";
import showPasswordIcon from "../../../../../assets/img/iconShowPassword.svg";
import { i18n } from "../../../private/languageSelector";
import AuthCard from "../../../../components/Card/AuthCard";
import { Link, useHistory } from "react-router-dom";

let asYouType = new AsYouType();
Yup.addMethod(Yup.mixed, "validatePhoneNumber", function(msg) {
  return this.test({
    name: "validatePhoneNumber",
    message: msg,
    test: (value) => {
      if (value) {
        asYouType.reset();
        asYouType.input(value);
        return asYouType.getNumber().isValid();
      } else {
        return true;
      }
    },
  });
});

// Validation schema
export const AddTaxSchema = Yup.object().shape({
  email: Yup.string()
    .email("Wrong Email Format")
    .min(3, "Minimum 3 Alphabets")
    .max(50, "Maximum 50 Alphabets")
    .required("Email is required"),
  mobile: Yup.string()
    .required(i18n("Validation.RequiredField"))
    .validatePhoneNumber(i18n("Validation.INVALID_PHONE")),
  password: Yup.string()
    .min(8, i18n("Validation.Minimum8Symbols"))
    .max(50, i18n("Validation.Maximum50Symbols"))
    // .matches(/^(?=.*[0-9])(?=.*[a-z]).{8,50}$/, "Password must be at least eight characters long, Contain letters and numbers")
    .matches(/^(?=.*[0-9])(?=.*[a-z]).{8,50}$/, i18n("Validation.INVALID_PASSWORD"))
    .required(i18n("Validation.RequiredField")),
  acceptTerms: Yup.boolean(),
});

const initAddState = {
  email: "",
  mobile: "",
  password: "",
  acceptTerms: false,
};

export function UserRegistration() {
  const history = useHistory();
  const dispatch = useDispatch();

  const { token } = useParams();

  const [userForSetup, setUserForSetup] = useState({});
  const [passwordShow, setPasswordShow] = useState(false);
  const [error, setError] = useState("");
  const passwordShowHandler = () => {
    setPasswordShow(passwordShow ? false : true);
  };

  useEffect(() => {
    if (token) {
      dispatch(userActions.fetchSetupUserDetail(token)).then((res) => setUserForSetup(res?.data?.user));
    }
  }, [dispatch, token]);

  const selectedLanguage = localStorage.getItem("language");

  return (
    <div className="d-flex justify-content-center align-items-center w-100 h-100" style={{ background: "#F2F2F2" }}>
      <div style={{ minWidth: "350px" }}>
        <Formik
          enableReinitialize={true}
          initialValues={userForSetup ? { ...initAddState, email: userForSetup?.email } : initAddState}
          validationSchema={AddTaxSchema}
          onSubmit={(values) => {
            const toastId = toast.loading("Loading...");
            dispatch(userActions.setupUserAccount(token, values)).then((res) => {
              if (res) {
                toast.success(res.data.message);
                history.push("/auth/login");
              } else {
                setError(i18n("ActivateAccount.MobileDoesNotMatch"));
              }
            });

            toast.dismiss(toastId);
          }}
        >
          {({ handleSubmit, values, setValues, errors, touched }) => (
            <div className="login-form">
              <AuthCard id={""} selectedLanguage={selectedLanguage} error={error}>
                <div className="pb-4" style={{ fontSize: "20px", fontWeight: 300 }}>
                  {i18n("ActivateAccount.CompleteAccountRegistration")}
                </div>
                <div className="pb-5" style={{ fontSize: "14px", fontWeight: 300 }}>
                  {i18n("ActivateAccount.CompleteDetails")}
                </div>
                <Form className="form form-label-right pt-2">
                  <div className="">
                    <div className="form-group">
                      <Field
                        name="email"
                        label={i18n("global.Email")}
                        className={`form-control thick-border ${touched.email && errors.email ? "red-border" : ""}`}
                        readOnly={true}
                        component={Input}
                        placeholder={i18n("global.Email")}
                        disabled
                        style={{ background: "#F2F2F2" }}
                      />
                    </div>
                    <div className={`form-group ltr ${touched.mobile && errors.mobile ? "red-border" : "thick-border "}`}>
                      <div style={{ position: "absolute", bottom: "1.8rem", fontSize: "75%", color: "#828282" }}>
                        {i18n("ActivateAccount.MobileNumber")}
                      </div>
                      <Field
                        component={Mobile}
                        name="mobile"
                        international
                        defaultCountry="SA"
                        style={{ display: "flex", textAlign: selectedLanguage === "arabic" ? "right" : "left" }}
                        value={values?.mobile}
                        onChange={(value) => {
                          setValues({
                            ...values,
                            mobile: value,
                          });
                        }}
                        switchIt={true}
                      />
                    </div>
                    <div className="form-group">
                      <Field
                        name="password"
                        component={Input}
                        placeholder={i18n("global.Password")}
                        label={i18n("global.Password")}
                        type={passwordShow ? "text" : "password"}
                        withFeedbackLabel={true}
                        className={`form-control thick-border ${touched.password && errors.password ? "red-border" : ""}`}
                      />
                      <span
                        onClick={passwordShowHandler}
                        style={{
                          float: selectedLanguage === "arabic" ? "left" : " right",
                          marginTop: "-30px",
                          position: "relative",
                          zIndex: "1",
                          cursor: "pointer",
                          width: "auto",
                        }}
                      >
                        <img src={passwordShow ? showPasswordIcon : hidePasswordIcon} alt="eyeIcon" />
                      </span>
                    </div>
                    <div className=" pl-0">
                      <div className="">
                        <div className="d-flex flex-wrap align-items-center mb-3">
                          <label className="checkbox ">
                            <input
                              type="checkbox"
                              name="acceptTerms"
                              className={`${selectedLanguage === "arabic" ? "ml-2" : "mr-2"}`}
                              value={values?.acceptTerms}
                              onChange={(e) => {
                                setValues({ ...values, acceptTerms: !values?.acceptTerms });
                              }}
                            />
                            <span className={` ${selectedLanguage === "arabic" ? "ml-2" : "mr-2"} `}></span>
                            {i18n("SignUp.IAgreeThe")}{" "}
                          </label>
                          <Link
                            className={` ${selectedLanguage === "arabic" ? "mr-1" : "ml-1"} `}
                            to="/terms"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {i18n("SignUp.Terms&Conditions")}
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
                <div className="d-flex justify-content-center align-items-center">
                  <button
                    type="submit"
                    onClick={() => handleSubmit()}
                    className={`btn font-weight-bold px-9  my-3 w-100 auth-btn mt-0 `}
                    style={{ display: "block", marginTop: "0px" }}
                    disabled={
                      values?.email?.length === 0 ||
                      values?.mobile?.length === 0 ||
                      values?.password?.length === 0 ||
                      errors?.email ||
                      errors?.password ||
                      errors?.mobile ||
                      !values?.acceptTerms
                    }
                  >
                    {i18n("ActivateAccount.CreateYourAccount")}
                  </button>
                </div>
              </AuthCard>
            </div>
          )}
        </Formik>
      </div>
    </div>
  );
}
