import React from "react";
import { NavLink } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { i18n } from "../../../pages/private/languageSelector";
import fatoratyLogo from "../../../../assets/img/dark-logo-fatoraty.svg";

const PasswordResetNext = () => {
  const history = useHistory();
  const selectedLanguage = localStorage.getItem("language");

  return (
    <div
      className="forget-password-next w-100 d-flex justify-content-center align-items-center flex-column"
      style={{ background: "#F2F2F2", height: "100vh" }}
    >
      <div
        className="login-form "
        style={{
          minWidth: "350px",
          width: "auto",
          background: "white",
          textAlign: selectedLanguage === "arabic" ? "right" : "left",
          borderRadius: "10px",
          padding: "25px 18px 25px 18px",
        }}
      >
        <div>
          <div className={`form-title ${selectedLanguage === "arabic" ? "text-right" : "text-left"} `}>
            <img src={fatoratyLogo} alt="logo" height={"27px"} />
            <p className="mx-0 mt-5 auth-heading-main" style={{ color: "#4F4F4F", fontSize: "16px" }}>
              {" "}
              {i18n("ResetPasswordNext.YourPasswordWasSetSuccessfully")}
            </p>
            <NavLink className="menu-link" to="/logout">
              <p
                style={{
                  color: "blue",
                  margin: 0,
                }}
              >
                <button className={`btn font-weight-bold px-9 py-4 mt-3 w-100 auth-btn`} onClick={() => history.push("/auth/login")}>
                  <span> {i18n("ForgotPasswordNext.GoToLogin")}</span>
                </button>
              </p>
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PasswordResetNext;
