import React from "react";
import { Card } from "../../../../../_metronic/_partials/controls";
import IconQr from "../../../../../assets/img/icon-qr.svg";
import { refactorValues } from "../../../../../helpers/helpers";
import { useSelector } from "react-redux";

export default function SideInvoicePreview({ store, values, image }) {
  const storedDetails = values?.storeDetails?.split("\n");
  const invoiceFooter = values?.description?.split("\n");
  const { language } = useSelector((state) => state.auth);
  let selectedLanguage =
    language === "arabic" && values?.invoiceLanguage?.value === "ar"
      ? "ar"
      : language === "english" && values?.invoiceLanguage?.value === "en"
      ? "en"
      : language === "arabic" && values?.invoiceLanguage?.value === "en"
      ? "en"
      : language === "english" && values?.invoiceLanguage?.value === "ar"
      ? "ar"
      : "en";

  return (
    <div className={`invoice-side-wrap ${selectedLanguage === "ar" && language === "english" ? "ltr" : "ltr"}`}>
      <Card style={{ padding: "1.25rem 0rem" }}>
        <div className="invoice-items row">
          <div className="col-12">
            <div className="pos-container">
              <div className="pos-invoice" style={{ width: "100%", marginTop: "0rem" }}>
                {image && image[0]?.image_url ? (
                  <>
                    <img
                      style={{ maxWidth: "328px", height: "100px", width: "100px", objectFit: "contain" }}
                      src={image[0]?.image_url}
                      alt="loading"
                    />
                  </>
                ) : (
                  ""
                )}
                <div className="bold-color">
                  {" "}
                  <h4 className="mt-3">
                    <b style={{ fontWeight: 600, fontSize: "18px" }}>{values?.name ? values?.name : ""}</b>
                  </h4>
                  <div className="mt-0 mb-0 bold-color">
                    {values?.taxNumber?.length > 0 ? `${refactorValues(values, selectedLanguage).taxNo} ${values?.taxNumber}` : ""}
                  </div>
                  <div className="mt-0 mb-0 bold-color">{values?.address ? values?.address : ""}</div>
                  <div className="mt-0 mb-0 bold-color">
                    {storedDetails?.length > 1 ? (
                      <>
                        {storedDetails?.map((item, index) => {
                          return (
                            <>
                              <div key={index}>{item}</div>
                              {item === "" && <br />}
                            </>
                          );
                        })}
                      </>
                    ) : (
                      <>{storedDetails[0]}</>
                    )}
                  </div>
                  {values?.taxNumber ? (
                    <>
                      <div className="mt-3  bold-color" style={{ fontSize: "1rem", fontWeight: 400 }}>
                        <div className=" mw-100 "></div>
                        {refactorValues(values, selectedLanguage).simplifiedInvoice}
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                  <h1 className="mt-3">{refactorValues(values, selectedLanguage).total}</h1>
                  <h5 style={{ fontWeight: 400, fontSize: "1rem" }}>{refactorValues(values, selectedLanguage).totalText}</h5>
                  <div className="doted-line mb-2 mt-2"></div>
                  <div className="row">
                    <div className={`col-12 d-flex align-items-start ${selectedLanguage === "en" ? "flex-row" : "flex-row-reverse"}`}>
                      <div className="bold-color">{refactorValues(values, selectedLanguage).invoiceNo}</div>
                      <div className="blurry-text mx-2">E1-00001</div>
                    </div>
                  </div>
                  <div className="row">
                    <div className={`col-12 d-flex align-items-start ${selectedLanguage === "en" ? "flex-row" : "flex-row-reverse"}`}>
                      <div className="bold-color">{refactorValues(values, selectedLanguage).date}</div>
                      <div className="blurry-text mx-2">01/01/2025 - 13:16:25</div>
                    </div>
                  </div>
                  <div className="row">
                    <div className={`col-12 d-flex align-items-start ${selectedLanguage === "en" ? "flex-row" : "flex-row-reverse"}`}>
                      <div className="bold-color"> {refactorValues(values, selectedLanguage).cashier}</div>
                      <div className="blurry-text mx-2">Ismael</div>
                    </div>
                    <div className="col-5 text-right"></div>
                  </div>
                  <div className="row">
                    <div className={`col-12 d-flex align-items-start ${selectedLanguage === "en" ? "flex-row" : "flex-row-reverse"}`}>
                      <div className="bold-color"> {refactorValues(values, selectedLanguage).pos}</div>
                      <div className="blurry-text mx-2">POS 1</div>
                    </div>

                    <div className="col-5 text-right"></div>
                  </div>
                  {values?.showCustomerInformation ? (
                    <>
                      {" "}
                      <div className="doted-line mb-2 mt-2"></div>
                      <div className="row">
                        <div className={`col-12 d-flex align-items-start ${selectedLanguage === "en" ? "flex-row" : "flex-row-reverse"}`}>
                          <div className="bold-color">{refactorValues(values, selectedLanguage).customer}</div>
                          <div className="blurry-text mx-2">Ismael</div>
                        </div>

                        <div className="col-5 text-right"></div>
                      </div>
                      <div className="row">
                        <div className={`col-12 d-flex align-items-start ${selectedLanguage === "en" ? "flex-row" : "flex-row-reverse"}`}>
                          <div className="bold-color">{refactorValues(values, selectedLanguage).mobile}</div>
                          <div className="blurry-text mx-2">05058983985</div>
                        </div>

                        <div className="col-5 text-right"></div>
                      </div>
                    </>
                  ) : null}
                  <div className="doted-line mb-2 mt-2"></div>
                  <div className="row">
                    <div
                      className={`col-12 d-flex justify-content-between align-items-start ${
                        selectedLanguage === "en" ? "flex-row" : "flex-row-reverse"
                      }`}
                    >
                      <div className={`blurry-text ${selectedLanguage === "en" ? "text-left" : "text-right"} `}>
                        product1
                        <div>
                          <div className="not-blurry-text">{values?.showNotes ? refactorValues(values, selectedLanguage).notesAppearHere : ""}</div>
                          <div>1 X 10.00</div>
                        </div>
                      </div>

                      <div className="blurry-text mx-2">10.00</div>
                    </div>

                    {/* <div className="col-5 text-right"></div> */}
                  </div>
                  <div className="row mt-5">
                    <div
                      className={`col-12 d-flex justify-content-between align-items-start ${
                        selectedLanguage === "en" ? "flex-row" : "flex-row-reverse"
                      }`}
                    >
                      <div className="blurry-text">
                        {" "}
                        product1 <div>1 X 10.00</div>
                      </div>
                      <div className="blurry-text mx-2">10.00</div>
                    </div>

                    {/* <div className="col-5 text-right"></div> */}
                  </div>
                  <div className=" mt-5 row">
                    <div
                      className={`col-12 d-flex justify-content-between align-items-start ${
                        selectedLanguage === "en" ? "flex-row" : "flex-row-reverse"
                      }`}
                    >
                      <div className="blurry-text">
                        {" "}
                        product1 <div>1 X 10.00</div>
                      </div>
                      <div className="blurry-text mx-2">10.00</div>
                    </div>

                    {/* <div className="col-5 text-right"></div> */}
                  </div>
                  <div className="doted-line mb-2" style={{ marginTop: values?.taxNumber?.length <= 0 && !image ? ".5rem" : ".5rem" }}></div>
                  <div className="row">
                    <div className={`col-12  ${selectedLanguage === "en" ? "text-left" : "text-right"}`}>
                      <div
                        className={`d-flex justify-content-between align-items-center ${selectedLanguage === "en" ? "flex-row" : "flex-row-reverse"}`}
                      >
                        <div className="bold-color" style={{ fontWeight: 600 }}>
                          {refactorValues(values, selectedLanguage).totalTextSec}
                        </div>
                        <div className="blurry-text"> 140.00</div>
                      </div>

                      <div className="col-5 text-right"></div>
                    </div>
                  </div>
                  <div className="row">
                    <div className={`col-12 ${selectedLanguage === "en" ? "text-left" : "text-right"}`}>
                      <div
                        className={`d-flex justify-content-between align-items-center ${selectedLanguage === "en" ? "flex-row" : "flex-row-reverse"}`}
                      >
                        <div className="bold-color">{refactorValues(values, selectedLanguage).cash} </div>
                        <div className="blurry-text"> 150.00</div>
                      </div>
                      <div className="col-5 text-right"></div>
                    </div>
                  </div>
                  <div className="row">
                    <div className={`col-12 ${selectedLanguage === "en" ? "text-left" : "text-right"}`}>
                      <div
                        className={`d-flex justify-content-between align-items-center ${selectedLanguage === "en" ? "flex-row" : "flex-row-reverse"}`}
                      >
                        <div className="bold-color">{refactorValues(values, selectedLanguage).change} </div>
                        <div className="blurry-text"> 10.00</div>
                      </div>
                      {/* <div className="bold-color">{refactorValues(values).change}</div> */}
                      <div className="col-5 text-right"></div>
                    </div>
                  </div>
                  <div className="doted-line mb-2" style={{ marginTop: values?.taxNumber?.length <= 0 && !image ? "6rem" : "1rem" }}></div>
                  {values?.taxNumber ? (
                    <>
                      <div className="pos-qrcode">
                        <img src={IconQr} alt="loading" style={{ width: "100px", height: "100px" }} />
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                  {invoiceFooter && (
                    <>
                      {" "}
                      <div className="my-4  bold-color" style={{ fontSize: "1rem", marginBottom: "1.5rem" }}>
                        {invoiceFooter?.length > 1 ? (
                          <div>
                            {invoiceFooter?.map((item, index) => {
                              return (
                                <>
                                  <div key={index}>{item}</div>
                                  {item === "" && <br />}
                                </>
                              );
                            })}
                          </div>
                        ) : (
                          <div>{invoiceFooter[0]}</div>
                        )}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
}
