import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import PageNavigator from "../../../components/pagination/PageNavigator";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import DeleteIcon from "../../../../assets/img/company/Vector.svg";
import { i18n } from "../../private/languageSelector";
import SubscriptionDialog from "../company-edit-Dialog/SubscriptionDialog";
import { deleteRole, fetchRoles } from "../../../../_redux/roles/rolesActions";
import { RolesEditForm } from "../company-edit-Dialog/roles/RoleEditForm";
import toast from "react-hot-toast";
import CustomSearch from "../../../components/searchBar/CustomSearch";
import { sortCaret } from "../../../../_metronic/_helpers";

const Role = () => {
  const dispatch = useDispatch();
  const { language } = useSelector((state) => state.auth);
  const { roles } = useSelector(
    (state) => ({
      roles: state.roles.entities,
    }),
    shallowEqual
  );

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [searchValue, setSearchValue] = useState("");
  const [showSearchInput, setShowSearchInput] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [openRolesAddDialog, setOpenRolesAddDialog] = useState(false);
  const [edit, setEdit] = useState(false);
  const [selectedRole, setSelectedRole] = useState(null);
  const [showDeleteRoleModal, setShowDeleteRoleModal] = useState(false);
  const [sortBy, setSortBy] = useState("desc");
  const [sortAt, setSortAt] = useState("created_at");

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (newLimit) => {
    setLimit(newLimit);
  };

  const handleEditRoleClick = (row) => {
    setEdit(true);
    setSelectedRole(row);
    setOpenRolesAddDialog(true);
  };

  const highlightMatch = (text, query) => {
    if (!query) return text;
    const parts = text.split(new RegExp(`(${query})`, "gi"));
    return parts.map((part, index) => (part.toLowerCase() === query.toLowerCase() ? <b key={index}>{part}</b> : part));
  };

  useEffect(() => {
    dispatch(fetchRoles(limit, page, sortBy, sortAt));
  }, [dispatch, limit, page, sortBy, sortAt]);

  const filterRoles =
    roles &&
    roles.map((item, index) => {
      return {
        ...item,
        Users: item?.users?.length,
        Access:
          item.pos === true && item.backOffice === true
            ? i18n("global.BackOfficeAndPos")
            : item.pos === true && item.backOffice === false
            ? i18n("global.POS")
            : item.pos === false && item.backOffice === true
            ? i18n("global.BackOffice")
            : "",
        name:
          item?.caseSensitiveName && item?.caseSensitiveName === "Owner"
            ? i18n("Company.Owner")
            : item?.caseSensitiveName === "Manager"
            ? i18n("Company.manager")
            : item?.caseSensitiveName === "Cashier"
            ? i18n("Company.Cashier")
            : item?.name,
      };
    });

  let filteredRoles = filterRoles?.filter((role) => {
    let searchText = searchValue?.toLowerCase();
    return role?.name?.toLowerCase()?.includes(searchText);
  });

  const columns = [
    {
      dataField: "name",
      text: i18n("global.Name"),
      headerAlign: "start",
      headerStyle: {
        textAlign: "left",
        fontWeight: "400",
        fontSize: "14px",
        color: "#828282",
      },
      align: language === "arabic" ? "start" : "left",
      style: {
        paddingTop: "15px",
        paddingBottom: "15px",
      },
      formatter: (cell) => {
        const formattedCell = cell;
        return highlightMatch(formattedCell, searchValue);
      },
      sortCaret: sortCaret,
      sort: true,
    },
    {
      dataField: "Access",
      text: i18n("Company.Access"),
      align: language === "arabic" ? "center" : "center",
      headerStyle: {
        textAlign: "center",
        fontWeight: "400",
        fontSize: "14px",
        color: "#828282",
        // paddingRight:"62px",
        paddingRight: "0px",
      },
      style: {
        paddingTop: "15px",
        paddingBottom: "15px",
      },
      formatter: (cell) => highlightMatch(cell, searchValue),
      sortCaret: sortCaret,
      sort: true,
    },
    {
      dataField: "Users",
      text: i18n("global.Users"),
      align: language === "arabic" ? "left" : "right",
      headerStyle: {
        textAlign: language === "arabic" ? "left" : "right",
        fontWeight: "400",
        fontSize: "14px",
        color: "#828282",
      },
      style: {
        paddingTop: "15px",
        paddingBottom: "15px",
      },
      formatter: (cell) => {
        const formattedCell = `${cell}`;
        return highlightMatch(formattedCell, searchValue);
      },
      sortCaret: sortCaret,
      sort: true,
    },
  ];

  const selectRow = {
    mode: "checkbox",
    clickToSelect: false,
    onSelect: (row, isSelected) => {
      if (row.originalName === "owner" || row.originalName === "cashier" || row.originalName === "manager") {
        return false;
      }

      const newSelectedRows = isSelected ? [...selectedRows, row._id] : selectedRows.filter((id) => id !== row._id);
      setSelectedRows(newSelectedRows);
      return true;
    },
    onSelectAll: (isSelected, rows) => {
      const newSelectedRows = isSelected
        ? rows.filter((row) => row.originalName !== "owner" && row.originalName !== "cashier" && row.originalName !== "manager").map((row) => row._id)
        : [];
      setSelectedRows(newSelectedRows);
    },
    nonSelectable: roles
      ?.filter((row) => row.originalName === "owner" || row.originalName === "cashier" || row.originalName === "manager")
      .map((row) => row._id),
  };

  const handleSearchInputChange = (e) => {
    setSearchValue(e.target.value);
  };

  if (!roles) {
    return null;
  }

  const confirmDelete = () => {
    if (selectedRows.length > 0 && !openRolesAddDialog) {
      for (let row of selectedRows) {
        dispatch(deleteRole(row)).catch((error) => {
          error.clientMessage = "Can't delete Role";
          toast.error("Can't delete Role");
        });
      }
      setSelectedRows([]);
      setShowDeleteRoleModal(false);
    } else if (selectedRole?._id) {
      dispatch(deleteRole(selectedRole._id)).catch((error) => {
        error.clientMessage = "Can't delete Role";
        toast.error("Can't delete Role");
      });
      setSelectedRows([]);
      setShowDeleteRoleModal(false);
      setOpenRolesAddDialog(false);
    }
  };

  const handleSorting = (type, sortOrder, sortField, data, page, sizePerPage) => {
    if (type === "sort") {
      setSortBy(sortOrder);
      if (sortField === "Access") {
        setSortAt("access");
      } else if (sortField === "Users") {
        setSortAt("users");
      } else {
        setSortAt(sortField);
      }
    }
  };

  return (
    <>
      <div className="table-width" style={{ backgroundColor: "#FFFFFF", borderRadius: "0.42rem", float: language === "arabic" ? "right" : "left" }}>
        <div className={`pl-4 pr-4 py-4 mb-3 d-flex justify-content-between align-items-center ${language === "arabic" ? "rtl" : "ltr"}`}>
          <CustomSearch
            handleSearchInputChange={handleSearchInputChange}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            setShowSearchInput={setShowSearchInput}
            showSearchInput={showSearchInput}
          />
          <div>
            {selectedRows.length > 0 && (
              <span className={` cursor-pointer ${language === "arabic" ? "ml-5" : "mr-5"}`} onClick={() => setShowDeleteRoleModal(true)}>
                <img src={DeleteIcon} alt="Delete" />
              </span>
            )}
            <span
              className="add-text-btn"
              onClick={() => {
                setEdit(false);
                setSelectedRole(null);
                setOpenRolesAddDialog(true);
              }}
            >
              {i18n("Company.AddRole")}
            </span>
          </div>
        </div>
        <BootstrapTable
          wrapperClasses="table-responsive"
          classes={`table table-head-custom table-vertical-center overflow-hidden ${language === "arabic" ? "rtl" : "ltr"}`}
          remote
          bordered={false}
          bootstrap4
          keyField="_id"
          columns={columns}
          data={filteredRoles}
          selectRow={selectRow}
          onTableChange={(type, { page, sizePerPage, sortField, sortOrder, data }) =>
            handleSorting(type, sortOrder, sortField, data, page, sizePerPage)
          }
          rowClasses="cursor-pointer"
          rowEvents={{
            onClick: (e, row, rowIndex) => {
              if (e.target.type !== "checkbox") {
                handleEditRoleClick(row);
              }
            },
          }}
        />
        <div className="pb-3 d-flex justify-content-end mr-3 ml-3">
          <PageNavigator page={page} limit={limit} handlePageChange={handlePageChange} handleLimitChange={handleLimitChange} data={filteredRoles} />
        </div>
      </div>
      <RolesEditForm
        openRolesAddDialog={openRolesAddDialog}
        setOpenRolesAddDialog={setOpenRolesAddDialog}
        edit={edit}
        roleForEdit={selectedRole}
        setShowDeleteRoleModal={setShowDeleteRoleModal}
      />

      {/* delete role modal  */}
      <SubscriptionDialog
        show={showDeleteRoleModal}
        onHide={() => setShowDeleteRoleModal(false)}
        modalWidth="350px"
        modalHeight="185px"
        message={selectedRows.length === 1 ? "Are you sure you want to delete the selected Role?" : "Are you sure you want to delete these Roles?"}
        messageWidth="320px"
        messageHeight="44px"
        messageColor="#333333"
        messageFontWeight="400"
        messageFontSize="16px"
        messageLineHeight="21.79px"
        primaryAction={() => setShowDeleteRoleModal(false)}
        primaryLabel={i18n("SubscriptionModals.CANCEL")}
        primaryLabelWidth="55px"
        primaryLabelHeight="20px"
        primaryLabelFontWeight="700"
        primaryLabelFontSize="14px"
        primaryLabelLineHeight="20px"
        primaryLabelColor="#828282"
        secondaryAction={confirmDelete}
        secondaryLabel={"DELETE"}
        secondaryLabelWidth="67px"
        secondaryLabelHeight="20px"
        secondaryLabelFontWeight="700"
        secondaryLabelFontSize="14px"
        secondaryLabelLineHeight="20px"
        secondaryLabelColor="#EB5757"
        isCenteredOnMobile
      />
    </>
  );
};

export default Role;
