import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { Input, Card, ReactSelector } from "../../../../../_metronic/_partials/controls";
import IconClose from "../../../../../assets/img/icon-close.svg";
import DeleteIcon from "../../../../../assets/img/company/Vector.svg";

import * as taxesActions from "../../../../../_redux/taxes/taxesActions";
import { i18n } from "../../../private/languageSelector";
import SubscriptionDialog from "../SubscriptionDialog";

export const AddTaxSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, "Minimum 3 Alphabets")
    .max(50, "Maximum 20 Alphabets")
    .required("English name is required"),
  taxRate: Yup.number().required("Tax rate is required"),
});

const initAddState = {
  name: "",
  taxRate: "",
  type: { value: "Included in the price", label: i18n("global.IncludedInPrice") },
};

const defaultType = [
  { value: "Included in the price", label: i18n("global.IncludedInPrice") },
  { value: "Added to the price", label: i18n("global.AddedToPrice") },
];

export function TaxesEditForm({ tax, edit, openTaxesAddDialog, setOpenTaxesAddDialog, setShowDeleteTaxModal }) {
  const dispatch = useDispatch();
  const [isFormFilled, setIsFormFilled] = useState(false);
  const [showUnsavedChangesModal, setShowUnsavedChangesModal] = useState(false);

  const handleClosePaymentDialog = (dirty, resetForm) => {
    if (dirty) {
      setShowUnsavedChangesModal(true);
    } else {
      setOpenTaxesAddDialog(false);
      resetForm();
    }
  };

  const { language } = useSelector((state) => state.auth);
  return (
    <Formik
      enableReinitialize={true}
      initialValues={
        edit
          ? {
              ...tax,
              type: {
                value: tax?.type,
                label: tax?.type?.includes("Added")
                  ? i18n("global.AddedToPrice")
                  : tax?.type?.includes("Included")
                  ? i18n("global.IncludedInPrice")
                  : tax?.type,
              },
              taxScheme: { value: tax?.taxScheme, label: tax?.taxScheme },
            }
          : initAddState
      }
      validationSchema={AddTaxSchema}
      onSubmit={(values, { resetForm }) => {
        if (edit) {
          dispatch(
            taxesActions.updateTax(tax?._id, {
              name: values.name,
              taxRate: values.taxRate,
              type: values.type?.value,
              taxScheme: values.taxScheme?.label,
            })
          );
        } else {
          dispatch(
            taxesActions.createTax({
              name: values.name,
              taxRate: values.taxRate,
              type: values.type?.value ? values.type?.value : values.type,
              taxScheme: values.taxScheme?.label ? values.taxScheme?.label : values.taxScheme,
            })
          );
        }
        resetForm();
        setOpenTaxesAddDialog(false);
      }}
    >
      {({ handleSubmit, values, setFieldValue, dirty, resetForm }) => {
        if (values.name && values.taxRate && values.type.value) {
          setIsFormFilled(true);
        }

        return (
          <>
            <Modal
              className={`${language === "arabic" ? "modal-dialog-arabic modal-drawer" : " modal-drawer"}  my-profile invoice-drawer`}
              size="lg"
              aria-labelledby="example-modal-sizes-title-lg"
              show={openTaxesAddDialog}
              onHide={() => handleClosePaymentDialog(dirty, resetForm)}
            >
              <Modal.Header>
                <div className="d-flex justify-content-around align-items-center">
                  <span onClick={() => handleClosePaymentDialog(dirty, resetForm)} className="cursor-pointer">
                    <img src={IconClose} alt="loading" />
                  </span>
                  <span>
                    {edit === false ? (
                      <span className="add-edit-tax">{i18n("global.AddTax")}</span>
                    ) : (
                      <span className="add-edit-tax">{i18n("global.EditTax")}</span>
                    )}
                  </span>
                  <span
                    onClick={handleSubmit}
                    className="add-edit-tax cursor-pointer"
                    style={{
                      color: isFormFilled && "#FFFFFF",
                      opacity: isFormFilled ? 1 : 0.5,
                    }}
                  >
                    {i18n("UserProfile.Save")}
                  </span>
                </div>
              </Modal.Header>
              <Modal.Body className={`overlay overlay-block cursor-default ${language === "arabic" ? "rtl" : "ltr"}`}>
                <Form className="form form-label-right">
                  <Card>
                    <div className="invoice-title border-bottom-0">
                      <p className="m-0">{i18n("global.TaxInformation")}</p>
                    </div>
                    <div className="invoice-items row">
                      <div className="col-12">
                        <div className="form-group">
                          <Field name="name" component={Input} placeholder={i18n("global.TaxName")} label={i18n("global.TaxName")} />
                        </div>
                        <div className="form-group">
                          <Field name="taxRate" component={Input} placeholder={i18n("global.TaxRate")} label={i18n("global.TaxRate")} />
                        </div>
                      </div>
                    </div>
                  </Card>
                  <Card>
                    <div className="invoice-title border-bottom-0">
                      <p className="m-0">{i18n("global.DefaultTaxScheme")}</p>
                    </div>
                    <div className="invoice-items row">
                      <div className="col-12">
                        <div className="form-group input-select w-100">
                          <Field
                            name="type"
                            placeholder={i18n("global.Type")}
                            component={ReactSelector}
                            options={defaultType.map((item) => ({
                              value: item.value,
                              label: item.label,
                            }))}
                            onChange={(option) => {
                              setFieldValue("type", option);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </Card>

                  {edit ? (
                    <Card>
                      <div className="w-100">
                        <div className="text-center">
                          <img
                            src={DeleteIcon}
                            alt="delete"
                            width={18}
                            height={20}
                            className="cursor-pointer"
                            onClick={() => setShowDeleteTaxModal(true)}
                          />
                        </div>
                      </div>
                    </Card>
                  ) : null}

                  {/* un-saved changes modal */}
                  <SubscriptionDialog
                    modalWidth={"350px"}
                    modalHeight={"185px"}
                    modalBackgroundColor={"#FFFFFF"}
                    show={showUnsavedChangesModal}
                    onHide={() => setShowUnsavedChangesModal(false)}
                    title={i18n("SubscriptionModals.UnsavedChanges")}
                    titleWidth={"140px"}
                    titleHeight={"20px"}
                    titleFontWeight={"700"}
                    titleFontSize={"16px"}
                    titleLineHeight={"20px"}
                    titleColor={"#333333"}
                    message={i18n("SubscriptionModals.AreYouSureYouWantToContinueWithoutSavingChanges")}
                    messageWidth={"320px"}
                    messageHeight={"44px"}
                    messageFontSize={"16px"}
                    messageFontWeight={"400"}
                    messageLineHeight={"21.79px"}
                    messageColor={"#333333"}
                    primaryLabel={i18n("SubscriptionModals.CANCEL")}
                    primaryAction={() => setShowUnsavedChangesModal(false)}
                    primaryLabelWidth={"55px"}
                    primaryLabelHeight={"20px"}
                    primaryLabelFontWeight={"700"}
                    primaryLabelFontSize={"14px"}
                    primaryLabelLineHeight={"20px"}
                    primaryLabelColor={"#828282"}
                    secondaryAction={() => {
                      setShowUnsavedChangesModal(false);
                      setOpenTaxesAddDialog(false);
                      setIsFormFilled(false);
                      resetForm();
                    }}
                    secondaryLabel={i18n("SubscriptionModals.CONTINUE")}
                    secondaryLabelWidth={"75px"}
                    secondaryLabelHeight={"20px"}
                    secondaryLabelFontWeight={"700"}
                    secondaryLabelFontSize={"14px"}
                    secondaryLabelLineHeight={"20px"}
                    secondaryLabelColor={"#2D9CDB"}
                    isCenteredOnMobile
                  />
                </Form>
              </Modal.Body>
            </Modal>
          </>
        );
      }}
    </Formik>
  );
}
