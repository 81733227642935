import React, { createContext, useState, useContext, useEffect } from "react";

const AsideContext = createContext({
  isAsideOpen: false,
  toggleAside: () => {},
  openCompanyMenuList: false,
  toggleCompanyMenuList: () => {},
  popoverOpen: false,
  togglePopover: () => {},
});

export const AsideProvider = ({ children }) => {
  const [isAsideOpen, setIsAsideOpen] = useState(true);
  const [openCompanyMenuList, setOpenCompanyMenuList] = useState(false);
  const [popoverOpen, setPopoverOpen] = useState(false);

  const toggleAside = () => {
    setIsAsideOpen((prevIsAsideOpen) => !prevIsAsideOpen);
  };

  const toggleCompanyMenuList = () => {
    setOpenCompanyMenuList((prevOpenCompanyMenuList) => !prevOpenCompanyMenuList);
  };

  const closeCompanyMenuList = () => {
    setOpenCompanyMenuList(true);
  };

  const togglePopover = () => {
    setPopoverOpen((prevPopoverOpen) => !prevPopoverOpen);
  };

  return (
    <AsideContext.Provider
      value={{
        setPopoverOpen,
        isAsideOpen,
        toggleAside,
        openCompanyMenuList,
        toggleCompanyMenuList,
        popoverOpen,
        togglePopover: togglePopover,
        closeCompanyMenuList,
      }}
    >
      {children}
    </AsideContext.Provider>
  );
};

export const useAside = () => useContext(AsideContext);
