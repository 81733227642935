import React, { useEffect, useRef, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import PageNavigator from "../../../components/pagination/PageNavigator";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
// import SearchIcon from "../../../../assets/img/company/SearchIcon.svg";
import DeleteIcon from "../../../../assets/img/company/Vector.svg";
import noCategoryIcon from "../../../../assets/img/noCategory.svg";
import { i18n } from "../../private/languageSelector";
import SubscriptionDialog from "../../company/company-edit-Dialog/SubscriptionDialog";
import * as actions from "../../../../_redux/discount/discountActions";
import { DiscountEditForm } from "./DiscountEditForm";
import CustomSearch from "../../../components/searchBar/CustomSearch";
import { sortCaret } from "../../../../_metronic/_helpers";

const DiscountsPage = () => {
  const dispatch = useDispatch();
  const { language } = useSelector((state) => state.auth);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [openDiscountAddDialog, setOpenDiscountAddDialog] = useState(false);
  const [sortBy, setSortBy] = useState("desc");
  const [sortAt, setSortAt] = useState("created_at");

  const { discounts } = useSelector(
    (state) => ({
      discounts: state.discounts?.entities,
    }),
    shallowEqual
  );
  useEffect(() => {
    dispatch(actions.fetchDiscounts(limit, page, sortBy, sortAt));
  }, [dispatch, limit, page, sortAt, sortBy]);

  // const searchInputRef = useRef(null);

  const [searchValue, setSearchValue] = useState("");
  const [showSearchInput, setShowSearchInput] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [edit, setEdit] = useState(false);
  const [selectedDiscount, setSelectedDiscount] = useState(null);
  const [showDeleteDiscountModal, setShowDeleteDiscountModal] = useState(false);

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };
  const handleLimitChange = (newLimit) => {
    setLimit(newLimit);
  };

  const handleEditTaxClick = (row) => {
    setEdit(true);
    setSelectedDiscount(row);
    setOpenDiscountAddDialog(true);
  };
  const highlightMatch = (text, query) => {
    if (!query) return text;
    const parts = text.split(new RegExp(`(${query})`, "gi"));
    return parts.map((part, index) => (part.toLowerCase() === query.toLowerCase() ? <b key={index}>{part}</b> : part));
  };

  // useEffect(() => {
  //   const handleClickOutside = (event) => {
  //     if (searchInputRef.current && !searchInputRef.current.contains(event.target) && searchValue === "") {
  //       setShowSearchInput(false);
  //     }
  //   };
  //   document.addEventListener("mousedown", handleClickOutside);
  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, [searchValue]);

  const columns = [
    {
      dataField: "businessId",
      text: i18n("Customer.ID"),
      headerAlign: "start",
      headerStyle: {
        textAlign: "left",
        fontWeight: "400",
        fontSize: "14px",
        color: "#828282",
      },
      align: language === "arabic" ? "start" : "left",
      style: {
        paddingTop: "15px",
        paddingBottom: "15px",
      },
      sort: true,
      sortCaret: sortCaret,
      // formatter: (cell) => highlightMatch(cell, searchValue),
    },
    {
      dataField: "name",
      text: i18n("Customer.NAME"),
      align: language === "arabic" ? "start" : "left",
      headerStyle: {
        textAlign: "start",
        fontWeight: "400",
        fontSize: "14px",
        color: "#828282",
      },
      style: {
        paddingTop: "15px",
        paddingBottom: "15px",
      },
      formatter: (cell) => highlightMatch(cell, searchValue),
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "discount",
      text: i18n("Customer.Value"),
      align: language === "arabic" ? "start" : "left",
      headerStyle: {
        textAlign: "start",
        fontWeight: "400",
        fontSize: "14px",
        color: "#828282",
      },
      style: {
        paddingTop: "15px",
        paddingBottom: "15px",
      },
      formatter: (cell) => {
        const formattedCell = `${cell}`;
        return highlightMatch(formattedCell, searchValue);
      },
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "restrictedAccess",
      text: i18n("Customer.RestrictedAccess"),
      align: language === "arabic" ? "start" : "left",
      headerStyle: {
        textAlign: "start",
        fontWeight: "400",
        fontSize: "14px",
        color: "#828282",
      },
      style: {
        paddingTop: "15px",
        paddingBottom: "15px",
      },
      formatter: (cell) => {
        const formattedCell = `${cell === "Yes" ? i18n("Category.Yes") : i18n("Category.No")}`;
        return highlightMatch(formattedCell, searchValue);
      },
      sort: true,
      sortCaret: sortCaret,
    },
  ];

  const selectRow = {
    mode: "checkbox",
    clickToSelect: false,
    onSelect: (row, isSelected) => {
      const newSelectedRows = isSelected ? [...selectedRows, row._id] : selectedRows.filter((id) => id !== row._id);
      setSelectedRows(newSelectedRows);
      return true;
    },
    onSelectAll: (isSelected, rows) => {
      const newSelectedRows = isSelected ? rows.map((row) => row._id) : [];
      setSelectedRows(newSelectedRows);
    },
  };

  const handleSearchInputChange = (e) => {
    setSearchValue(e.target.value);
  };

  if (!discounts) {
    return null;
  }

  const confirmDelete = () => {
    if (selectedRows.length > 0 && !openDiscountAddDialog) {
      for (let id of selectedRows) {
        dispatch(actions.deleteDiscount(id))
          .then((res) => {
            dispatch(actions.fetchDiscounts(limit, page, sortBy, sortAt));
          })
          .catch((error) => {
            error.clientMessage = "Can't delete discount";
            setShowDeleteDiscountModal(true);
          });
      }
    } else if (selectedDiscount?._id) {
      dispatch(actions.deleteDiscount(selectedDiscount?._id))
        .then((res) => {
          dispatch(actions.fetchDiscounts(limit, page, sortBy, sortAt));
        })
        .catch((error) => {
          error.clientMessage = "Can't delete discount";
          setShowDeleteDiscountModal(true);
        });
    }
    setSelectedRows([]);
    setShowDeleteDiscountModal(false);
    setOpenDiscountAddDialog(false);
    setSelectedDiscount(null);
  };

  let refactoredDiscounts = discounts.map((item) => {
    return {
      ...item,
      discount: `${item?.value !== null ? item?.value : ""} ${item.type}`,
      restrictedAccess: item.restricted ? "Yes" : "No",
    };
  });

  let filteredDiscounts = refactoredDiscounts?.filter((item) => {
    let searchText = searchValue?.toLowerCase();
    return item?.name?.toLowerCase()?.includes(searchText) || item?.businessId?.toLowerCase()?.includes(searchText);
  });

  const handleSorting = (type, sortOrder, sortField, data, page, sizePerPage) => {
    if (type === "sort") {
      setSortBy(sortOrder);
      if (sortField === "discount") {
        setSortAt("value");
      } else if (sortField === "restrictedAccess") {
        setSortAt("restricted");
      } else setSortAt(sortField);
    }
  };
  return (
    <>
      <div className={`${language === "arabic" ? "rtl" : "ltr"}`}>
        <div style={{ width: "100%", backgroundColor: "#FFFFFF", borderRadius: "0.42rem" }}>
          <div className="pl-4 pr-4 py-4 mb-3 d-flex justify-content-between align-items-center">
            <CustomSearch
              handleSearchInputChange={handleSearchInputChange}
              searchValue={searchValue}
              setSearchValue={setSearchValue}
              setShowSearchInput={setShowSearchInput}
              showSearchInput={showSearchInput}
            />
            <div>
              {selectedRows.length > 0 && (
                <span className={`cursor-pointer ${language === "arabic" ? "ml-5" : "mr-5"}`} onClick={() => setShowDeleteDiscountModal(true)}>
                  <img src={DeleteIcon} alt="Delete" />
                </span>
              )}
              <span
                className="add-text-btn"
                onClick={() => {
                  setEdit(false);
                  setSelectedDiscount(null);
                  setOpenDiscountAddDialog(true);
                }}
              >
                {i18n("Discount.ADDDISCOUNT")}
              </span>
            </div>
            {/* <div style={{ cursor: "pointer" }} className="d-flex justify-content-between align-items-center">
              {showSearchInput && (
                <div ref={searchInputRef} className="position-relative">
                  <input
                    style={{
                      width: "400px",
                      border: "none",
                      borderBottom: "2px solid #2D9CDB",
                      borderRadius: "0",
                    }}
                    className="form-control mr-sm-2 ml-3"
                    type="search"
                    placeholder={"Search..."}
                    aria-label="Search"
                    value={searchValue}
                    onChange={handleSearchInputChange}
                  />
                </div>
              )}
              <img onClick={() => setShowSearchInput(!showSearchInput)} src={SearchIcon} alt="SearchImage" />
            </div> */}
          </div>
          <BootstrapTable
            wrapperClasses="table-responsive"
            classes="table table-head-custom table-vertical-center overflow-hidden"
            remote
            bordered={false}
            bootstrap4
            keyField="_id"
            columns={columns}
            data={filteredDiscounts}
            selectRow={selectRow}
            rowClasses="cursor-pointer"
            onTableChange={(type, { page, sizePerPage, sortField, sortOrder, data }) =>
              handleSorting(type, sortOrder, sortField, data, page, sizePerPage)
            }
            rowEvents={{
              onClick: (e, row, rowIndex) => {
                if (e.target.type !== "checkbox") {
                  handleEditTaxClick(row);
                }
              },
            }}
          />

          {discounts && discounts?.length < 1 && (
            <div className="d-flex flex-column justify-content-center align-items-center mt-5 mb-5">
              <div className="category-icon-bg d-flex justify-content-center align-items-center ">
                <img src={noCategoryIcon} alt="noCategoryIcon" />
              </div>
              <p className="py-3 you-have-no-category">{i18n("Discount.NoDiscountYet")}</p>
            </div>
          )}

          <div className="pb-3 d-flex justify-content-end mr-3 ml-3">
            <PageNavigator
              page={page}
              limit={limit}
              handlePageChange={handlePageChange}
              handleLimitChange={handleLimitChange}
              data={filteredDiscounts}
            />
          </div>
        </div>

        <DiscountEditForm
          edit={edit}
          openDiscountDialog={openDiscountAddDialog}
          setOpenDiscountDialog={setOpenDiscountAddDialog}
          discountForEdit={selectedDiscount}
          setShowDeleteModal={setShowDeleteDiscountModal}
        />

        {/* delete category modal  */}
        <SubscriptionDialog
          show={showDeleteDiscountModal}
          onHide={() => setShowDeleteDiscountModal(false)}
          modalWidth="350px"
          modalHeight="185px"
          message={i18n("Discount.AreYouSureYouWantToDeleteThisDiscount")}
          messageWidth="320px"
          messageHeight="44px"
          messageColor="#333333"
          messageFontWeight="400"
          messageFontSize="16px"
          messageLineHeight="21.79px"
          primaryAction={() => setShowDeleteDiscountModal(false)}
          primaryLabel={i18n("SubscriptionModals.CANCEL")}
          primaryLabelWidth="55px"
          primaryLabelHeight="20px"
          primaryLabelFontWeight="700"
          primaryLabelFontSize="14px"
          primaryLabelLineHeight="20px"
          primaryLabelColor="#828282"
          secondaryAction={confirmDelete}
          secondaryLabel={i18n("Discount.DELETE")}
          secondaryLabelWidth="67px"
          secondaryLabelHeight="20px"
          secondaryLabelFontWeight="700"
          secondaryLabelFontSize="14px"
          secondaryLabelLineHeight="20px"
          secondaryLabelColor="#EB5757"
          isCenteredOnMobile
        />
      </div>
    </>
  );
};

export default DiscountsPage;
