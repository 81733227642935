import React, { useMemo, useState, useEffect, useRef } from "react";
import { Modal } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { useProductsUIContext } from "../../../../../pages/products/ProductsUIContext";
import { i18n } from "../../../../../pages/private/languageSelector";
import * as productActions from "../../../../../../_redux/products/productsActions";
import { AssignToCategory } from "../../../../../pages/products/product-assign-to-category/AssignToCategory";

export function ProductActionModal({ show, onHide, position }) {
  const { id } = useParams();
  const dispatch = useDispatch();

  const productsUIContext = useProductsUIContext();
  const productsUIProps = useMemo(() => {
    return {
      openEditProductDialog: productsUIContext.openEditProductDialog,
      openDeleteProductDialog: productsUIContext.openDeleteProductDialog,
      openViewProductDialog: productsUIContext.openViewProductDialog,
      queryParams: productsUIContext?.queryParams,
    };
  }, [productsUIContext]);

  const [openAssignCategoryDialog, setOpenAssignCategoryDialog] = useState(false);

  useEffect(() => {
    dispatch(productActions.fetchProduct(id));
  }, [dispatch, id]);

  const { productForEdit, userAccess, productFilterState, currentState } = useSelector(
    (state) => ({
      productForEdit: state?.products?.productForEdit,
      userAccess: state?.users?.userAccess,
      productFilterState: state?.products?.productFilterState,
      currentState: state.products,
    }),
    shallowEqual
  );
  const dropdownRef = useRef();

  const duplicateHandler = () => {
    const { entities } = currentState;

    let allSku = entities?.map((item) => item.sku);
    let sortedSku = allSku?.sort((a, b) => b - a);
    let nextSku = Number(sortedSku[0]) + 1;

    let duplicatedProduct = productForEdit?.category ? productForEdit : { ...productForEdit, category: "" };
    let duplicatedProductWithNewSku = { ...duplicatedProduct, sku: nextSku };

    dispatch(productActions.duplicateProduct(productFilterState, duplicatedProductWithNewSku, productsUIContext.queryParams, entities));
  };
  const { language } = useSelector((state) => state.auth);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef && show && !openAssignCategoryDialog && !dropdownRef?.current?.contains(event.target)) {
        onHide();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [show, openAssignCategoryDialog]);

  return (
    <div ref={dropdownRef}>
      {show && (
        <div
          style={{
            position: "absolute",
            right: language === "english" && "2rem",
            left: language === "arabic" && "2rem",
            top: position?.top,
            zIndex: 100,
            background: "#FFFFFF",
            boxShadow: "0px 4px 4px 0px #00000040",
            borderRadius: "5px",
            minWidth: "150px",
            textAlign: language === "arabic" ? "right" : "left",
          }}
        >
          {/* <Modal className="modal-drawer" size="lg" aria-labelledby="example-modal-sizes-title-lg" show={show} onHide={() => {}}>
        <Modal.Header>
          <button type="button" className="btn btn-close" onClick={onHide}>
            <img src={IconClose} alt="loading" />
          </button>

          {<Modal.Title>{i18n("MENU.Actions")}</Modal.Title>}
        </Modal.Header> */}

          <>
            {/* <Modal.Body className="overlay overlay-block cursor-default"> */}
            {userAccess?.editData ? (
              <div className="product-action" onClick={() => setOpenAssignCategoryDialog(true)}>
                {/* <img src={IconAssign} alt="loading" /> */}
                <div className="p-4">{i18n("MENU.AssignToCategory")}</div>
              </div>
            ) : (
              ""
            )}

            {/* {userAccess?.editData ? (
            <div className="product-action" onClick={() => productsUIProps.openEditProductDialog(id)}>
              <img src={IconEdit} alt="loading" />
              <b>{i18n("Customer.Edit")}</b>
            </div>
          ) : (
            ""
          )} */}

            {userAccess?.createData ? (
              <div className="product-action" onClick={() => productsUIProps.openEditProductDialog(id)}>
                {/* <img src={IconCopy} alt="loading" /> */}
                <div className="p-4">{i18n("MENU.DUPLICATE")}</div>
              </div>
            ) : (
              ""
            )}

            {userAccess?.deleteData ? (
              <div className="product-action" onClick={() => productsUIProps.openDeleteProductDialog(id)}>
                {/* <img src={IconDelete} alt="loading" /> */}
                <div className="p-4" style={{ color: "#EB5757" }}>
                  {i18n("MENU.Delete")}
                </div>
              </div>
            ) : (
              ""
            )}
            {/* </Modal.Body> */}
          </>

          {/* </Modal> */}
          <AssignToCategory
            openAssignCategoryDialog={openAssignCategoryDialog}
            setOpenAssignCategoryDialog={setOpenAssignCategoryDialog}
            productId={id}
            onHide={() => {
              setOpenAssignCategoryDialog(false);
              onHide();
            }}
          />
        </div>
      )}
    </div>
  );
}
