import React from "react";
import fatoratyLogo from "../../../assets/img/dark-logo-fatoraty.svg";
import { Link } from "react-router-dom";
import SelectLanguage from "../select-language/SelectLanguage";

function AuthCard({ children, selectedLanguage, error, text, link, id, linkText }) {
  return (
    <div className={selectedLanguage === "arabic" ? "rtl" : "ltr"}>
      <div style={{ marginBottom: "2rem" }}>
        <img src={fatoratyLogo} alt="logo" />
      </div>
      {error && (
        <div
          className=" alert alert-custom p-0  text-center alert-light-danger alert-dismissible"
          style={{
            background: "#FBDDDD",
            borderTopLeftRadius: "10px",
            borderTopRightRadius: "10px",
            borderBottomLeftRadius: "0px",
            borderBottomRightRadius: "0px",
            marginBottom: "-0.4rem",
          }}
        >
          <div className="alert-text font-weight-bold py-2">{error}</div>
        </div>
      )}
      <div className="login-form login-signin " style={{ background: "#FFFFFF", borderRadius: "10px", padding: "25px 18px 25px 18px" }}>
        {children}
      </div>
      <div className="d-flex pt-3 justify-content-between align-items-center">
        <div className="d-block ">
          <span className="already-account-text">{text}</span>
          <Link to={link} className={`font-weight-bold ${selectedLanguage === "arabic" ? "mr-2" : "ml-2"}`} id={id}>
            {linkText}
          </Link>
        </div>
        <SelectLanguage />
      </div>
    </div>
  );
}

export default AuthCard;
