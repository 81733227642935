import React from "react";

export function Checkbox({ isSelected, onChange, children, label, disabled, productView }) {
  return (
    <>
      {label && (
        <label>
          <b>{label}</b>
        </label>
      )}
      <label className={`checkbox checkbox-lg checkbox-single ${productView ? "checkbox-bg" : ""}`}>
        <input type="checkbox" checked={isSelected} onChange={onChange} disabled={disabled} style={{ background: "white" }} />
        {children}
        <span
          style={{
            height: productView ? "15px" : "17px",
            width: productView ? "15px" : "16px",
            border: !productView ? "1px solid" : "none",
            borderRadius: productView && "2px",
          }}
        />
      </label>
    </>
  );
}
