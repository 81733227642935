/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { connect } from "react-redux";
import { countries } from "country-data";
import { useSelector } from "react-redux";
import "react-phone-number-input/style.css";
import * as auth from "../_redux/authRedux";
import { useHistory } from "react-router-dom";
import { AsYouType } from "libphonenumber-js/max";
import countryToCurrency from "country-to-currency";
import countryList from "react-select-country-list";
import logo from "./../../../../assets/img/company-user.png";
import { i18n } from "../../../pages/private/languageSelector";
import {
  updateUser,
  updateUserCompany,
  getUserByToken,
} from "../_redux/authCrud";
import {
  ACCOUNT_TYPES,
  INDUSTRIES,
  CURRENCIES,
  PROFESSIONS,
} from "./../../../../helpers/constants";

let asYouType = new AsYouType();
const initialValues = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  password: "",
  acceptTerms: false,
};

const userInitialData = {
  profession: "",
  country: "",
  currency: "",
};

const companyInitialData = {
  companyName: "",
  industry: "",
  country: "",
  currency: "",
};
Yup.addMethod(Yup.mixed, "validatePhoneNumber", function(msg) {
  return this.test({
    name: "validatePhoneNumber",
    message: msg,
    test: (value) => {
      asYouType.reset();
      asYouType.input(value);
      return asYouType.getNumber().isValid();
    },
  });
});

const UserOrCompany = () => {
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [accountType, setAccountType] = useState("");
  const [userCountryName, setUserCountryName] = useState("");
  const [companyCountryName, setCompanyCountryName] = useState("");
  const [companyInitialValues, setCompanyInitialValues] = useState(
    companyInitialData
  );
  const [userInitialValues, setUserInitialValues] = useState(userInitialData);
  const [stepOneData] = useState(initialValues);
  const { user } = useSelector((state) => state.auth);
  const options = useMemo(() => countryList().getData(), []);

  const passwordValidation = i18n("Validation.INVALID_PASSWORD");
  const RegistrationSchema = Yup.object().shape({
    firstName: Yup.string()
      .min(3, i18n("Validation.Minimum3Symbols"))
      .max(50, i18n("Validation.Maximum50Symbols"))
      .matches(/^[a-zA-Z ]*$/, "Alphabets Required")
      .required(i18n("Validation.RequiredField")),
    lastName: Yup.string()
      .min(3, i18n("Validation.Minimum3Symbols"))
      .max(50, i18n("Validation.Maximum50Symbols"))
      .matches(/^[a-zA-Z ]*$/, "Alphabets Required")
      .required(i18n("Validation.RequiredField")),
    email: Yup.string()
      .email(i18n("Validation.RequiredField"))
      .min(3, i18n("Validation.Minimum3Symbols"))
      .max(50, i18n("Validation.Maximum50Symbols"))
      .matches(
        // eslint-disable-next-line
        /^\w+[\w-\.]*\@\w+((-\w+)|(\w*))\.[a-z]{2,3}$/,
        "Must contain e.g (example._-@example.com)"
      )
      .required(i18n("Validation.RequiredField")),
    phone: Yup.string()
      .validatePhoneNumber("Invalid Phone Number")
      .required(i18n("Validation.RequiredField")),
    password: Yup.string()
      .min(8, i18n("Validation.Minimum3Symbols"))
      .max(50, i18n("Validation.Maximum50Symbols"))
      .matches(/^(?=.*[0-9])(?=.*[a-z]).{8,50}$/, passwordValidation)
      .required(i18n("Validation.RequiredField")),
    acceptTerms: Yup.bool().required(
      i18n("SubscriptionModals.YouMustAcceptTermsConditions")
    ),
  });

  const IndividualSchema = Yup.object().shape({
    profession: Yup.string().required(i18n("Validation.RequiredField")),
    country: Yup.string().required(i18n("Validation.RequiredField")),
    currency: Yup.string().required(i18n("Validation.RequiredField")),
  });

  const CompanySchema = Yup.object().shape({
    companyName: Yup.string()
      .min(3, i18n("Validation.Minimum3Symbols"))
      .max(50, i18n("Validation.Maximum50Symbols"))
      .required(i18n("Validation.RequiredField")),
    industry: Yup.string().required(i18n("Validation.RequiredField")),
    country: Yup.string().required(i18n("Validation.RequiredField")),
    currency: Yup.string().required(i18n("Validation.RequiredField")),
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid exclamationSign";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues: stepOneData,
    validationSchema: RegistrationSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      enableLoading();
    },
  });

  const individualFormik = useFormik({
    initialValues: userInitialValues,
    validationSchema: IndividualSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      enableLoading();
      const toSubmit = {
        country: userCountryName,
        currency: values.currency,
        profession: values.profession,
      };
      updateUserCompany(user.companyId._id, toSubmit)
        .then(({ data }) => {
          disableLoading();
          getUserByToken();
          // window.open("/dashboard", "_self");
          window.open("/analytics/sales-summary", "_self");
          toast.success(data.message);
        })
        .catch(() => {
          setSubmitting(false);
          setStatus(i18n("Validation.InvalidLogin"));
          disableLoading();
        });
    },
  });

  const companyFormik = useFormik({
    initialValues: companyInitialValues,
    validationSchema: CompanySchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      enableLoading();
      const toSubmit = {
        country: companyCountryName,
        currency: values.currency,
        industry: values.industry,
        companyName: values.companyName,
      };
      updateUserCompany(user.companyId._id, toSubmit)
        .then(({ data }) => {
          let typeChange = true;
          updateUser(user._id, user, typeChange);
          disableLoading();
          getUserByToken();
          // window.open("/dashboard", "_self");
          window.open("/analytics/sales-summary", "_self");
          toast.success(data.message);
          // history.push("/dashboard");
        })
        .catch(() => {
          setSubmitting(false);
          setStatus(i18n("Validation.InvalidLogin"));
          disableLoading();
        });
    },
  });

  // Detecting user details using ip address
  useEffect(() => {
    if (companyInitialValues) {
      companyFormik.setFieldValue("country", companyInitialValues.country);
      companyFormik.setFieldValue("currency", companyInitialValues.currency);
    }
  }, [companyInitialValues]);

  useEffect(() => {
    try {
      fetch("https://api.ipregistry.co/?key=td089a1mybgzdxyb")
        .then((res) => res.json())
        .then((response) => {
          setCompanyInitialValues({
            ...companyInitialValues,
            country: response?.location?.country?.code,
            currency: response?.currency?.code,
          });
          setUserInitialValues({
            ...companyInitialValues,
            country: response?.location?.country?.name,
            currency: response?.currency?.code,
          });
          setUserCountryName(response?.location?.country?.name);
          setCompanyCountryName(response?.location?.country?.name);
          individualFormik.setFieldValue(
            "country",
            response?.location?.country?.name
          );
        });
    } catch (error) {
      console.log("Error: ", error);
    }
    // eslint-disable-next-line
  }, []);

  const currencyHandlerUser = (e) => {
    setUserInitialValues({
      ...companyInitialValues,
      country: e.target.value,
      currency: countryToCurrency[e.target.value],
    });
    individualFormik.setFieldValue("country", e.target.value);
    individualFormik.setFieldValue(
      "currency",
      countryToCurrency[e.target.value]
    );
    setUserCountryName(countries[e.target.value].name);
  };

  const currencyHandlerCompany = (e) => {
    companyFormik.setFieldValue("country", e.target.value);
    setCompanyInitialValues({
      ...companyInitialValues,
      country: e.target.value,
      currency: countryToCurrency[e.target.value],
    });
    setCompanyCountryName(countries[e.target.value].name);
    companyFormik.setFieldValue("country", e.target.value);
    companyFormik.setFieldValue("currency", countryToCurrency[e.target.value]);
  };

  useEffect(() => {
    if (user?.companyId?.profession || user?.companyId?.industry) {
      // history.push("/dashboard");
      history.push("/analytics/sales-summary");
    }
  }, [user, history]);
  useEffect(() => {
    individualFormik.resetForm();
    setAccountType(ACCOUNT_TYPES.COMPANY);
  }, []);

  return (
    <div className="login-form login-signin">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="illustration">
              <h1>{i18n("IndividualOrCompany.FreelancerOrCompany")}</h1>
              <p>
                {i18n(
                  "IndividualOrCompany.WetherYouAreACompanyOrAFreelancerFatoratyWillProvideYouWithInnovativeToolsToGrowYourBusiness"
                )}
              </p>
              <div className="img-wrap">
                <img src={logo} alt="loading" />
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="are-you">
              <h1>{i18n("IndividualOrCompany.AreYou?")}</h1>
              <div className="what-are-you"></div>
            </div>
            {accountType === ACCOUNT_TYPES.INDIVIDUAL && (
              <form
                id="kt_login_signin_form"
                className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp py-5"
                onSubmit={individualFormik.handleSubmit}
              >
                {/* begin: Alert */}
                {individualFormik.status && (
                  <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                    <div className="alert-text font-weight-bold">
                      {individualFormik.status}
                    </div>
                  </div>
                )}
                <div className="form-group fv-plugins-icon-container">
                  <label>Profession</label>
                  <div className="input-icon">
                    <select
                      className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                        "profession"
                      )}`}
                      name="profession"
                      {...individualFormik.getFieldProps("profession")}
                    >
                      <option value="">
                        {i18n("IndividualOrCompany.SelectProfession")}
                      </option>
                      {PROFESSIONS.map((item, index) => (
                        <option key={index} value={item}>
                          {item}
                        </option>
                      ))}
                    </select>
                    <span>
                      <i className="fa fa-briefcase icon-md"></i>
                    </span>
                  </div>
                  {individualFormik.touched.profession &&
                  individualFormik.errors.profession ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {individualFormik.errors.profession}
                      </div>
                    </div>
                  ) : null}
                </div>

                {/* ////////////////////////////////country////////////////////////// */}

                <div className="form-group fv-plugins-icon-container">
                  <label>Country</label>
                  <div className="input-icon">
                    <select
                      className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                        "country"
                      )}`}
                      name="country"
                      {...individualFormik.getFieldProps("country")}
                      onChange={(e) => currencyHandlerUser(e)}
                    >
                      {options?.map((item, index) => (
                        <option key={index} value={item?.value}>
                          {item?.label}
                        </option>
                      ))}
                    </select>
                    <span>
                      <i className="fa fa-dollar-sign icon-md"></i>
                    </span>
                  </div>
                  {individualFormik.touched.country &&
                  individualFormik.errors.country ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {individualFormik.errors.country}
                      </div>
                    </div>
                  ) : null}
                </div>
                {/* ////////////////////////////////currency////////////////////////// */}

                <div className="form-group fv-plugins-icon-container">
                  <label>Currency</label>
                  <div className="input-icon">
                    <select
                      disabled
                      style={{ appearance: "none" }}
                      className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                        "currency"
                      )}`}
                      name="currency"
                      {...individualFormik.getFieldProps("currency")}
                    >
                      {CURRENCIES.map((item, index) => (
                        <option key={index} value={item}>
                          {item}
                        </option>
                      ))}
                    </select>
                    <span>
                      <i className="fa fa-dollar-sign icon-md"></i>
                    </span>
                  </div>
                  {individualFormik.touched.currency &&
                  individualFormik.errors.currency ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {individualFormik.errors.currency}
                      </div>
                    </div>
                  ) : null}
                </div>

                {/* ////////////////////////////////end////////////////////////// */}

                <div className="f-full-width-center">
                  <button
                    type="submit"
                    disabled={individualFormik.isSubmitting}
                    className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4 f-button"
                    style={{ width: "auto" }}
                  >
                    <span>{i18n("IndividualOrCompany.Finish")}</span>
                    {loading && (
                      <span className="ml-3 spinner spinner-white"></span>
                    )}
                  </button>
                </div>
              </form>
            )}

            {accountType === ACCOUNT_TYPES.COMPANY && (
              <form
                id="kt_login_signin_form"
                className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp py-5"
                onSubmit={companyFormik.handleSubmit}
              >
                {/* begin: Alert */}
                {companyFormik.status && (
                  <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                    <div className="alert-text font-weight-bold">
                      {companyFormik.status}
                    </div>
                  </div>
                )}
                <div className="form-group fv-plugins-icon-container">
                  <label>{i18n("IndividualOrCompany.CompanyName")}</label>
                  <div className="input-icon">
                    <input
                      placeholder={i18n("IndividualOrCompany.CompanyName")}
                      type="text"
                      className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                        "companyName"
                      )}`}
                      name="companyName"
                      {...companyFormik.getFieldProps("companyName")}
                    />
                    <span>
                      <i className="fa fa-home icon-md"></i>
                    </span>
                  </div>
                  {companyFormik.touched.companyName &&
                  companyFormik.errors.companyName ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {companyFormik.errors.companyName}
                      </div>
                    </div>
                  ) : null}
                </div>
                <div className="form-group fv-plugins-icon-container">
                  <label>Industry</label>
                  <div className="input-icon">
                    <select
                      className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                        "industry"
                      )}`}
                      name="industry"
                      {...companyFormik.getFieldProps("industry")}
                    >
                      <option value="">
                        {i18n("IndividualOrCompany.SelectIndustry")}
                      </option>
                      {INDUSTRIES.map((item, index) => (
                        <option key={index} value={item}>
                          {item}
                        </option>
                      ))}
                    </select>
                    <span>
                      <i className="fa fa-industry icon-md"></i>
                    </span>
                  </div>
                  {companyFormik.touched.industry &&
                  companyFormik.errors.industry ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {companyFormik.errors.industry}
                      </div>
                    </div>
                  ) : null}
                </div>

                {/* ////////////////////////////////country////////////////////////// */}

                <div className="form-group fv-plugins-icon-container">
                  <label>Country</label>
                  <div className="input-icon">
                    <select
                      className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                        "country"
                      )}`}
                      name="country"
                      {...companyFormik.getFieldProps("country")}
                      onChange={(e) => currencyHandlerCompany(e)}
                    >
                      {options.map((item, index) => (
                        <option key={index} value={item?.value}>
                          {item?.label}
                        </option>
                      ))}
                    </select>
                    <span>
                      <i className="fa fa-dollar-sign icon-md"></i>
                    </span>
                  </div>
                  {companyFormik.touched.country &&
                  companyFormik.errors.country ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {companyFormik.errors.country}
                      </div>
                    </div>
                  ) : null}
                </div>
                {/* ////////////////////////////////currency////////////////////////// */}

                <div className="form-group fv-plugins-icon-container">
                  <label>Currency</label>
                  <div className="input-icon">
                    <select
                      disabled
                      style={{ appearance: "none" }}
                      className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                        "currency"
                      )}`}
                      name="currency"
                      {...companyFormik.getFieldProps("currency")}
                    >
                      {CURRENCIES.map((item, index) => (
                        <option key={index} value={item}>
                          {item}
                        </option>
                      ))}
                    </select>
                    <span>
                      <i className="fa fa-dollar-sign icon-md"></i>
                    </span>
                  </div>
                  {companyFormik.touched.currency &&
                  companyFormik.errors.currency ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {companyFormik.errors.currency}
                      </div>
                    </div>
                  ) : null}
                </div>

                {/* ////////////////////////////////end////////////////////////// */}

                <div className="f-full-width-center">
                  <button
                    type="submit"
                    disabled={companyFormik.isSubmitting}
                    className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4 f-button"
                  >
                    <span> {i18n("IndividualOrCompany.Finish")}</span>
                    {loading && <span className="ml-3 spinner spinner-white" />}
                  </button>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(null, auth.actions)(UserOrCompany);
