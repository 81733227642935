import React, { useEffect, useMemo, useState } from "react";
import { useFormik } from "formik";
import { connect, useDispatch } from "react-redux";
import * as Yup from "yup";
import { Link, useHistory } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import * as auth from "../_redux/authRedux";
import { login, register, updateUserCompany } from "../_redux/authCrud";
import toast from "react-hot-toast";
import axios from "axios";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { AsYouType } from "libphonenumber-js/max";
import { i18n } from "../../../pages/private/languageSelector";
import iconeEye from "../../../../assets/img/iconEye.svg";
import showPasswordIcon from "../../../../assets/img/iconShowPassword.svg";
import countryList from "react-select-country-list";
import { FATORATAY_AUTH } from "../../../../helpers/constants";
import { Storage } from "react-jhipster";
import AuthCard from "../../../components/Card/AuthCard";
import * as analyticsActions from "../../../../_redux/analytics/analyticsSlice";

let asYouType = new AsYouType();
const initialValues = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  password: "",
  acceptTerms: false,
  companyName: "",
  country: "",
};

Yup.addMethod(Yup.mixed, "validatePhoneNumber", function(msg) {
  return this.test({
    name: "validatePhoneNumber",
    message: msg,
    test: (value) => {
      asYouType.reset();
      asYouType.input(value);
      return asYouType.getNumber().isValid();
    },
  });
});

function Registration(props) {
  const { areYou } = props;
  const [loading, setLoading] = useState(false);
  const [stepOneData, setStepOneData] = useState(initialValues);
  const [currency, setCurrency] = useState("");
  const [platFormInfo, setPlatFormInfo] = useState({
    platform: "",
    ipAddress: "",
    timeZone: "",
  });
  const history = useHistory();
  const dispatch = useDispatch();
  const options = useMemo(() => countryList().getData(), []);

  const passwordValidation = i18n("Validation.INVALID_PASSWORD");
  const RegistrationSchema = Yup.object().shape({
    // firstName: Yup.string()
    //   .min(3, i18n("Validation.Minimum3Symbols"))
    //   .max(50, i18n("Validation.Maximum50Symbols"))
    //   .matches(/^[a-zA-Z ]*$/, "Alphabets Required")
    //   .required(i18n("Validation.RequiredField")),
    // lastName: Yup.string()
    //   .min(3, i18n("Validation.Minimum3Symbols"))
    //   .max(50, i18n("Validation.Maximum50Symbols"))
    //   .matches(/^[a-zA-Z ]*$/, "Alphabets Required")
    //   .required(i18n("Validation.RequiredField")),
    email: Yup.string()
      .email(i18n("Validation.WrongEmailFormat"))
      .min(3, i18n("Validation.Minimum3Symbols"))
      .max(50, i18n("Validation.Maximum50Symbols"))
      .matches(
        // eslint-disable-next-line
        /^\w+[\w-\.]*\@\w+((-\w+)|(\w*))\.[a-z]{2,3}$/,
        "Must contain e.g (example._-@example.com)"
      )
      .required(i18n("Validation.RequiredField")),
    // phone: Yup.string()

    //   .validatePhoneNumber(i18n("Validation.INVALID_PHONE"))
    //   .required(i18n("Validation.RequiredField")),
    password: Yup.string()
      // .min(8, i18n("Validation.Minimum8Symbols"))
      .min(8, passwordValidation)
      .max(50, i18n("Validation.Maximum50Symbols"))
      .matches(/^(?=.*[0-9])(?=.*[a-z]).{8,50}$/, passwordValidation)
      .required(i18n("Validation.RequiredField")),
    acceptTerms: Yup.bool().required("You must accept the terms and conditions"),
    companyName: Yup.string().required("Company Name is required"),
    country: Yup.string().required("Country is required"),
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid exclamationSign";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };
  const getPlatformInfo = async () => {
    const response = await axios.get("https://api.ipify.org?format=json");
    const countryInfo = await axios.get(`https://ipapi.co/${response?.data?.ip}/json/`);
    const browserInfo = await axios.get("https://api.ipregistry.co/?key=td089a1mybgzdxyb");
    setCurrency(browserInfo?.data?.currency?.code);
    setPlatFormInfo({
      platform: browserInfo?.data?.user_agent?.type === "browser" ? "Web" : "App",
      ipAddress: countryInfo?.data?.ip,
      timeZone: countryInfo?.data?.timezone,
    });
    formik.setFieldValue("country", countryInfo?.data?.country_name);
  };

  useEffect(() => {
    getPlatformInfo();
  }, []);

  const formik = useFormik({
    initialValues: stepOneData,
    validationSchema: RegistrationSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      enableLoading();
      axios
        .get(`/api/users/search?email=${values.email}`)
        .then((response) => {
          setStatus(i18n("Validation.EmailAlreadyRegistered"));
          setSubmitting(false);
          disableLoading();
        })
        .catch((error) => {
          setStatus("");
          setStepOneData({ ...values });
          areYou();
          setSubmitting(false);
          disableLoading();
          const toSubmit = {
            email: values.email,
            password: values.password,
            mobile: "",
            name: {
              first: "Owner",
              last: "user",
            },
            country: values?.country,
            companyName: values?.companyName,
            language: localStorage.getItem("language") || "english",
            platformInfo: platFormInfo,
          };
          register(toSubmit)
            .then(({ data }) => {
              login(values.email, values.password, platFormInfo)
                .then(({ data: { token } }) => {
                  disableLoading();
                  if (values.rememberMe) {
                    Storage.local.set(FATORATAY_AUTH, token);
                  } else {
                    Storage.session.set(FATORATAY_AUTH, token);
                  }
                  props.login(token, values.rememberMe);
                })
                .then(() => {
                  let dataToSubmit = { country: data?.user.country, currency: currency, profession: "", companyName: values?.companyName };
                  updateUserCompany(data?.user.companyId, dataToSubmit);
                })
                .then(() => {
                  dispatch(analyticsActions.setShowEmailConfirmationDialog(true));
                })
                .catch((error) => {
                  disableLoading();
                  setSubmitting(false);
                  setStatus(error?.response?.data?.message);
                });

              disableLoading();
              toast.success(data.message);
              history.push("/analytics/sales-summary");
              // history.push({
              //   pathname: "/register-next",
              //   state: values?.email,
              // });
            })
            .catch((error) => {
              setSubmitting(false);
              setStatus(error?.message === "Request failed with status code 502" ? "Network Error" : error?.message);
              disableLoading();
            });
        });
    },
  });

  const [passwordShow, setPasswordShow] = useState(false);
  const passwordShowHandler = () => {
    setPasswordShow(passwordShow ? false : true);
  };

  const selectedLanguage = localStorage.getItem("language");
  return (
    <div>
      <AuthCard
        error={formik.status}
        selectedLanguage={selectedLanguage}
        link={"/auth/login"}
        id={"kt_login_signup"}
        text={i18n("SignUp.AlreadyHaveAnAccount?")}
        linkText={i18n("SignUp.Login")}
      >
        <div>
          <>
            <div className={`form-title `} style={{ textAlign: selectedLanguage === "arabic" ? "right" : "left" }}>
              <h1>{i18n("SignUp.SignUpToFatoraty")}</h1>
              <p>{i18n("SignUp.EnterYourDetailsToCreateYourAccount")}</p>
            </div>

            <form
              id="kt_login_signin_form"
              className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
              onSubmit={formik.handleSubmit}
            >
              <Row>
                {/* <Col xs={12}>
                  <div className="form-group with-icon">
                    <field>
                      <input
                        placeholder={i18n("global.FirstName")}
                        type="text"
                        className={`form-control thick-border ${formik.touched.firstName && formik.errors.firstName ? "red-border" : ""}`}
                        name="firstName"
                        {...formik.getFieldProps("firstName")}
                      />
                      <label>{i18n("global.FirstName")}</label>
                      {formik.touched.firstName && formik.errors.firstName ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">{formik.errors.firstName}</div>
                        </div>
                      ) : null}
                    </field>
                  </div>
                </Col>
                <Col xs={12}>
                  <div className="form-group">
                    <input
                      placeholder={i18n("global.LastName")}
                      type="text"
                      className={`form-control thick-border ${formik.touched.lastName && formik.errors.lastName ? "red-border" : ""}`}
                      name="lastName"
                      {...formik.getFieldProps("lastName")}
                    />
                    <label>{i18n("global.LastName")}</label>
                    {formik.touched.lastName && formik.errors.lastName ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">{formik.errors.lastName}</div>
                      </div>
                    ) : null}
                  </div>
                </Col> */}
              </Row>
              <div className="form-group with-icon">
                <field>
                  <input
                    placeholder={i18n("global.Email")}
                    className={`form-control thick-border ${formik.touched.email && formik.errors.email ? "red-border" : ""}`}
                    name="email"
                    {...formik.getFieldProps("email")}
                  />
                  <label>{i18n("global.Email")}</label>
                  {formik.touched.email && formik.errors.email ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{formik.errors.email}</div>
                    </div>
                  ) : null}
                </field>
              </div>
              {/* <div className="form-group with-icon">
                <field>
                  <PhoneInput
                    international
                    countryCallingCodeEditable={false}
                    defaultCountry="SA"
                    style={{ display: "flex" }}
                    className={`form-control thick-border  ${formik.touched.phone && formik.errors.phone ? "red-border" : ""} ${
                      formik.touched.phone && formik.errors.phone ? "is-invalid exclamationSign" : ""
                    } `}
                    name="phone"
                    {...formik.getFieldProps("phone")}
                    value={formik.values.phone}
                    onChange={(value) => {
                      formik.setValues({ ...formik.values, phone: value });
                    }}
                  />
                  <label>{i18n("global.Mobile")}</label>
                  {formik.touched.phone && formik.errors.phone ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{formik.errors.phone}</div>
                    </div>
                  ) : null}
                </field>
              </div> */}
              <div className="form-group with-icon">
                <field>
                  <input
                    placeholder={i18n("global.Password")}
                    type={passwordShow ? "text" : "password"}
                    className={`form-control thick-border ${
                      formik.touched.password && formik.errors.password ? "red-border" : ""
                    } p-0 ${getInputClasses("password")}`}
                    name="password"
                    {...formik.getFieldProps("password")}
                  />
                  <label>{i18n("global.Password")}</label>
                  <span
                    onClick={passwordShowHandler}
                    style={{
                      float: selectedLanguage === "arabic" ? "left" : "right",
                      marginTop: "-30px",
                      position: "relative",
                      zIndex: "1",
                      cursor: "pointer",
                      width: "auto",
                    }}
                  >
                    <img src={passwordShow ? showPasswordIcon : iconeEye} alt="eyeIcon" />
                  </span>

                  {formik.touched.password && formik.errors.password ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{formik.errors.password}</div>
                    </div>
                  ) : null}
                </field>
              </div>
              <div className="form-group with-icon">
                <field>
                  <input
                    placeholder={i18n("global.BusinessName")}
                    className={`form-control thick-border ${formik.touched.companyName && formik.errors.companyName ? "red-border" : ""} `}
                    name="companyName"
                    {...formik.getFieldProps("companyName")}
                  />
                  <label>{i18n("global.BusinessName")}</label>
                  {formik.touched.companyName && formik.errors.companyName ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{formik.errors.companyName}</div>
                    </div>
                  ) : null}
                </field>
              </div>
              <div className="form-group with-icon">
                <div style={{ position: "absolute", bottom: "20px", color: "#828282", fontSize: "75%", fontWeight: 400 }}>
                  {i18n("global.Country")}
                </div>
                <div className="w-100">
                  <select
                    className={`form-control thick-border h-auto p-0 py-1 ${
                      formik.touched.country && formik.errors.country ? "red-border" : ""
                    } ${getInputClasses("country")}`}
                    name="country"
                    {...formik.getFieldProps("country")}
                    value={formik.values.country}
                    onChange={(e) => {
                      formik.setValues({ ...formik.values, country: e?.target?.value });
                    }}
                  >
                    {options?.map((item, index) => {
                      return (
                        <option key={index} value={item?.label}>
                          {item?.label}
                        </option>
                      );
                    })}
                  </select>
                </div>
                {formik.touched.country && formik.errors.country ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.country}</div>
                  </div>
                ) : null}
              </div>

              <div className=" pl-0">
                <div className="">
                  <div className="d-flex flex-wrap align-items-center mb-3">
                    <label className="checkbox ">
                      <input
                        type="checkbox"
                        name="acceptTerms"
                        className={`${selectedLanguage === "arabic" ? "ml-2" : "mr-2"}`}
                        {...formik.getFieldProps("acceptTerms")}
                        {...(formik.values.acceptTerms ? { checked: true } : {})}
                      />
                      <span className={` ${selectedLanguage === "arabic" ? "ml-2" : "mr-2"}  `}></span>
                      {i18n("SignUp.IAgreeThe")}{" "}
                    </label>
                    <Link className={` ${selectedLanguage === "arabic" ? "mr-1" : "ml-1"} `} to="/terms" target="_blank" rel="noopener noreferrer">
                      {i18n("SignUp.Terms&Conditions")}
                    </Link>
                  </div>
                </div>
                {formik.touched.acceptTerms && formik.errors.acceptTerms ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.acceptTerms}</div>
                  </div>
                ) : null}
                <button
                  type="submit"
                  disabled={
                    !formik.values.acceptTerms ||
                    formik.values.companyName?.length === 0 ||
                    formik.values.country?.length === 0 ||
                    formik.values?.email?.length === 0 ||
                    // formik.values.firstName?.length === 0 ||
                    // formik.values.lastName?.length === 0 ||
                    formik.values.password?.length === 0 ||
                    // formik.values.phone?.length === 0 ||
                    // formik.errors.phone?.length > 0 ||
                    formik.errors.password?.length > 0 ||
                    // formik.errors.lastName?.length > 0 ||
                    // formik.errors.firstName?.length > 0 ||
                    formik.errors.email?.length > 0 ||
                    formik.errors.country?.length > 0 ||
                    formik.errors.companyName?.length > 0
                  }
                  className={`btn font-weight-bold px-9 py-4 my-3 w-100 auth-btn`}
                >
                  <span> {i18n("Validation.SIGNUP")}</span>
                  {loading && <span className="ml-3 spinner spinner-white"></span>}
                </button>
              </div>
            </form>
          </>
        </div>
      </AuthCard>
    </div>
  );
}

export default connect(null, auth.actions)(Registration);
