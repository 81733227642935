import React, { useState } from "react";
import { useFormik } from "formik";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import * as Yup from "yup";
import * as auth from "../_redux/authRedux";
import { requestPassword } from "../_redux/authCrud";
import { useHistory } from "react-router-dom";
import { i18n } from "../../../pages/private/languageSelector";
import AuthCard from "../../../components/Card/AuthCard";

const initialValues = {
  email: "",
};

function ForgotPassword() {
  const history = useHistory();
  const [isRequested, setIsRequested] = useState(false);
  const ForgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .email(i18n("Validation.WrongEmailFormat"))
      .min(3, i18n("Validation.Minimum3Symbols"))
      .max(50, i18n("Validation.Maximum50Symbols"))
      .required(i18n("Validation.RequiredField")),
  });

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: ForgotPasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      requestPassword(values.email)
        .then((res) => {
          setIsRequested(true);
          history.push("/forgot-password-next");
        })
        .catch(() => {
          setIsRequested(false);
          setSubmitting(false);
          setStatus(i18n("Validation.EmailNotFound"), {
            // name: values.email,
          });
        });
    },
  });
  const selectedLanguage = localStorage.getItem("language");

  return (
    <>
      {isRequested && <Redirect to="/auth" />}
      {!isRequested && (
        <div style={{ maxWidth: "350px", widht: "auto" }}>
          <AuthCard
            id={""}
            error={formik.status?.includes("Invalid Email or Password") ? i18n("Validation.InvalidEmailOrPassword") : formik.status}
            text={i18n("Validation.BackTo")}
            link={"/auth/login"}
            linkText={i18n("SignUp.Login")}
            selectedLanguage={selectedLanguage}
          >
            <div className="form-title">
              <p className="m-0 mb-5" style={{ fontSize: "18px", color: "#333333", textAlign: selectedLanguage === "arabic" ? "right" : "left" }}>
                {i18n("ForgotPassword.ResetPassword")}
              </p>
              <p className="m-0">{i18n("ForgotPassword.PleaseEnterYourEmailToReceiveAPasswordResetMessage")}</p>
            </div>
            <form
              onSubmit={formik.handleSubmit}
              className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
              style={{ marginTop: "-1.2rem", marginBottom: "-2rem" }}
            >
              <div className="form-group mb-3 with-icon" style={{ marginBottom: "1rem", marginTop: "3rem" }}>
                <field>
                  <input
                    type="email"
                    placeholder={i18n("global.Email")}
                    className={`form-control  thick-border ${formik.touched.email && formik.errors.email ? "red-border" : ""}`}
                    name="email"
                    {...formik.getFieldProps("email")}
                  />
                  <label> {i18n("global.Email")}</label>
                  {formik.touched.email && formik.errors.email ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{formik.errors.email}</div>
                    </div>
                  ) : null}
                </field>
              </div>

              <div className="d-flex flex-wrap flex-center" style={{ marginBottom: "3rem" }}>
                <button
                  id="kt_login_forgot_submit"
                  type="submit"
                  className={`btn font-weight-bold px-9 py-4 my-3 w-100 auth-btn`}
                  disabled={formik.isSubmitting || formik?.values?.email?.length === 0}
                >
                  {i18n("ForgotPassword.SEND")}
                </button>
              </div>
              {/* <div className="d-block w-100 mt-3 text-center">
                <span className="font-weight-bold text-dark-50">
                  {i18n("ForgotPassword.DontHaveAnAccountYet")}{" "}
                  <Link to="/auth/registration" style={{ color: "#20639b" }}>
                    {i18n("ForgotPassword.RegisterNow")}
                  </Link>
                </span>
              </div> */}
            </form>
          </AuthCard>
        </div>
      )}
    </>
  );
}

export default connect(null, auth.actions)(ForgotPassword);
