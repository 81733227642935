import { createSlice } from "@reduxjs/toolkit";
const productInitFilterState = {
  category: "",
  status: "",
  store: "",
};
const initialProductsState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  skuCount: 0,
  entities: null,
  productForEdit: undefined,
  newProduct: undefined,
  lastError: null,
  navigate: false,
  filterOn: false,
  productFilterState: productInitFilterState,
  showProductsLayout: true,
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const productsSlice = createSlice({
  name: "products",
  initialState: initialProductsState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      state.navigate = false;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getProductById
    productFetched: (state, action) => {
      state.actionsLoading = false;
      state.productForEdit = action.payload.productForEdit;
      state.error = null;
    },
    // findProducts
    productsFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    productFilterOn: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.filterOn = action.payload.filterOn;
    },
    // createProduct
    productCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.navigate = true;
      state.newProduct = action.payload.newProduct;
    },
    // updateProduct
    productUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.navigate = true;
    },
    //getLatestSkuNumber
    productSkuFetch: (state, action) => {
      state.listLoading = false;
      state.error = null;
      state.skuCount = action.payload.skuCount;
    },
    // deleteProduct
    productDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
    },
    productFiltersSet: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.productFilterState = action.payload.productFilterState;
    },
    productFiltersClear: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.productFilterState = productInitFilterState;
      // state.entities = state.entities.filter(
      //   (el) => el.id !== action.payload.id
      // );
    },
    // deleteProducts
    productsDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter((el) => !action.payload.ids.includes(el.id));
    },
    // productsUpdateState
    productsStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { ids, status } = action.payload;
      state.entities = state.entities.map((entity) => {
        if (ids.findIndex((id) => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    },
    showProductsLayout: (state, action) => {
      state.showProductsLayout = action.payload;
    },
  },
});

export const { showProductsLayout } = productsSlice.actions;
