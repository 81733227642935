import React, { useEffect, useState } from "react";

import { getCompany } from "../../../_redux/company/companyCrud";
import { shallowEqual, useSelector } from "react-redux";
import CompanyView from "../company/company-edit-Dialog/companyView";
import { i18n } from "../private/languageSelector";
import { getUserByToken, updateUserProfile } from "../../modules/Auth/_redux/authCrud";
import toast from "react-hot-toast";
import ActionDialog from "./components/ActionDialog";
const UserSettings = () => {
  const { language } = useSelector((state) => state.auth);

  const { stores } = useSelector(
    (state) => ({
      stores: state.stores?.entities,
    }),
    shallowEqual
  );
  let languageOptions = [
    { label: "English", value: "english" },
    { label: "عربي", value: "arabic" },
  ];
  const [showPasswordDialog, setShowPasswordDialog] = useState(false);
  const [showPinCodeDialog, setShowPinCodeDialog] = useState(false);
  const [companyInformationData, setCompanyInformationData] = useState({});
  const [openEditCompanyInformationDialog, setOpenEditCompanyInformationDialog] = useState(false);
  const [openEditInformationDialog, setOpenEditInformationDialog] = useState(false);
  const [openEditInformationDialogValue, setOpenEditInformationDialogValue] = useState("");
  const [user, setUser] = useState();
  const [posPin, setPosPin] = useState("");

  const fetchCompanyInformation = async () => {
    const response = await getCompany();
    setCompanyInformationData(response?.data?.company);
  };

  useEffect(() => {
    fetchCompanyInformation();
    getUserByToken().then((res) => {
      setUser(res?.data);
    });
  }, []);

  useEffect(() => {
    setPosPin(user?.posPin);
  }, [user]);

  useEffect(() => {
    if (!openEditInformationDialog && !openEditCompanyInformationDialog) {
      fetchCompanyInformation();
    }
  }, [openEditInformationDialog, openEditCompanyInformationDialog]);

  const handleLanguage = async (language) => {
    try {
      localStorage.setItem("language", language);
      await updateUserProfile(user?._id, {
        ...user,
        language: language,
      });
      toast.success("User Language Updated");
      window.location.reload();
    } catch (error) {
      toast.error("Failed to update user language");
      console.error("Error updating user profile:", error);
    }
  };

  const onHide = () => {
    if (showPasswordDialog && !showPinCodeDialog) {
      setShowPasswordDialog(false);
    } else {
      setShowPinCodeDialog(false);
    }
  };
  const secondaryAction = () => {
    if (showPasswordDialog && !showPinCodeDialog) {
      setShowPasswordDialog(false);
    } else {
      setShowPinCodeDialog(false);
    }
  };
  return (
    <>
      <CompanyView />
      <div
        className="row d-flex justify-content-between"
        style={{
          direction: language === "arabic" ? "rtl" : "ltr",
        }}
      >
        {/* information card */}
        <div className="col-lg-6 col-md-12  d-flex flex-column">
          <div
            className={`card ${language === "arabic" ? "rtl" : "ltr"}`}
            style={{
              backgroundColor: "#FFFFFF",
              borderRadius: "10px",
              boxShadow: "0px 4px 4px 0px #00000040",
            }}
          >
            <h5
              className="card-title"
              style={{
                fontSize: "18px",
                fontWeight: "600",
                color: "#4F4F4F",
                borderBottom: "1px solid #F2F2F2",
                paddingBottom: "1rem",
                marginTop: "1rem",
              }}
            >
              {i18n("Company.Information")}
            </h5>
            <div className="d-flex flex-column row pb-5">
              <span className="mx-3  whitespace-nowrap small-text-user">{i18n("global.Name")}:</span>
              <span className="mx-3  text-start large-text-user">
                {user?.name?.first} {user?.name?.last}
              </span>
            </div>
            <div className="d-flex flex-column row pb-5">
              <span className="mx-3  whitespace-nowrap small-text-user">{i18n("global.Email")}:</span>
              <span className="mx-3  text-start large-text-user">{user?.email}</span>
            </div>
            <div className="d-flex  flex-column row pb-5">
              <span className="mx-3 small-text-user whitespace-nowrap">{i18n("global.Phone")}:</span>
              <span className="mx-3 large-text-user text-start">{user?.mobile}</span>
            </div>
            <div className="d-flex flex-column row pb-5">
              <span className="mx-3 small-text-user whitespace-nowrap">{i18n("global.Role")}:</span>
              <span className="mx-3 large-text-user text-start">{user?.rolePayload?.originalName}</span>
            </div>
          </div>
          {/* Stores card */}
          <div
            className={`card ${language === "arabic" ? "rtl" : "ltr"}`}
            style={{
              backgroundColor: "#FFFFFF",
              borderRadius: "10px",
              boxShadow: "0px 4px 4px 0px #00000040",
            }}
          >
            <h5
              className="card-title"
              style={{
                fontSize: "18px",
                fontWeight: "600",
                color: "#4F4F4F",
                borderBottom: "1px solid #F2F2F2",
                paddingBottom: "1rem",
                marginTop: "1rem",
              }}
            >
              {i18n("Company.Stores")}
            </h5>
            <div className="d-flex flex-column row pb-5" style={{ gap: "1rem" }}>
              {stores?.map((store) => (
                <span className="mx-3  text-start large-text-user">{store?.name}</span>
              ))}
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-md-12 d-flex flex-column">
          {/* language card */}

          <div
            className={`card ${language === "arabic" ? "rtl" : "ltr"}`}
            style={{
              backgroundColor: "#FFFFFF",
              borderRadius: "10px",
              boxShadow: "0px 4px 4px 0px #00000040",
            }}
          >
            <h5
              className="card-title"
              style={{
                fontSize: "18px",
                fontWeight: "600",
                color: "#4F4F4F",
                borderBottom: "1px solid #F2F2F2",
                paddingBottom: "1rem",
                marginTop: "1rem",
              }}
            >
              {i18n("global.Language")}
            </h5>
            <div className="d-flex flex-column row" style={{ paddingLeft: "1.1rem", paddingRight: "1.25rem" }}>
              <div className=" w-100" style={{ paddingBottom: "1.5rem" }}>
                <div className={`${"small-text-user"}`}>{i18n("global.Language")}</div>
                <select
                  className="w-100"
                  onChange={(e) => {
                    handleLanguage(e?.target?.value);
                  }}
                  value={user?.language}
                  style={{ border: "none", borderBottom: "1px solid #E0E0E0", outline: "none" }}
                >
                  {languageOptions?.map((elem, index) => (
                    <option key={index} value={elem?.value}>
                      {elem?.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          {/* pin card */}

          <div
            className={`card ${language === "arabic" ? "rtl" : "ltr"}`}
            style={{
              backgroundColor: "#FFFFFF",
              borderRadius: "10px",
              boxShadow: "0px 4px 4px 0px #00000040",
            }}
          >
            <div style={{ borderBottom: "1px solid #F2F2F2", paddingBottom: "1rem", marginTop: "1rem" }}>
              <div className="d-flex justify-content-between">
                <span
                  className=""
                  style={{
                    fontSize: "18px",
                    fontWeight: "600",
                    color: "#4F4F4F",
                  }}
                >
                  PIN
                </span>
                <span
                  className=""
                  style={{
                    fontSize: "14px",
                    fontWeight: "700",
                    color: "#2D9CDB",
                    cursor: "pointer",
                  }}
                  onClick={() => setShowPinCodeDialog(true)}
                >
                  {i18n("MENU.EDIT")}
                </span>
              </div>
            </div>

            <div className="d-flex justify-content-between  row py-5">
              <span className="mx-3  text-start large-text-user">PIN</span>
              <span className="mx-3  text-start large-text-user">****</span>
            </div>
          </div>
          {/* password card */}
          <div
            className={`card ${language === "arabic" ? "rtl" : "ltr"}`}
            style={{
              backgroundColor: "#FFFFFF",
              borderRadius: "10px",
              boxShadow: "0px 4px 4px 0px #00000040",
            }}
          >
            <div style={{ borderBottom: "1px solid #F2F2F2", paddingBottom: "1rem", marginTop: "1rem" }}>
              <div className="d-flex justify-content-between">
                <span
                  className=""
                  style={{
                    fontSize: "18px",
                    fontWeight: "600",
                    color: "#4F4F4F",
                  }}
                >
                  {i18n("global.Password")}
                </span>
                <span
                  className=""
                  style={{
                    fontSize: "14px",
                    fontWeight: "700",
                    color: "#2D9CDB",
                    cursor: "pointer",
                  }}
                  onClick={() => setShowPasswordDialog(true)}
                >
                  {i18n("MENU.EDIT")}
                </span>
              </div>
            </div>

            <div className="d-flex justify-content-between  row py-5">
              <span className="mx-3  text-start large-text-user">{i18n("UserProfile.MyPassword")}</span>
              <span className="mx-3  text-start large-text-user">*******</span>
            </div>
          </div>
        </div>
      </div>

      {/* Edit User Information */}

      <ActionDialog
        user={user}
        primaryAction={onHide}
        showPasswordDialog={showPasswordDialog}
        showPinCodeDialog={showPinCodeDialog}
        onHide={onHide}
        secondaryAction={secondaryAction}
        posPin={posPin}
        setPosPin={setPosPin}
        setUser={setUser}
      />
    </>
  );
};

export default UserSettings;
