import React, { useEffect, useState, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
// import * as actions from "../../../../_redux/products/productsActions";
import * as actions from "../../../_redux/products/productsActions";
// import { ProductEditDialogHeader } from "./ProductEditDialogHeader";
import { ProductEditForm } from "./product-edit-dialog/ProductEditForm";
import { useProductsUIContext } from "./ProductsUIContext";
import toast from "react-hot-toast";
import SubscriptionDialog from "../company/company-edit-Dialog/SubscriptionDialog";
import { i18n } from "../private/languageSelector";
import { productsSlice } from "../../../_redux/products/productsSlice";
const initProduct = {
  id: undefined,
  englishName: "",
  arabicName: "",
  price: "",
  categoryInProduct: "",
  serviceIsActive: true,
  serviceIsTaxable: false,
  englishDetail: "",
  arabicDetail: "",
  sku: 0,
  detail: "",
  barcode: "",
  soldBy: "each",
  pos: "colorAndShape",
};

export function ProductEditPage({
  id,
  view,
  show,
  onHide,
  forInvoice,
  productName,
  setOpenAddProductPage,
  isProductDuplicate,
  setIsProductDuplicate,
}) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [showProductDeleteModal, setShowProductDeleteModal] = useState(false);
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [confirmDialogForClose, setConfirmDialogForClose] = useState(false);

  // Products UI Context
  const productsUIContext = useProductsUIContext();
  const productsUIProps = useMemo(() => {
    return {
      queryParams: productsUIContext?.queryParams,
    };
  }, [productsUIContext]);

  // Products Redux state
  const { actionsLoading, productForEdit, navigate, productFilterState, skuCount, products } = useSelector(
    (state) => ({
      navigate: state.products.navigate,
      actionsLoading: state.products.actionsLoading,
      productForEdit: state.products.productForEdit,
      productFilterState: state?.products?.productFilterState,
      skuCount: state?.products?.skuCount,
      products: state?.products?.entities,
    }),
    shallowEqual
  );
  useEffect(() => {
    if (navigate) {
      // onHide();
      setOpenAddProductPage(false);
    }
    // eslint-disable-next-line
  }, [navigate]);

  useEffect(() => {
    dispatch(actions.fetchProduct(id));
  }, [id, dispatch]);
  const saveProduct = (product) => {
    let skuProduct = products.filter((distintProduct) => distintProduct?.sku === Number(product?.sku));

    if (!id || id === undefined || id === "undefined") {
      if (skuProduct && skuProduct.length) {
        toast.error("SKU already exist");
      } else {
        dispatch(actions.createProduct(productFilterState, product, forInvoice ? {} : productsUIProps.queryParams)).then(() => {
          setLoading(false);
          dispatch(productsSlice.actions.showProductsLayout(true));
        });
      }
    } else {
      if ((skuProduct && skuProduct.length && skuProduct[0]._id !== product?._id) || product?._id === undefined || product?._id === "undefined") {
        toast.error("SKU already exist");
      } else {
        setLoading(true);
        dispatch(actions.updateProduct(productFilterState, product, productsUIProps.queryParams)).then(() => {
          setLoading(false);
          dispatch(productsSlice.actions.showProductsLayout(true));
        });
      }
    }
  };

  const confirmDelete = () => {
    if (id) {
      dispatch(actions.deleteProduct(id)).then(() => {
        dispatch(actions.fetchProducts(productFilterState, productsUIProps.queryParams));
      });
      setShowProductDeleteModal(false);
      setOpenAddProductPage(false);
      dispatch(productsSlice.actions.showProductsLayout(true));
    }
  };
  const handleClose = () => {
    if (isFormDirty) {
      setConfirmDialogForClose(true);
    } else {
      setOpenAddProductPage(false);
      dispatch(productsSlice.actions.showProductsLayout(true));
    }
  };
  const { language } = useSelector((state) => state.auth);
  return (
    //  <Modal className="modal-drawer invoice-drawer" size="lg" show={show} onHide={handleClose} aria-labelledby="example-modal-sizes-title-lg">
    <>
      <div className={`store-sub-header ${language === "arabic" ? "arabic-lang " : ""}`} style={{ marginLeft: "-7rem", marginTop: "-7rem" }}>
        <div className={`date-header store-invoice-header w-100  justify-content-start`}></div>
      </div>

      <div className="table-width" style={{ borderRadius: "0.42rem" }}>
        <ProductEditForm
          view={false}
          onHide={() => setOpenAddProductPage(false)}
          loading={loading}
          saveProduct={saveProduct}
          setIsFormDirty={setIsFormDirty}
          actionsLoading={actionsLoading}
          confirmDialogForClose={confirmDialogForClose}
          setConfirmDialogForClose={setConfirmDialogForClose}
          setShowProductDeleteModal={setShowProductDeleteModal}
          product={
            productForEdit || {
              ...initProduct,
              sku: skuCount,
              englishName: productName ? productName : "",
            }
          }
          pageView={true}
          setOpenAddProductPage={setOpenAddProductPage}
          isProductDuplicate={isProductDuplicate}
          setIsProductDuplicate={setIsProductDuplicate}
        />
        <SubscriptionDialog
          show={showProductDeleteModal}
          onHide={() => setShowProductDeleteModal(false)}
          modalWidth="350px"
          modalHeight="185px"
          message={i18n("MENU.DeleteSelectedProduct")}
          messageWidth="320px"
          messageHeight="44px"
          messageColor="#333333"
          messageFontWeight="400"
          messageFontSize="16px"
          messageLineHeight="21.79px"
          primaryAction={() => setShowProductDeleteModal(false)}
          primaryLabel={i18n("SubscriptionModals.CANCEL")}
          primaryLabelWidth="55px"
          primaryLabelHeight="20px"
          primaryLabelFontWeight="700"
          primaryLabelFontSize="14px"
          primaryLabelLineHeight="20px"
          primaryLabelColor="#828282"
          secondaryAction={confirmDelete}
          secondaryLabel={i18n("Discount.DELETE")}
          secondaryLabelWidth="67px"
          secondaryLabelHeight="20px"
          secondaryLabelFontWeight="700"
          secondaryLabelFontSize="14px"
          secondaryLabelLineHeight="20px"
          secondaryLabelColor="#EB5757"
        />{" "}
      </div>
    </>
    //  </Modal>
  );
}
