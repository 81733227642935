import React from "react";
import { useSelector } from "react-redux";

const inputLabel = ({ error, label, touched, uniqueEmailError, uniqueMobileError, customFeedbackLabel, language, isArabicStyle }) => {
  if (uniqueEmailError) {
    return <div className="invalid-feedback">{uniqueEmailError}</div>;
  }
  if (uniqueMobileError) {
    return <div className="invalid-feedback">{uniqueMobileError}</div>;
  }
  if (touched && error) {
    return <div className={`invalid-feedback ${language === "arabic" && isArabicStyle && "arabic-lang"}`}>{error}</div>;
  }

  if (touched && !error && label) {
    return <div className="valid-feedback">{label} was entered correct</div>;
  }

  return (
    <div className="feedback">
      {customFeedbackLabel && <>{customFeedbackLabel}</>}
      {!customFeedbackLabel && (
        <>
          Please enter <b>{label}</b>
        </>
      )}
    </div>
  );
};

const selectLabel = ({ label, touched, error, customFeedbackLabel }) => {
  if (touched && error) {
    return <div className="invalid-feedback">{error}</div>;
  }

  return (
    <div className="feedback">
      {customFeedbackLabel && <>{customFeedbackLabel}</>}
      {!customFeedbackLabel && label && (
        <>
          Please select <b>{label}</b>
        </>
      )}
    </div>
  );
};

export function FieldFeedbackLabel({ type, error, label, touched, uniqueEmailError, uniqueMobileError, customFeedbackLabel, isArabicStyle }) {
  const { language } = useSelector((state) => state.auth);
  switch (type) {
    case "text":
      return inputLabel({ label, touched, error, customFeedbackLabel });
    case "email":
      return inputLabel({
        error,
        label,
        touched,
        uniqueEmailError,
        customFeedbackLabel,
        language,
        isArabicStyle,
      });
    case "password":
      return inputLabel({ label, touched, error, customFeedbackLabel });
    case "mobile":
      return inputLabel({
        error,
        label,
        touched,
        uniqueMobileError,
        customFeedbackLabel,
      });
    default:
      return selectLabel({ label, touched, error, customFeedbackLabel });
  }
}
