import React, { useState, useEffect } from "react";
import moment from "moment";
import IconQr from "../../../../assets/img/icon-qr.svg";
import { refactorValues } from "../../../../helpers/helpers";
export const PosInvoicePreview = ({ invoice, invoiceData, companyData }) => {
  const [products, setProducts] = useState([]);
  const [filterTaxess, setFilterTaxess] = useState([]);
  let totalValueWithDiscount = Number(invoice?.invoiceData?.productsTotal) - Number(invoice?.invoiceData?.discountTotal);
  useEffect(() => {
    if (invoice !== undefined || invoice !== "undefined" || invoice?.length > 0) {
      setProducts([invoice?.invoiceData?.products]);
    }
  }, [invoice]);

  // function calculateDiscounts(products) {
  //   const discountsMap = {};
  //   if (products) {
  //     products.forEach((product) => {
  //       const quantity = product.quantity || 1; // Default to 1 if quantity is not provided
  //       console.log(quantity, "quantity here");
  //       product.discounts.forEach((discount) => {
  //         if (discount.isSelected) {
  //           const key = discount.name;
  //           if (!discountsMap[key]) {
  //             discountsMap[key] = 0;
  //           }
  //           if (discount.type === "%") {
  //             discountsMap[key] += parseFloat((discount.value * product.price * quantity) / 100);
  //           } else {
  //             discountsMap[key] += parseFloat(discount.value * quantity);
  //           }
  //         }
  //       });
  //     });
  //   }
  //   return discountsMap;
  // }
  function calculateDiscounts(products) {
    const discountsMap = {};
    if (products) {
      products.forEach((product) => {
        const quantity = product.quantity || 1; // Default to 1 if quantity is not provided
        let remainingPrice = product.price * quantity; // Start with the total price

        product.discounts.forEach((discount) => {
          // if (discount.isSelected) {
          const key = discount.name;
          if (!discountsMap[key]) {
            discountsMap[key] = 0;
          }

          let discountValue = 0;
          if (discount.type === "%") {
            discountValue = parseFloat((discount.value * remainingPrice) / 100); // Apply percentage discount
          } else {
            // discountValue = parseFloat(discount.value); // Apply fixed discount
            discountsMap[key] = Number(discount.value);
          }

          discountsMap[key] += discountValue; // Add to the map
          remainingPrice -= discountValue; // Deduct this discount from the remaining price
          // }
        });
      });
    }
    return discountsMap;
  }

  const filterTaxes = (products) => {
    if (products) {
      const allProductTaxes = [];
      for (let i = 0; i < products?.length; i++) {
        if (products[i] && products[i].taxes) {
          products[i].taxes.forEach((tax) => {
            allProductTaxes.push(tax);
          });
        }
      }
      let taxesUnique = [];
      const uniqueTaxesByProduct = [];
      const uniqueArray = allProductTaxes.map((tax) => {
        const arr = allProductTaxes.filter((item) => item._id === tax._id);
        if (arr.length) {
          let includedValue = arr.reduce((a, b) => {
            return a + b.includedValue;
          }, 0);
          let excludedValue = arr.reduce((a, b) => {
            return a + b.exclusiveVaue;
          }, 0);
          taxesUnique = [...taxesUnique, arr];
          uniqueTaxesByProduct.push({ ...tax, includedValue, excludedValue });
        }
      });
      const newuniqueArray = uniqueTaxesByProduct.filter(function(value, index, self) {
        return index === self.findIndex((t) => t._id === value._id);
      });

      return newuniqueArray?.length ? newuniqueArray : allProductTaxes;
    }
  };
  useEffect(() => {
    if (products) {
      let includedTaxes = filterTaxes(products?.flat());
      setFilterTaxess([...includedTaxes]);
    }
  }, [products]);

  const aggregatedDiscounts = calculateDiscounts(invoice?.invoiceData?.products || []);
  let language = invoice?.selectedInvoiceLanguage;
  return (
    <div className="pos-container">
      <div className="container d-flex justify-content-center align-items-center">
        <div className="pos-invoice" style={{ maxWidth: "405px", width: "100%", marginTop: "2rem" }}>
          <div className="ml-2">
            {invoice?.invoiceData?.senderInformation?.storeData?.image?.length > 0 ? (
              <>
                <img
                  style={{ maxWidth: "328px" }}
                  src={invoice?.invoiceData?.senderInformation?.storeData?.image ? invoice?.invoiceData?.senderInformation?.storeData?.image : ""}
                  alt="loading"
                />
              </>
            ) : (
              ""
            )}
          </div>
          <div className="bold-color">
            <h4 className="mt-3">
              <b style={{ fontWeight: 700 }}>
                {invoice?.invoiceData?.senderInformation?.storeData?.name ? invoice?.invoiceData?.senderInformation?.storeData?.name : ""}.
              </b>
            </h4>

            <p className="mt-0 mb-0 bold-color">
              {invoice?.invoiceData?.senderInformation?.storeData?.taxNumber?.length > 0
                ? `${refactorValues("", language).taxNo} ${invoice?.invoiceData?.senderInformation?.storeData?.taxNumber}`
                : ""}
            </p>
            <p className="mt-0 mb-0 bold-color">
              {invoice?.invoiceData?.senderInformation?.storeData?.address ? invoice?.invoiceData?.senderInformation?.storeData?.address : ""}
            </p>
            <p className="mt-0 mb-0 bold-color">
              {invoice?.invoiceData?.senderInformation?.storeData?.storeDetails
                ? invoice?.invoiceData?.senderInformation?.storeData?.storeDetails
                : ""}
            </p>
            {/* <p className="mt-0 mb-0 bold-color">
              {invoice?.invoiceData?.senderInformation?.companyId?.street
                ? invoice?.invoiceData?.senderInformation?.companyId?.street
                : invoice?.company?.street
                ? invoice?.company?.street
                : ""}
            </p>
            <p className="mt-0 mb-0 bold-color">
              {invoice?.invoiceData?.senderInformation?.companyId?.city || invoice?.invoiceData?.senderInformation?.companyId?.state
                ? `${invoice?.invoiceData?.senderInformation?.companyId?.city ? invoice?.invoiceData?.senderInformation?.companyId?.city : ""}, ${
                    invoice?.invoiceData?.senderInformation?.companyId?.state ? invoice?.invoiceData?.senderInformation?.companyId?.state : ""
                  }`
                : invoice?.company?.city || invoice?.company?.state
                ? `${invoice?.company?.city ? invoice?.company?.city : ""}, ${invoice?.company?.state ? invoice?.company?.state : ""}`
                : ""}
            </p> */}
            <p className="mt-0 mb-0 bold-color">
              {invoice?.invoiceData?.company?.mobile
                ? invoice?.company?.mobile
                : invoice?.invoiceData?.senderInformation?.companyId?.mobile?.length > 0
                ? invoice?.invoiceData?.senderInformation?.companyId?.mobile
                : ""}
            </p>
            <p className="mt-0 mb-0 bold-color">
              {invoice?.invoiceData?.company?.crNumber
                ? `CR#${invoice?.invoiceData.company?.crNumber}`
                : invoice?.invoiceData?.senderInformation?.companyId?.crNumber
                ? `CR#${invoice?.invoiceData?.senderInformation?.companyId?.crNumber}`
                : ""}
            </p>

            {(invoice?.invoiceData?.senderInformation?.storeData?.taxNumber || invoice?.company?.taxNumber) &&
            invoice?.invoiceData?.invoiceType !== "refund" ? (
              // &&  Number(invoice?.invoiceData?.taxTotal) > 0
              <>
                <div className="mt-3  bold-color" style={{ fontSize: "1rem", fontWeight: 400 }}>
                  <div className=" mw-100 "></div>
                  {refactorValues("", language).simplifiedInvoice}
                </div>
              </>
            ) : invoice?.invoiceData?.invoiceType === "refund" && invoice?.invoiceData?.senderInformation?.storeData?.taxNumber?.length === 0 ? (
              <div className="mt-3  bold-color" style={{ fontSize: "1rem", fontWeight: 400 }}>
                <div className=" mw-100 "></div>
                {refactorValues("", language).refund}
              </div>
            ) : invoice?.invoiceData?.invoiceType === "refund" && invoice?.invoiceData?.senderInformation?.storeData?.taxNumber?.length > 0 ? (
              <div className="mt-3  bold-color" style={{ fontSize: "1rem", fontWeight: 400 }}>
                <div className=" mw-100 "></div>
                {refactorValues("", language).creditNote}
              </div>
            ) : (
              ""
            )}

            {/* {invoice?.invoiceData?.invoiceType === "refund" ? (
              !invoice?.invoiceData?.senderInformation?.companyId?.taxNumber ? (
                <>
                  <div>
                    <div className="doted-line mw-100 mb-2 mt-2"></div>
                    <b>Credit note</b>
                  </div>
                </>
              ) : (
                <>
                  <div>
                    <b>Credit note</b>
                  </div>
                </>
              )
            ) : (
              ""
            )} */}
            {/* <div className="doted-line mb-2 mt-2"></div> */}

            <h1 className="mt-3">
              {invoice?.invoiceData?.senderInformation?.companyId?.currency}{" "}
              {invoice?.invoiceData?.invoiceType !== "refund" ? (
                <>{invoice?.invoiceData?.totalSales ? Number(invoice?.invoiceData?.totalaftertax)?.toFixed(2) : 0.0}</>
              ) : (
                <>{invoice?.invoiceData?.paidTotal ? Number(invoice?.invoiceData?.paidTotal)?.toFixed(2) : 0.0}</>
              )}
            </h1>
            <h5 style={{ fontWeight: 400, fontSize: "1rem" }}>{refactorValues("", language).totalText}</h5>
            <div className="doted-line mb-2 mt-2"></div>

            <div className="row">
              <div className={`col-12 d-flex align-items-start ${language === "en" ? "flex-row" : "flex-row-reverse"}`}>
                <div className="bold-color">{refactorValues("", language).invoiceNo}</div>
                <div className="mx-2">{invoice?.invoiceData?.invoiceNumber ? invoice?.invoiceData?.invoiceNumber : ""}</div>
              </div>

              {invoice?.invoiceData?.invoiceType === "refund" ? (
                <>
                  <div className={`col-12 ${language === "en" ? "text-left" : "text-right"}`}>
                    <div className={`bold-color `}>
                      Parent Invoice: {invoice?.invoiceData?.parentinvoiceNumber ? invoice?.invoiceData?.parentinvoiceNumber : ""}
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
            <div className={` bold-color w-100 ${language === "en" ? "text-left" : "text-right"}`}>
              {/* <div className={`bold-color `}> */}
              {moment(invoiceData?.invoiceData?.invoiceDate).format("MM-DD-YYYY")}
              &nbsp;{moment(invoice?.invoiceData?.invoiceDate).format("HH:mm")}
              {/* </div> */}
            </div>
            <div className="row">
              <div className={`col-12 d-flex align-items-start ${language === "en" ? "flex-row" : "flex-row-reverse"}`}>
                <div className="bold-color">{refactorValues("", language).cashier}</div>
                <div className="mx-2">{invoice?.invoiceData?.activeuser ? invoice?.invoiceData?.activeuser : ""}</div>
              </div>
              <div className="">
                {/* <div className="light-color">{invoice?.invoiceData?.invoiceNumber ? invoice?.invoiceData?.invoiceNumber : ""}</div> */}
              </div>
            </div>

            <div className="row">
              <div className={`col-12 d-flex align-items-start ${language === "en" ? "flex-row" : "flex-row-reverse"}`}>
                <div className="bold-color">{refactorValues("", language).pos}</div>
                <div className="mx-2">{invoice?.invoiceData?.pos ? invoice?.invoiceData?.pos : ""}</div>
              </div>
            </div>
            {invoice?.invoiceData?.receiverInformation?.name?.first ? (
              <>
                <div className="doted-line mb-2 mt-2"></div>
                <div className="row">
                  <div className={`col-12 d-flex align-items-start ${language === "en" ? "flex-row" : "flex-row-reverse"}`}>
                    <div className="bold-color">{refactorValues("", language).customer}</div>
                    <div className=" mx-2">
                      {" "}
                      {invoice?.invoiceData?.receiverInformation?.name?.first + " " + invoice?.invoiceData?.receiverInformation?.name?.last}
                    </div>
                  </div>
                </div>
              </>
            ) : (
              ""
            )}
            <div className="doted-line mb-2 mt-2"></div>

            {invoiceData?.invoiceData?.products?.map((item) => (
              <>
                <div className="row mt-2">
                  <div className={`col-12 d-flex justify-content-between align-items-start ${language === "en" ? "flex-row" : "flex-row-reverse"}`}>
                    <div className=""> {item?.name ? item?.name : ""}</div>
                    <div className="">{item?.totalPrice ? Number(item?.totalPrice)?.toFixed(2) : 0.0}</div>
                  </div>
                </div>
                <div
                  className={`pos-invoice-cashier mt-1 mb-1 w-100 ${
                    language === "en" ? "text-start justify-content-start" : "text-right justify-content-end"
                  }`}
                >
                  <div className="light-color">{`${item?.quantity} x ${Number(item?.price).toFixed(2)}`}</div>
                </div>
              </>
            ))}
            <div className="doted-line mb-2 mt-2"></div>
            {Number(invoice?.invoiceData?.discountTotal) > 0 ? (
              <>
                <div className="row">
                  <div
                    className={`d-flex w-100 px-3 align-items-center justify-content-between ${language !== "ar" ? "flex-row" : "flex-row-reverse"}`}
                  >
                    <div>
                      <b>{refactorValues("", language).subTotal}</b>
                    </div>
                    <div>
                      <b> {invoice?.invoiceData?.productsTotal ? Number(invoice?.invoiceData?.productsTotal)?.toFixed(2) : 0.0}</b>
                    </div>
                  </div>
                </div>

                {Object.keys(aggregatedDiscounts).map((discountName, index) => {
                  const totalDiscount = aggregatedDiscounts[discountName];

                  return (
                    <div
                      key={index}
                      className={` d-flex align-items-center justify-content-between ${language !== "ar" ? "flex-row" : "flex-row-reverse"}`}
                    >
                      <div className="">
                        <div>{discountName}</div>
                      </div>
                      <div className="">-{Number(totalDiscount).toFixed(2)}</div>
                    </div>
                  );
                })}

                <div className="doted-line mb-2 mt-2"></div>

                {invoice?.invoiceData?.taxTotal === "0.00" ? (
                  <>
                    <div className={` d-flex align-items-center justify-content-between ${language !== "ar" ? "flex-row" : "flex-row-reverse"}`}>
                      <div>
                        <b> {refactorValues("", language).totalText} </b>
                      </div>
                      <div>
                        <b>{invoice?.invoiceData?.productsTotal ? totalValueWithDiscount?.toFixed(2) : 0.0}</b>
                      </div>
                    </div>
                  </>
                ) : (
                  ""
                )}
              </>
            ) : (
              <>
                {invoice?.invoiceData?.taxTotal === "0.00" ? (
                  <>
                    <div className={` d-flex align-items-center justify-content-between ${language !== "ar" ? "flex-row" : "flex-row-reverse"}`}>
                      <div className="">
                        <b> {refactorValues("", language).totalText} </b>
                      </div>
                      <div className="">
                        <b>{invoice?.invoiceData?.productsTotal ? Number(invoice?.invoiceData?.productsTotal)?.toFixed(2) : 0.0}</b>
                      </div>
                    </div>
                  </>
                ) : (
                  ""
                )}
              </>
            )}
            {Number(invoice?.invoiceData?.taxTotal) > 0 || filterTaxess?.length > 0 ? (
              <>
                <div className={`mt-3 mb-2 d-flex align-items-center justify-content-between ${language !== "ar" ? "flex-row" : "flex-row-reverse"}`}>
                  <div className="">
                    <b>{refactorValues("", language).totalBeforeTax}</b>
                  </div>
                  <div className="">
                    <b>{invoice?.invoiceData?.totalbeforetax ? Number(invoice?.invoiceData?.totalbeforetax)?.toFixed(2) : 0.0}</b>
                  </div>
                </div>
                {/* {invoiceData?.invoiceData?.products?.map((product, index) => {
                  return (
                    <div key={index}>
                      {product?.taxes?.map((tax, index) => {
                        return (
                          <div className="d-flex justify-content-between allign-items-center">
                            <>
                              {" "}
                              <div>
                                {tax?.name}
                                <span className="mx-1">{tax?.taxRate}%</span>{" "}
                                {tax?.type !== "Added to the price" ? <span className="invoice-date-section">(Included)</span> : ""}
                              </div>
                              <div>
                                {tax?.type?.includes("Included") ? Number(tax?.includedValue)?.toFixed(2) : Number(tax?.exclusiveVaue)?.toFixed(2)}
                              </div>
                            </>
                          </div>
                        );
                      })}
                    </div>
                  );
                })} */}
                {filterTaxess?.map((tax) => (
                  <>
                    <div className={`mt-1 d-flex align-items-center justify-content-between ${language !== "ar" ? "flex-row" : "flex-row-reverse"}`}>
                      <div className="">
                        {`${tax?.name}  ${tax?.taxRate}%`}
                        <span className="light-color ml-1">
                          {tax?.type === "Included in the price" ? <span>({refactorValues("", language).included})</span> : ""}
                        </span>
                      </div>
                      <div className="">
                        {Number(tax?.includedValue) > 0 ? Number(tax?.includedValue)?.toFixed(2) : Number(tax?.excludedValue)?.toFixed(2)}
                      </div>
                    </div>
                  </>
                ))}

                <div className="doted-line mb-2 mt-2"></div>
                <div className={` d-flex align-items-center justify-content-between ${language !== "ar" ? "flex-row" : "flex-row-reverse"}`}>
                  <div className="">
                    <b>
                      {Number(invoice?.invoiceData?.taxTotal) > 0
                        ? refactorValues("", language).totalWithTax
                        : refactorValues("", language).totalText}{" "}
                    </b>
                  </div>
                  <div className="">
                    <b>{invoice?.invoiceData?.totalaftertax ? Number(invoice?.invoiceData?.totalaftertax)?.toFixed(2) : 0.0}</b>
                  </div>
                </div>
              </>
            ) : (
              ""
            )}
            {Number(invoiceData?.invoiceData?.balance)?.length > 0 ? (
              <>
                <div className={` d-flex align-items-center justify-content-between ${language !== "ar" ? "flex-row" : "flex-row-reverse"}`}>
                  <div className="">
                    <b> Amount </b>
                  </div>
                  <div className="">
                    <b>{invoice?.invoiceData?.balance ? Number(invoice?.invoiceData?.balance)?.toFixed(2) : 0.0}</b>
                  </div>
                </div>
              </>
            ) : (
              ""
            )}

            {invoiceData?.invoiceData?.payments?.length <= 1 ? (
              <>
                <div className={`mt-1 d-flex align-items-center justify-content-between ${language !== "ar" ? "flex-row" : "flex-row-reverse"}`}>
                  <div className="">
                    <div>{invoice?.invoiceData?.payments[0]?.method ? invoice?.invoiceData?.payments[0]?.method : ""}</div>
                  </div>
                  <div>
                    <div>{invoice?.invoiceData?.paidTotal ? Number(invoice?.invoiceData?.paidTotal)?.toFixed(2) : 0.0}</div>
                  </div>
                </div>
                {Number(invoice?.invoiceData?.change) > 0 && invoice?.invoiceData?.invoiceType !== "refund" && (
                  <div className={`mt-1 d-flex align-items-center justify-content-between ${language !== "ar" ? "flex-row" : "flex-row-reverse"}`}>
                    <div className="">{refactorValues("", language).change}</div>
                    <div
                      className="
                    "
                    >
                      {invoice?.invoiceData?.change ? Number(invoice?.invoiceData?.change)?.toFixed(2) : 0.0}
                    </div>
                  </div>
                )}
              </>
            ) : window?.location?.pathname?.split("/")[3]?.length > 0 ? (
              <>
                <>
                  {invoice?.invoiceData?.payments?.length > 1 && invoice?.invoiceData?.invoiceType !== "refund" ? (
                    <div className={`mt-2 d-flex align-items-center justify-content-between ${language !== "ar" ? "flex-row" : "flex-row-reverse"}`}>
                      <div className="">
                        <div>{refactorValues("", language).amountDue}</div>
                      </div>
                      <div className="">
                        <div>
                          {invoice?.invoiceData?.payments[window?.location?.pathname?.split("/")[3]]?.amount
                            ? Number(invoice?.invoiceData?.payments[window?.location?.pathname?.split("/")[3]]?.amount)?.toFixed(2)
                            : 0.0}
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  <div className={`mt-1 d-flex align-items-center justify-content-between ${language !== "ar" ? "flex-row" : "flex-row-reverse"}`}>
                    <div className="">
                      <div>
                        {invoice?.invoiceData?.payments[window?.location?.pathname?.split("/")[3]]?.method
                          ? invoice?.invoiceData?.payments[window?.location?.pathname?.split("/")[3]]?.method
                          : ""}
                      </div>
                    </div>
                    <div className="">
                      <div>
                        {invoice?.invoiceData?.payments[window?.location?.pathname?.split("/")[3]]?.cashRecieved
                          ? Number(invoice?.invoiceData?.payments[window?.location?.pathname?.split("/")[3]]?.cashRecieved)?.toFixed(2)
                          : 0.0}
                      </div>
                    </div>
                  </div>
                  {Number(invoice?.invoiceData?.payments[window?.location?.pathname?.split("/")[3]]?.change).toFixed(2) !== "0.00" ? (
                    <div className={`mt-1 d-flex align-items-center justify-content-between ${language !== "ar" ? "flex-row" : "flex-row-reverse"}`}>
                      <div className="">{refactorValues("", language).change}</div>
                      <div className="">
                        {invoice?.invoiceData?.payments[window?.location?.pathname?.split("/")[3]]?.change
                          ? Number(invoice?.invoiceData?.payments[window?.location?.pathname?.split("/")[3]]?.change).toFixed(2)
                          : 0.0}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </>
              </>
            ) : (
              invoice?.invoiceData?.payments?.map((item) => (
                <>
                  <>
                    {invoice?.invoiceData?.payments?.length > 1 && invoice?.invoiceData?.invoiceType !== "refund" ? (
                      <div
                        className={`mt-2 d-flex align-items-center justify-content-between ${language !== "ar" ? "flex-row" : "flex-row-reverse"}`}
                      >
                        <div className="">
                          <div>{refactorValues("", language).amountDue}</div>
                        </div>
                        <div className=" text-right">
                          <div>{item?.amount ? Number(item?.amount)?.toFixed(2) : 0.0}</div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}

                    <div
                      className={`mt-1 W-100 d-flex align-items-center justify-content-between ${
                        language !== "ar" ? "flex-row" : "flex-row-reverse"
                      }`}
                    >
                      <div className="">
                        <div>{item?.method ? item?.method : ""}</div>
                      </div>
                      <div className="">
                        {invoiceData?.invoiceData?.payments?.length <= 1 && invoice?.invoiceData?.invoiceType !== "refund"
                          ? Number(invoice?.invoiceData?.paidTotal)?.toFixed(2)
                          : invoice?.invoiceData?.invoiceType !== "refund" && invoiceData?.invoiceData?.payments?.length > 1
                          ? // ?
                            Number(item?.cashRecieved)?.toFixed(2)
                          : Number(item?.amount).toFixed(2)}{" "}
                      </div>
                    </div>
                    {Number(item?.change).toFixed(2) !== "0.00" ? (
                      <div
                        className={`mt-1 d-flex align-items-center justify-content-between ${language !== "ar" ? "flex-row" : "flex-row-reverse"}`}
                      >
                        <div className="">{refactorValues("", language).change}</div>
                        <div className="">{item?.change ? Number(item?.change).toFixed(2) : 0.0}</div>
                      </div>
                    ) : (
                      ""
                    )}
                  </>
                </>
              ))
            )}
            <div className="doted-line mb-2 mt-2"></div>

            {invoice?.invoiceData?.senderInformation?.storeData?.taxNumber ||
            invoice?.company?.taxNumber ||
            invoice?.invoiceData?.senderInformation?.companyId?.taxNumber ? (
              <>
                <div className="pos-qrcode">
                  <img src={IconQr} alt="loading" style={{ width: "100px", height: "100px" }} />
                </div>
              </>
            ) : (
              ""
            )}
            {invoice?.invoiceData?.senderInformation?.storeData?.description && (
              <>
                {" "}
                <div className="my-4  bold-color" style={{ fontSize: "1rem", marginBottom: "1.5rem" }}>
                  {/* <h3>Thank You, Come Again !</h3> */}
                  <div>{invoice?.invoiceData?.senderInformation?.storeData?.description}</div>
                </div>
                <div className="doted-line mb-2 mt-5"></div>
              </>
            )}

            <div className="my-4 bold-color" style={{ fontSize: "1rem" }}>
              <div>Powerd by Fatoraty 2023</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
