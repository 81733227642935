import React, { useMemo } from "react";
import { Link, useLocation } from "react-router-dom";
import objectPath from "object-path";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import NavToggler from "../../../../assets/img/nav-toggler.png";
// import fatoratyLogo from "../../../../assets/img/logo-light.svg";
import fatoratyLogo from "../../../../assets/img/logoUpdated.svg";
import { useAside } from "../AsideContext";
import { useDispatch, useSelector } from "react-redux";
import {
  expiredSubscriptionWithTwoMinutes,
  failEveryPayment,
  getSubscription,
  pausedSubscription,
} from "../../../../_redux/subscription/subscriptionService";
import { i18n } from "../../../../app/pages/private/languageSelector";
import { translateModuleName } from "../../../../helpers/helpers";

export function Brand() {
  const dispatch = useDispatch();
  const { language } = useSelector((state) => state.auth);
  const { subscriptionData } = useSelector((state) => state.subscription);
  const location = useLocation();
  const { pathname } = location;
  // Define a mapping of specific paths to display text
  const pathToTextMapping = {
    "analytics/sales-summary": "Sales by Summary",
    "sales-by-product": "Sales by Product",
    "sales-by-category": "Sales by Category",
    "sales-by-user": "Sales by User",
    "sales-by-payment-channel": "Sales by Payment Channel",
    "sales-by-time": "Sales by Time",
    "tax-report": "Taxes",
    "discount-report": "Discounts",
  };
  // Extract the full path after the base URL
  const pathSegment = pathname
    .split("/")
    .filter(Boolean)
    .join("/");

  // Get the display text from the mapping
  let displayText;

  if (pathSegment.startsWith("products/actions-modal/")) {
    displayText = "Products";
  } else {
    displayText =
      pathToTextMapping[pathSegment] ||
      pathSegment
        .split("/")
        .pop()
        .replace("-", " ")
        .replace(/(^\w|\s\w)/g, (m) => m.toUpperCase());
  }
  const uiService = useHtmlClassService();
  const { toggleAside, isAsideOpen } = useAside();
  const layoutProps = useMemo(() => {
    return {
      brandClasses: uiService.getClasses("brand", true),
      asideSelfMinimizeToggle: objectPath.get(uiService.config, "aside.self.minimize.toggle"),
      headerLogo: uiService.getLogo(),
      headerStickyLogo: uiService.getStickyLogo(),
    };
  }, [uiService]);
  const handlePausedSubscription = async () => {
    await dispatch(pausedSubscription());
    await dispatch(getSubscription());
  };
  const handleSubscriptionWithTwoMinutes = async () => {
    await dispatch(expiredSubscriptionWithTwoMinutes()).then(() => {
      dispatch(getSubscription());
    });
  };
  const handleFailEveryPayment = async () => {
    await dispatch(failEveryPayment()).then(() => {
      dispatch(getSubscription());
    });
  };
  let isSubscribed = subscriptionData?.subscription?.subscriptionInfo?.isSubscribed;
  let isPaused = subscriptionData?.subscription?.currentPlan.status !== "Paused";

  const applyMargin = () => {
    let path = window.location.pathname;
    if (path.includes("/sales-by-payment-channel") || path.includes("/sales-by-user")) {
      return "42rem";
    } else if (path.includes("/sales-summary")||path.includes("/user-settings")) {
      return "37rem";
    } else if (path.includes("/sales-by-product") || path.includes("/sales-by-category") || path.includes("/sales-by-time")) {
      return "40rem";
    } else if (path.includes("/pos-devices")) {
      return "35rem";
    } else return "33rem";
  };
  return (
    <>
      {/* begin::Brand */}
      <div
        className={`brand flex-column-auto ${layoutProps.brandClasses} f-backgroundColor`}
        id="kt_brand"
        style={{
          direction: language === "arabic" ? "rtl" : "ltr",
          width: language === "english" && "650px",
        }}
      >
        {/* begin::Logo */}
        {/* <Link to="/dashboard" className="brand-logo"> */}

        {/* end::Logo */}
        {layoutProps.asideSelfMinimizeToggle && (
          <>
            {/* begin::Toggle */}
            <span
              onClick={() => toggleAside()}
              className={`aside-toggler px-0 menu-toggleAction f-sideMenu-toggle `}
              id="kt_aside_toggle"
              style={{
                marginLeft: language === "english" && "30px",
                marginRight:
                  isAsideOpen && language === "arabic"
                    ? "30px"
                    : !isAsideOpen && language === "arabic" && (!isSubscribed || (isSubscribed && !isPaused))
                    ? "18rem"
                    : !isAsideOpen && language === "arabic" && isSubscribed
                    ? // ? "35rem"
                      applyMargin()
                    : "0px",
              }}
            >
              <img src={NavToggler} alt="loading" />
            </span>
            {/* Display the capitalized path segment */}

            {/* end::Toolbar */}
          </>
        )}
        <Link
          to="/analytics/sales-summary"
          className="brand-image-logo"
          style={{
            marginLeft: language === "english" && "4rem",
            marginRight: language === "arabic" && isAsideOpen ? "4rem" : language === "arabic" && !isAsideOpen && isSubscribed && "3rem",
          }}
        >
          <img alt="logo" src={fatoratyLogo} style={{ width: "111px", height: "32px" }} />
        </Link>

        <span
          className=" header-subscription-text"
          style={{
            width: "100%",
            minWidth: "fit-content",
            marginRight: language === "arabic" && "20px",
            whiteSpace: "nowrap",
            marginLeft: language === "english" && isAsideOpen ? "4rem" : "0rem",
            // marginRight: language === "arabic" && "4rem",
            marginRight: language === "arabic" && isAsideOpen ? "4rem" : language === "arabic" && !isAsideOpen && isPaused ? "2rem" : "0px",
          }}
        >
          {translateModuleName(displayText)}
        </span>
        {subscriptionData?.subscription?.subscriptionInfo?.isSubscribed && subscriptionData?.subscription?.currentPlan.status !== "Paused" && (
          <button className="btn btn-sm btn-info mx-2" onClick={handlePausedSubscription}>
            Pause subscription
          </button>
        )}
        {subscriptionData?.subscription?.subscriptionInfo?.isSubscribed && subscriptionData?.subscription?.currentPlan.status !== "Paused" && (
          <button className="btn btn-sm btn-info mx-2" onClick={handleSubscriptionWithTwoMinutes}>
            Expire Subscription in 2 mins
          </button>
        )}
        <button className="btn  btn-sm btn-info mx-2 " onClick={handleFailEveryPayment}>
          {subscriptionData?.subscription?.paymentShouldFail === false ? "Fail every payment" : "Succeed every payment"}
        </button>
      </div>
      {/* end::Brand */}
    </>
  );
}
