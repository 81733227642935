import React, { useEffect, useMemo, useState } from "react";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import { i18n } from "../../../../app/pages/private/languageSelector";
import { getSubscription } from "../../../../_redux/subscription/subscriptionService";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import PricingPlanDialog from "../../../../app/pages/company/company-edit-Dialog/PricingPlanDialog";
import ErrorIcon from "@material-ui/icons/Error";
import { useAside } from "../AsideContext";

export function Footer() {
  const today = new Date().getFullYear();
  const uiService = useHtmlClassService();
  const { isAsideOpen } = useAside();
  const dispatch = useDispatch();
  const { language } = useSelector((state) => state.auth);
  const [showPricingPlanModal, setShowPricingPlanModal] = useState(false);

  const { subscriptionData } = useSelector((state) => state.subscription);

  const trialEndedAtFormatted = moment(subscriptionData?.subscription?.trialEndedAt).fromNow();

  const layoutProps = useMemo(() => {
    return {
      footerClasses: uiService.getClasses("footer", true),
      footerContainerClasses: uiService.getClasses("footer_container", true),
    };
  }, [uiService]);

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(getSubscription());
    };
    fetchData();
  }, [dispatch]);

  const trialEndedAt = subscriptionData?.subscription?.trialEndedAt;
  const trialEndDays = trialEndedAt
    ? moment(trialEndedAt)
        .startOf("day")
        .diff(moment().startOf("day"), "days")
    : null;

  return (
    <>
      <div className="footer-padding"></div>
      <div
        className={`footer py-4 d-flex flex-lg-column custom-footer  ${layoutProps.footerClasses}`}
        id="kt_footer"
        style={{
          backgroundColor:
            subscriptionData?.subscription?.currentPlan?.plan === "Trial" && subscriptionData?.subscription?.currentPlan?.status === "Running"
              ? "#FFF6DC"
              : subscriptionData?.subscription?.currentPlan?.plan === "Trial" && subscriptionData?.subscription?.currentPlan?.status === "Expired"
              ? "#EB57571A"
              : "white",
          direction: language === "arabic" ? "rtl" : "ltr",
          border: subscriptionData?.subscription?.currentPlan?.status === "Paused" ? "1px solid #EB5757" : "none",
        }}
      >
        <div
          className={`${layoutProps.footerContainerClasses} d-flex  flex-row ${
            subscriptionData?.currentPlan === "Trial" ? "show-desktop" : ""
          } align-items-center justify-content-between`}
        >
          {subscriptionData?.subscription?.currentPlan !== "Trial" && subscriptionData?.subscription?.currentPlan?.status === "Running" ? (
            <div
              className={`text-dark  ${
                subscriptionData?.subscription?.currentPlan?.plan === "Trial" && subscriptionData?.subscription?.currentPlan?.status === "Running"
                  ? "show-desktop"
                  : ""
              }  ${!isAsideOpen && language === "arabic" && "pr-20"}`}
            >
              <span className="text-muted font-weight-bold mr-2">{today.toString()}</span> &copy;{" "}
              <a href="/#" target="_blank" rel="noopener noreferrer" className={"text-dark-75 text-hover-primary "}>
                {i18n("LandingPage.Fatoraty")}
              </a>
            </div>
          ) : null}

          {subscriptionData?.subscription?.currentPlan?.plan === "Trial" && subscriptionData?.subscription?.currentPlan?.status === "Running" && (
            <div className="text-dark">
              <span className="text-dark-75 text-hover-primary">
                {/* {i18n("SubscriptionModals.TheFreeTrialForUsersManagementExpiresIn")} {trialEndDays} {i18n("SubscriptionModals.days")} */}
                {i18n("SubscriptionModals.FreeTrialMessage")} {trialEndDays} {i18n("SubscriptionModals.Days")}
                {/* Trial for users management expires in  days{" "} */}
              </span>
            </div>
          )}
          {subscriptionData?.subscription?.currentPlan?.plan === "Trial" && subscriptionData?.subscription?.currentPlan?.status === "Expired" && (
            <div className="text-dark ">
              <span style={{ color: "#EB5757" }}>
                {/* {i18n(
                    "SubscriptionModals.YourUsersManagementSubscriptionHasExpired"
                  )}{" "} */}
                {i18n("Company.TrialExpiredMessage")}
                {/* {trialEndedAtFormatted} .{i18n("SubscriptionModals.PleaseActivateTheSubscriptionToContinueUsingThisService")} */}
              </span>
            </div>
          )}
          {subscriptionData?.subscription?.currentPlan?.status === "Paused" && (
            <p className="mt-2 d-flex justify-content-center text-dark ">
              {/* <span className="text-danger">{i18n("SubscriptionModals.SubscriptionAutoRenewalFailedEnsurePayamentCardHasSufficientFunds")}</span> */}
              <span style={{ color: "#EB5757" }}>{i18n("Company.SubscriptionAutoFailed")}</span>
            </p>
          )}
          <div className="nav nav-dark order-1 order-md-2">
            {subscriptionData?.subscription?.currentPlan?.plan === "Free" && subscriptionData?.subscription?.currentPlan?.status !== "Paused" && (
              <a href="/#" target="_blank" rel="noopener noreferrer" className="nav-link pr-3 pl-0 show-desktop">
                {i18n("LandingPage.About")}
              </a>
            )}
            {subscriptionData?.subscription?.currentPlan?.plan === "Trial" &&
              (subscriptionData?.subscription?.currentPlan?.status === "Expired" ||
                subscriptionData?.subscription?.currentPlan?.status === "Running") && (
                <span className="try-for-free" onClick={() => setShowPricingPlanModal(true)}>
                  {i18n("global.SUBSCRIBE")}
                </span>
              )}
            {subscriptionData?.subscription?.currentPlan?.status === "Paused" && (
              <span className="try-for-free">{i18n("SubscriptionModals.RETRYPAYMENT")}</span>
            )}
          </div>
        </div>
      </div>
      <PricingPlanDialog showModal={showPricingPlanModal} onHide={() => setShowPricingPlanModal(false)} />
    </>
  );
}
