import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { Modal, Form } from "react-bootstrap";
import { Formik, Field } from "formik";
import { Input, Card, Mobile, ReactSelector, Checkbox, SwitchToggler } from "../../../../../_metronic/_partials/controls";
import { useLocation, useHistory } from "react-router-dom";
import { i18n } from "../../../../pages/private/languageSelector";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import PinInput from "react-pin-input";
import IconEditAvatar from "../../../../../assets/img/icon-edit-avatar.svg";
import IconLock from "../../../../../assets/img/padlock.svg";
import IconClose from "../../../../../assets/img/icon-close.svg";
import IconTrash from "../../../../../assets/img/icon-trash.svg";
import UserAvatar from "../../../../../assets/img/user-avatar-default.jpg";
import * as rolesActions from "../../../../../_redux/roles/rolesActions";
import * as storesActions from "../../../../../_redux/stores/storeActions";
import * as usersActions from "../../../../../_redux/users/usersActions";
import toast from "react-hot-toast";
import ImageUploading from "react-images-uploading";
import { AsYouType } from "libphonenumber-js/max";
import SubscriptionDialog from "../SubscriptionDialog";
import {
  expiredTrialModalShowToggler,
  getSubscription,
  startFreeTrial,
  subscriptionAddUser,
} from "../../../../../_redux/subscription/subscriptionService";
import { getPaymentCard } from "../../../../../_redux/payment/paymentService";
import { CompanyFetch } from "../../../../../_redux/company/companyActions";
import PricingPlanDialog from "../PricingPlanDialog";
import {
  shouldShowPricingPlanModal,
  shouldShowPricingPlanModalAfterBackToFreePlan,
  shouldShowPricingPlanModalConfirmAfterBackToFreePlan,
  shouldShowPricingPlanModalForTrial,
} from "../../../../../helpers/constants";
import moment from "moment";
import { getAllUsers } from "../../../../../_redux/analytics/analyticsService";

let asYouType = new AsYouType();
Yup.addMethod(Yup.mixed, "validatePhoneNumber", function(msg) {
  return this.test({
    name: "validatePhoneNumber",
    message: msg,
    test: (value) => {
      if (value) {
        asYouType.reset();
        asYouType.input(value);
        return asYouType.getNumber().isValid();
      } else {
        return true;
      }
    },
  });
});

const UserSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(3, i18n("Validation.Minimun3Alphabets"))
    .max(50, "Maximum 50 Alphabets")
    .required(i18n("Validation.FirstNameIsRequired")),
  lastName: Yup.string()
    .min(3, i18n("Validation.Minimun3Alphabets"))
    .max(50, "Maximum 50 Alphabets")
    .required(i18n("Validation.LastNameIsRequired")),
  email: Yup.string()
    .email(i18n("Validation.InvalidEmail"))
    .matches(
      // eslint-disable-next-line
      /^\w+[\w-\.]*\@\w+((-\w+)|(\w*))\.[a-z]{2,3}$/,
      "Must contain e.g (example._-@example.com)"
    )
    .required(i18n("Validation.EmailIsRequired")),
  mobile: Yup.string()
    .required(i18n("Validation.PhoneNumberRequired"))
    .validatePhoneNumber(i18n("Validation.INVALID_PHONE")),

  role: Yup.string(),
  isActive: Yup.boolean(),
});

let initValues = {
  firstName: "",
  lastName: "",
  email: "",
  mobile: "",
  role: "",
  isActive: true,
};

export const UserEditForm = ({ edit, userForEdit, defaultPosPin, openUsersAddDialog, setOpenUsersAddDialog }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const { subscriptionData } = useSelector((state) => state.subscription);

  const { companyForEdit } = useSelector(
    (state) => ({
      companyForEdit: state.company.companyForEdit,
    }),
    shallowEqual
  );

  const { users } = useSelector((state) => state.analytics);

  const { paymentCard } = useSelector((state) => state.paymentCard);

  const [image, setImage] = useState([]);
  const [posPin, setPosPin] = useState("");
  const [allStores, setAllStores] = useState([]);
  const [rolePayload, setRolePayload] = useState({});
  const [disableRole, setDisableRole] = useState(false);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [showUnsavedChangesModal, setShowUnsavedChangesModal] = useState(false);
  const [startFreeTrialDialog, setStartFreeTrialDialog] = useState(false);
  const [showContinueSubscriptionModal, setContinueSubscriptionModal] = useState(false);
  const [showDeleteUserModal, setShowDeleteUserModal] = useState(false);
  const [isFormFilled, setIsFormFilled] = useState(false);
  const [showPaymentForUserModal, setShowPaymentForUserModal] = useState(false);
  const [showPricingPlanModal, setShowPricingPlanModal] = useState(false);
  const [showTrialExpiredModal, setShowTrialExpiredModal] = useState(false);
  const [showAddPaymentModal, setShowAddPaymentModal] = useState(false);
  const [showAddUserModal, setShowAddUserModal] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(CompanyFetch());
      if (companyForEdit?.paymentCards?.moyasar) {
        if (companyForEdit._id) {
          await dispatch(getPaymentCard({ companyId: companyForEdit._id }));
        }
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (unsavedChanges) {
        event.preventDefault();
        event.returnValue = "";
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [unsavedChanges]);

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(getSubscription());
      await dispatch(getAllUsers());
    };
    fetchData();
  }, [dispatch]);

  const { stores, roles, user } = useSelector(
    (state) => ({
      user: state.auth.user,
      roles: state.roles.entities,
      stores: state.stores?.entities,
      users: state.users.entities,
    }),
    shallowEqual
  );
  const { language } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(rolesActions.fetchRoles());
    dispatch(storesActions.fetchStores());
  }, [dispatch, openUsersAddDialog]);

  useEffect(() => {
    if (userForEdit && edit && openUsersAddDialog) {
      setRolePayload(userForEdit?.rolePayload);
      setPosPin(userForEdit?.posPin);
      if (userForEdit?.email === user?.email) {
        setDisableRole(true);
      } else {
        setDisableRole(false);
      }
    } else if (!openUsersAddDialog && isFormFilled) {
      setPosPin(defaultPosPin);
      setRolePayload("");
    } else if (!openUsersAddDialog) {
      setPosPin("");
    }
  }, [userForEdit, edit, openUsersAddDialog, user, defaultPosPin, isFormFilled]);

  useEffect(() => {
    if (rolePayload && rolePayload.pos) {
      if (posPin?.length === 0) {
        setPosPin(defaultPosPin);
      }
    }
  }, [rolePayload, defaultPosPin]);

  useEffect(() => {
    if (userForEdit === null) {
      setDisableRole(false);
    }
  }, [userForEdit]);

  useEffect(() => {
    let refectorStores;

    if (stores && stores.length && userForEdit && userForEdit.storesAccess) {
      refectorStores = stores.map((item) => {
        if (userForEdit?.rolePayload?.originalName === "owner") {
          return { ...item, active: true };
        } else {
          let userStoreArray = userForEdit.storesAccess;

          let activeItem = userStoreArray.find((subItem) => subItem === item._id);
          if (activeItem) {
            return { ...item, active: true };
          } else {
            return { ...item, active: false };
          }
        }
      });
    } else if (stores && stores.length && userForEdit && !userForEdit.storesAccess) {
      refectorStores = stores.map((item) => {
        if (userForEdit?.rolePayload?.originalName === "owner") {
          return { ...item, active: true };
        }
        return { ...item, active: false };
      });
    } else if (stores && stores.length && !userForEdit) {
      refectorStores = stores.map((item) => {
        return { ...item, active: true };
      });
    }
    if (refectorStores && refectorStores.length) {
      setAllStores([...refectorStores]);
    }
    // eslint-disable-next-line
  }, [stores, userForEdit]);

  const onChange = (imageList, addUpdateIndex) => {
    setImage(imageList);
  };

  const deleteUserHandler = async () => {
    if (subscriptionData?.subscription?.currentPlan?.plan === "Trial" && subscriptionData?.subscription?.currentPlan?.status === "Expired") {
      if (
        users?.length === 2 &&
        subscriptionData?.subscription?.subscriptionInfo.isSubscribed === false &&
        subscriptionData?.subscription?.subscriptionInfo.isUnsubscribed === false &&
        subscriptionData?.subscription?.trialExpireModalShow === false
      ) {
        await dispatch(expiredTrialModalShowToggler()).then((response) => {
          if (
            response.payload.status === true &&
            location.pathname === "/users/edit/delete" &&
            userForEdit &&
            openUsersAddDialog &&
            location.pathname !== "/users"
          ) {
            setShowTrialExpiredModal(true);
          }
        });
      }
      dispatch(usersActions.deleteSubscriptionUsers(userForEdit?._id))
        .then((res) => {
          if (res) {
            dispatch(usersActions.fetchUsers());
            dispatch(getSubscription());
            setOpenUsersAddDialog(false);
            // history.push("/users");
            setShowDeleteUserModal(false);
            toast.success("User Deleted");
          }
        })
        .catch((error) => {
          console.log("Error", error);
        });
    } else if (subscriptionData && subscriptionData?.subscription?.subscriptionInfo?.isSubscribed) {
      dispatch(usersActions.deleteSubscriptionUsers(userForEdit?._id))
        .then((res) => {
          if (res) {
            dispatch(usersActions.fetchUsers());
            dispatch(getSubscription());
            setOpenUsersAddDialog(false);
            history.push("/users");
            setShowDeleteUserModal(false);
            toast.success("User Deleted");
          }
        })
        .catch((error) => {
          console.log("Error", error);
        });
    } else {
      dispatch(usersActions.deleteUser(userForEdit?._id))
        .then((res) => {
          if (res) {
            dispatch(usersActions.fetchUsers());
            dispatch(getSubscription());
            setOpenUsersAddDialog(false);
            history.push("/users");
            setShowDeleteUserModal(false);
            toast.success("User Deleted");
          }
        })
        .catch((error) => {
          console.log("Error", error);
        });
    }
  };

  const stateEmptyHandler = (resetForm) => {
    resetForm();
    setImage([]);
    setPosPin("");
    setOpenUsersAddDialog(false);
    history.push("/users");
  };

  const handleInputChange = () => {
    setUnsavedChanges(true);
  };

  const startFreeTrialHandler = async () => {
    await dispatch(startFreeTrial());
    setStartFreeTrialDialog(false);
    setOpenUsersAddDialog(false);
    history.push("/users");
  };

  const trialEndTime = moment(subscriptionData?.subscription?.trialEndedAt).diff(new Date(), "minutes");

  const onSubmitHandler = async (values, resetForm) => {
    const existingUser = users && users?.find((user) => user.email === values.email || user.mobile === values.mobile);

    if (!edit && existingUser) {
      return toast.error("User with this email or mobile already exists");
    }

    if (shouldShowPricingPlanModalAfterBackToFreePlan(subscriptionData, edit)) {
      return setShowPricingPlanModal(true);
    }

    if (shouldShowPricingPlanModalConfirmAfterBackToFreePlan(subscriptionData, edit)) {
      return setShowPricingPlanModal(true);
    }

    if ((shouldShowPricingPlanModal(subscriptionData, edit) || shouldShowPricingPlanModalForTrial(subscriptionData, edit)) && existingUser) {
      return toast.error("User with this email or mobile already exists");
    }

    if (shouldShowPricingPlanModal(subscriptionData, edit)) {
      return setShowPricingPlanModal(true);
    }
    if (shouldShowPricingPlanModalForTrial(subscriptionData, edit)) {
      return setShowPricingPlanModal(true);
    }

    if (!edit && trialEndTime <= 0 && subscriptionData?.subscription?.subscriptionInfo?.isSubscribed) {
      return setShowPaymentForUserModal(true);
    }

    setIsFormFilled(false);
    let findActiveStore = allStores && allStores.length ? allStores.filter((item) => item.active).map((subItem) => subItem._id) : [];

    if (values?.posPin !== "" && posPin?.toString().length !== 4) {
      toast.error("Enter a 4 Digit Pos Pin");
    } else {
      if (userForEdit && edit) {
        dispatch(
          usersActions.updateUser(
            userForEdit?._id,
            {
              ...values,
              profileImage: image?.length ? image[0]?.data_url : values?.profileImage ? values?.profileImage : "",
              posPin: posPin ? posPin : userForEdit?.posPin,
              storesAccess: [...findActiveStore],
              role: rolePayload._id,
              rolePayload: rolePayload,
            },
            resetForm
          )
        );
        setOpenUsersAddDialog(false);
        history.push("/users");
      } else {
        if (rolePayload._id) {
          dispatch(
            usersActions.createUser({
              ...values,
              profileImage: image?.length ? image[0]?.data_url : values?.profileImage ? values?.profileImage : "",
              posPin: posPin,
              storesAccess: [...findActiveStore],
              role: rolePayload._id,
              rolePayload: rolePayload,
            })
          )
            .then((res) => {
              if (res) {
                dispatch(usersActions.fetchUsers());
                toast.success(i18n("SubscriptionToast.UserCreatedSuccessfully"));
                setRolePayload("");
                resetForm();
                setImage([]);
                setPosPin("");
              }
              if (res.data.status) {
                setOpenUsersAddDialog(false);
                history.push("/users");
                stateEmptyHandler(resetForm);
                if (
                  subscriptionData?.subscription?.currentPlan?.plan === "Free" &&
                  subscriptionData?.subscription?.userTrial?.isTrialStarted === false
                ) {
                  startFreeTrialHandler();
                }
              }
              if (res.data.status && location.pathname === "/users/add" && localStorage.getItem("subscribedClicked", "true")) {
                setContinueSubscriptionModal(true);
              }
              history.push("/users");
            })
            .catch((error) => {
              toast.error(error?.response?.data?.message);
            });
        } else {
          toast.error("Select a Role");
        }
      }
    }
    setUnsavedChanges(false);
  };

  const handleClosePaymentDialog = (dirty) => {
    setPosPin("");
    if (dirty) {
      setShowUnsavedChangesModal(true);
    } else {
      setOpenUsersAddDialog(false);
      history.push("/users");
    }
  };

  const handlePaymentForUser = async (values, resetForm) => {
    let findActiveStore = allStores && allStores.length ? allStores.filter((item) => item.active).map((subItem) => subItem._id) : [];

    if (rolePayload._id) {
      dispatch(
        usersActions.createUser({
          ...values,
          profileImage: image?.length ? image[0]?.data_url : values?.profileImage ? values?.profileImage : "",
          posPin: posPin,
          storesAccess: [...findActiveStore],
          role: rolePayload._id,
          rolePayload: rolePayload,
        })
      )
        .then((res) => {
          if (res) {
            if (subscriptionData.subscription.subscriptionInfo.isSubscribed) {
              dispatch(subscriptionAddUser());
            }
            dispatch(usersActions.fetchUsers());
            toast.success(i18n("SubscriptionToast.UserCreatedSuccessfully"));
            setRolePayload("");
            resetForm();
            setImage([]);
            setPosPin("");
            setShowPaymentForUserModal(false);
            setOpenUsersAddDialog(false);
            history.push("/users");
            dispatch(getSubscription());
          }
        })
        .catch((error) => {
          console.log("Error", error);
        });
    } else {
      toast.error("Select a Role");
    }
    setShowPaymentForUserModal(false);
  };

  const handleSecondaryAction = () => {
    if (!paymentCard) {
      return setShowAddPaymentModal(true);
    }
    if (users && users?.length < 2) {
      setShowAddUserModal(true);
      setContinueSubscriptionModal(false);
      setShowTrialExpiredModal(false);
    } else if (users && users?.length >= 2) {
      setContinueSubscriptionModal(false);
      setShowPricingPlanModal(true);
      localStorage.removeItem("subscribedClicked");
      setShowTrialExpiredModal(false);
    }
  };

  const handleCancel = async () => {
    setShowTrialExpiredModal(false);
  };

  const handleSubscribe = async () => {
    localStorage.setItem("subscribedClicked", "true");
    handleSecondaryAction();
    setShowTrialExpiredModal(false);
  };

  const navigateUser = () => {
    history.push("/users/add");
    setShowAddUserModal(false);
  };

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={
          edit
            ? {
                ...userForEdit,
                firstName: userForEdit?.name?.first,
                lastName: userForEdit?.name?.last,
                role: {
                  label: userForEdit?.rolePayload?.name,
                  value: userForEdit?.role,
                },
              }
            : initValues
        }
        validationSchema={UserSchema}
        onSubmit={(values, { resetForm }) => {
          onSubmitHandler(values, resetForm);
        }}
      >
        {({ handleSubmit, values, setValues, setFieldValue, dirty, resetForm }) => {
          if (values.firstName && values.lastName && values.email && values.mobile && values.role) {
            setIsFormFilled(true);
          }
          return (
            <>
              <Modal
                className={`${language === "arabic" ? "modal-dialog-arabic modal-drawer" : " modal-drawer"}  my-profile invoice-drawer`}
                size="lg"
                aria-labelledby="example-modal-sizes-title-lg"
                show={openUsersAddDialog}
                onHide={() => handleClosePaymentDialog(dirty)}
              >
                <Modal.Header className="d-flex justify-content-evenly" dir={language === "arabic" ? "rtl" : "ltr"}>
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      handleClosePaymentDialog(dirty);
                    }}
                  >
                    <img src={IconClose} alt="loading" />
                  </span>
                  <span
                    style={{
                      fontWeight: "400",
                      fontSize: "18px",
                      color: "#FFFFFF",
                    }}
                  >
                    {edit ? i18n("Company.EditUserProfile") : i18n("Company.AddNewUser")}
                  </span>
                  <span
                    style={{
                      cursor: "pointer",
                      fontWeight: "400",
                      fontSize: "18px",
                      color: isFormFilled && "#FFFFFF",
                      opacity: isFormFilled ? 1 : 0.5,
                    }}
                    onClick={() => {
                      if (subscriptionData && subscriptionData?.subscription?.userTrial?.isTrialStarted === false && edit === false) {
                        if (isFormFilled) {
                          setStartFreeTrialDialog(true);
                        }
                      } else {
                        handleSubmit();
                      }
                    }}
                  >
                    {i18n("UserProfile.Save")}
                  </span>
                </Modal.Header>
                <Modal.Body className={`overlay overlay-block cursor-default ${language === "arabic" ? "rtl" : "ltr"}`}>
                  <Form onChange={handleInputChange}>
                    <Card>
                      <div>
                        <h5
                          className="m-0"
                          style={{
                            textAlign: language === "arabic" ? "right" : "left",
                          }}
                        >
                          <b>{i18n("UserProfile.ProfilePicture")}</b>
                        </h5>
                      </div>
                      <div className="user-avatar">
                        <div className="img-wrap">
                          {image?.length ? (
                            image.map((image, index) => <img key={index} src={image["data_url"]} alt="loading" width="100" />)
                          ) : (
                            <img src={userForEdit?.profileImage ? userForEdit?.profileImage : UserAvatar} alt="loading" />
                          )}
                        </div>
                        <ImageUploading value={image} onChange={onChange} dataURLKey="data_url">
                          {({ imageList, onImageUpload }) => (
                            <button
                              className="btn-edit"
                              onClick={(e) => {
                                e.preventDefault();
                                onImageUpload();
                              }}
                            >
                              <img src={IconEditAvatar} alt="loading" />
                            </button>
                          )}
                        </ImageUploading>
                      </div>
                    </Card>
                    <Card>
                      <div
                        className="invoice-title"
                        style={{
                          borderBottom: "none",
                          direction: language === "arabic" ? "rtl" : "ltr",
                        }}
                      >
                        <span
                          style={{
                            fontWeight: "700",
                            fontSize: "16px",
                            color: "#333333",
                          }}
                        >
                          {i18n("Company.UserInformation")}
                        </span>
                      </div>
                      <div className="form-group">
                        <Field
                          name="firstName"
                          component={Input}
                          placeholder={i18n("global.FirstName")}
                          label={i18n("global.FirstName")}
                          style={{
                            direction: language === "arabic" ? "rtl" : "ltr",
                          }}
                        />
                      </div>
                      <div className="form-group">
                        <Field
                          name="lastName"
                          component={Input}
                          placeholder={i18n("global.LastName")}
                          label={i18n("global.LastName")}
                          style={{
                            direction: language === "arabic" ? "rtl" : "ltr",
                          }}
                        />
                      </div>
                      <div className="form-group">
                        <Field
                          name="email"
                          disabled={edit && userForEdit?.rolePayload?.originalName === "owner"}
                          component={Input}
                          placeholder={i18n("UserProfile.Email")}
                          label={i18n("UserProfile.Email")}
                          style={{
                            direction: language === "arabic" ? "rtl" : "ltr",
                          }}
                        />
                      </div>
                      <div className="form-group">
                        <Field
                          component={Mobile}
                          name="mobile"
                          international
                          defaultCountry="SA"
                          style={{
                            display: "flex",
                            direction: language === "arabic" ? "rtl" : "ltr",
                          }}
                          value={values?.mobile}
                          onChange={(value) => {
                            setValues({
                              ...values,
                              mobile: value,
                            });
                            setUnsavedChanges(true);
                          }}
                          switchIt={true}
                        />
                      </div>
                    </Card>
                    <Card>
                      <div
                        className="invoice-title"
                        style={{
                          borderBottom: "none",
                          direction: language === "arabic" ? "rtl" : "ltr",
                        }}
                      >
                        <h5 className="m-0">
                          <b>{i18n("Company.UserRole")}</b>
                        </h5>
                      </div>
                      <div className="row">
                        <h6 className="col-12">
                          <div className="form-group input-select w-100">
                            <Field
                              name="role"
                              placeholder="Role"
                              component={ReactSelector}
                              isDisabled={disableRole}
                              value={{
                                label:
                                  rolePayload?.name === "cashier"
                                    ? i18n("Company.Cashier")
                                    : rolePayload?.name === "manager"
                                    ? i18n("Company.manager")
                                    : rolePayload?.name === "owner"
                                    ? i18n("Company.Owner")
                                    : rolePayload?.name
                                    ? rolePayload?.name
                                    : i18n("Company.SelectRole"),
                                name: rolePayload?.name ? rolePayload?.name : i18n("Company.SelectRole"),
                              }}
                              options={
                                roles?.length
                                  ? roles
                                      .filter((it) => {
                                        return it.name !== "owner";
                                      })
                                      .map((item) => {
                                        return {
                                          value: item._id,
                                          label:
                                            item?.name === "cashier"
                                              ? i18n("Company.Cashier")
                                              : item?.name === "manager"
                                              ? i18n("Company.manager")
                                              : item?.name,
                                        };
                                      })
                                  : []
                              }
                              onChange={(option) => {
                                setFieldValue("role", option);
                                let data = roles.filter((item) => item._id === option.value);
                                setRolePayload(data[0]);
                              }}
                              styles={{
                                control: (provided, state) => ({
                                  ...provided,
                                  direction: language === "arabic" ? "rtl" : "ltr",
                                  borderColor: state.isFocused ? "transparent" : provided.borderColor,
                                  boxShadow: "none",
                                  "&:hover": {
                                    borderColor: provided.borderColor,
                                  },
                                }),
                                menu: (provided) => ({
                                  ...provided,
                                  direction: language === "arabic" ? "rtl" : "ltr",
                                }),
                                option: (provided) => ({
                                  ...provided,
                                  direction: language === "arabic" ? "rtl" : "ltr",
                                }),
                              }}
                            />
                          </div>
                        </h6>
                        {rolePayload && rolePayload.pos ? (
                          <div
                            className="d-flex justify-content-between w-100"
                            style={{
                              direction: language === "arabic" ? "rtl" : "ltr",
                            }}
                          >
                            <h6 className="ml-4 mr-4 py-4">{i18n("Company.POSPIN")}</h6>
                            <h6 className="ml-4 mr-4 ">
                              <div className="form-group">
                                <PinInput
                                  length={4}
                                  initialValue={edit ? posPin : defaultPosPin}
                                  secret={false}
                                  focus={false}
                                  type="numeric"
                                  style={{ width: "100%" }}
                                  inputStyle={{
                                    width: "30px",
                                    height: "30px",
                                    border: "0",
                                    borderBottom: "1px solid",
                                  }}
                                  onChange={(value) => setPosPin(value)}
                                />
                              </div>
                            </h6>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </Card>
                    {allStores?.length ? (
                      <Card>
                        <h5
                          className="mb-5"
                          style={{
                            textAlign: language === "arabic" ? "right" : "left",
                          }}
                        >
                          <b>{i18n("Company.StoreAccess")}</b>
                        </h5>
                        <div className="stores-overflow">
                          {allStores?.length
                            ? allStores?.map((store, i) => {
                                return (
                                  <div
                                    key={i}
                                    className="d-flex mb-5"
                                    style={{
                                      direction: language === "arabic" ? "rtl" : "ltr",
                                    }}
                                  >
                                    <Field
                                      name={store?.storeId}
                                      component={Checkbox}
                                      isSelected={store.active}
                                      disabled={true && userForEdit?.rolePayload?.originalName === "owner"}
                                      onChange={(e) => {
                                        let newStore;
                                        newStore = {
                                          ...allStores[i],
                                          active: !allStores[i].active,
                                        };
                                        allStores[i] = newStore;
                                        setAllStores([...allStores]);
                                      }}
                                    />
                                    <span className="ml-5 mr-5">{store?.name ? store?.name : store?.storeId}</span>
                                  </div>
                                );
                              })
                            : ""}
                        </div>
                      </Card>
                    ) : null}
                    <Card>
                      <div>
                        <h5
                          className="m-0"
                          style={{
                            textAlign: language === "arabic" ? "end" : "start",
                          }}
                        >
                          <span style={{ fontWeight: "700", fontSize: "16px" }}>{i18n("Company.UserPassword")}</span>
                        </h5>
                        <div
                          className="d-flex justify-content-between"
                          style={{
                            direction: language === "arabic" ? "rtl" : "ltr",
                          }}
                        >
                          <h5 className="mt-5" style={{ color: "#2D9CDB" }}>
                            {i18n("Company.SendPasswordResetEmail")}
                          </h5>
                          <img src={IconLock} alt="icon-lock" />
                        </div>
                      </div>
                    </Card>
                    {!disableRole ? (
                      <Card>
                        <div
                          style={{
                            direction: language === "arabic" ? "rtl" : "ltr",
                          }}
                        >
                          <h5 className="m-0">
                            <b>{i18n("Company.UserAccount")}</b>
                          </h5>
                          <div className="d-flex justify-content-between mt-5">
                            <h5>{i18n("MENU.Active")}</h5>
                            <div className="user-active">
                              <SwitchToggler
                                className="default-switch"
                                id="active"
                                name="isActive"
                                checked={values?.isActive || false}
                                onChange={(e) => {
                                  setFieldValue("isActive", e.target.checked);
                                }}
                              />
                            </div>
                          </div>
                          {userForEdit && edit ? (
                            <div
                              className="d-flex justify-content-between mt-5"
                              style={{
                                direction: language === "arabic" ? "rtl" : "ltr",
                              }}
                            >
                              <h5 style={{ color: "#EB5757" }}>{i18n("SubscriptionModals.DeleteAccount")}</h5>
                              <div className="mr-5" onClick={() => setShowDeleteUserModal(true)}>
                                <img title="Delete" style={{ cursor: "pointer" }} src={IconTrash} alt="loading" />
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </Card>
                    ) : null}
                  </Form>
                </Modal.Body>
              </Modal>
              {/* Free Trial Started Modal */}
              <SubscriptionDialog
                show={startFreeTrialDialog}
                onHide={() => setStartFreeTrialDialog(false)}
                modalWidth="350px"
                modalHeight="250px"
                title={i18n("SubscriptionModals.TryUsersManagementForFree")}
                titleWidth="248px"
                titleHeight="20px"
                titleColor="#333333"
                titleFontWeight="700"
                titleFontSize="16px"
                titleLineHeight="20px"
                message={i18n("SubscriptionModals.StartYour30DayFreeTrialWithNoCreditCardRequired")}
                messageWidth="312px"
                messageHeight="88px"
                messageColor="#333333"
                messageFontWeight="500"
                messageFontSize="16px"
                messageLineHeight="21.79px"
                primaryAction={() => setStartFreeTrialDialog(false)}
                primaryLabel={i18n("SubscriptionModals.CANCEL")}
                primaryLabelWidth="55px"
                primaryLabelHeight="20px"
                primaryLabelFontWeight="700"
                primaryLabelFontSize="14px"
                primaryLabelLineHeight="20px"
                primaryLabelColor="#828282"
                secondaryAction={() => {
                  setStartFreeTrialDialog(false);
                  handleSubmit();
                }}
                secondaryLabel={i18n("SubscriptionModals.STARTFREETRIAL")}
                secondaryLabelWidth="123px"
                secondaryLabelHeight="30px"
                secondaryLabelFontWeight="700"
                secondaryLabelFontSize="14px"
                secondaryLabelLineHeight="29.57px"
                secondaryLabelColor="#2D9CDB"
              />
              {/* un-saved changes modal */}
              <SubscriptionDialog
                modalWidth={"350px"}
                modalHeight={"185px"}
                modalBackgroundColor={"#FFFFFF"}
                show={showUnsavedChangesModal}
                onHide={() => setShowUnsavedChangesModal(false)}
                title={i18n("SubscriptionModals.UnsavedChanges")}
                titleWidth={"140px"}
                titleHeight={"20px"}
                titleFontWeight={"700"}
                titleFontSize={"16px"}
                titleLineHeight={"20px"}
                titleColor={"#333333"}
                message={i18n("SubscriptionModals.AreYouSureYouWantToContinueWithoutSavingChanges")}
                messageWidth={"320px"}
                messageHeight={"44px"}
                messageFontSize={"16px"}
                messageFontWeight={"400"}
                messageLineHeight={"21.79px"}
                messageColor={"#333333"}
                primaryLabel={i18n("SubscriptionModals.CANCEL")}
                primaryAction={() => setShowUnsavedChangesModal(false)}
                primaryLabelWidth={"55px"}
                primaryLabelHeight={"20px"}
                primaryLabelFontWeight={"700"}
                primaryLabelFontSize={"14px"}
                primaryLabelLineHeight={"20px"}
                primaryLabelColor={"#828282"}
                secondaryAction={() => {
                  setShowUnsavedChangesModal(false);
                  setOpenUsersAddDialog(false);
                  history.push("/users");
                  setIsFormFilled(false);
                  resetForm();
                  setRolePayload("");
                }}
                secondaryLabel={i18n("SubscriptionModals.CONTINUE")}
                secondaryLabelWidth={"75px"}
                secondaryLabelHeight={"20px"}
                secondaryLabelFontWeight={"700"}
                secondaryLabelFontSize={"14px"}
                secondaryLabelLineHeight={"20px"}
                secondaryLabelColor={"#2D9CDB"}
              />

              {/* payment for user managment */}
              <SubscriptionDialog
                show={showPaymentForUserModal}
                onHide={() => setShowPaymentForUserModal(false)}
                modalWidth="400px"
                modalHeight="220px"
                title={i18n("SubscriptionModals.PaymentForUserManagement")}
                titleWidth="287px"
                titleHeight="29px"
                titleColor="#333333"
                titleFontWeight="700"
                titleFontSize="18px"
                titleLineHeight="28.57px"
                message={i18n("SubscriptionModals.YouWillBeChargedProratedAmountRestBillingCycleThisUserAccount")}
                messageWidth="359px"
                messageHeight="40px"
                messageColor="#333333"
                messageFontWeight="400"
                messageFontSize="14px"
                messageLineHeight="22px"
                primaryAction={() => setShowPaymentForUserModal(false)}
                primaryLabel={i18n("SubscriptionModals.CANCEL")}
                primaryLabelWidth="63px"
                primaryLabelHeight="16px"
                primaryLabelFontWeight="600"
                primaryLabelFontSize="16px"
                primaryLabelLineHeight="15.92px"
                primaryLabelColor="#828282"
                secondaryAction={() => handlePaymentForUser(values, resetForm)}
                secondaryLabel={i18n("SubscriptionModals.PAY")}
                secondaryLabelWidth="30px"
                secondaryLabelHeight="16px"
                secondaryLabelFontWeight="600"
                secondaryLabelFontSize="16px"
                secondaryLabelLineHeight="15.92px"
                secondaryLabelColor="#2D9CDB"
                payementCardBrand={paymentCard?.brand}
                paymentCardLastFourDigit={paymentCard?.last_four}
              />
              <PricingPlanDialog
                showModal={showPricingPlanModal}
                onHide={() => setShowPricingPlanModal(false)}
                handleCreateUser={
                  subscriptionData?.subscription?.subscriptionInfo?.isUnsubscribed ||
                  shouldShowPricingPlanModalAfterBackToFreePlan(subscriptionData) ||
                  shouldShowPricingPlanModalConfirmAfterBackToFreePlan(subscriptionData)
                    ? () => handlePaymentForUser(values, resetForm)
                    : null
                }
              />
            </>
          );
        }}
      </Formik>

      {/* continue subscription modal */}
      <SubscriptionDialog
        show={showContinueSubscriptionModal}
        onHide={() => setContinueSubscriptionModal(false)}
        modalWidth="300px"
        modalHeight="225px"
        title={i18n("SubscriptionModals.ContinueWithSubscription")}
        titleWidth="244px"
        titleHeight="29px"
        titleColor="#333333"
        titleFontWeight="700"
        titleFontSize="16px"
        titleLineHeight="20px"
        message={i18n("SubscriptionModals.UserWasCreatedSuccessfullyYouCanNowContinueWithYourSubscription")}
        messageWidth="260px"
        messageHeight="66px"
        messageColor="#333333"
        messageFontWeight="500"
        messageFontSize="16px"
        messageLineHeight="22px"
        primaryAction={() => setContinueSubscriptionModal(false)}
        primaryLabel={i18n("SubscriptionModals.CANCEL")}
        primaryLabelWidth="63px"
        primaryLabelHeight="16px"
        primaryLabelFontWeight="600"
        primaryLabelFontSize="16px"
        primaryLabelLineHeight="15.92px"
        primaryLabelColor="#828282"
        secondaryAction={() => history.push("/subscription/new")}
        secondaryLabel={i18n("SubscriptionModals.CONTINUE")}
        secondaryLabelWidth="86px"
        secondaryLabelHeight="16px"
        secondaryLabelFontWeight="600"
        secondaryLabelFontSize="16px"
        secondaryLabelLineHeight="20px"
        secondaryLabelColor="#2D9CDB"
      />

      {/* delete user modal  */}
      <SubscriptionDialog
        show={showDeleteUserModal}
        onHide={() => setShowDeleteUserModal(false)}
        modalWidth="350px"
        modalHeight="185px"
        title={i18n("SubscriptionModals.UserRemoval")}
        titleWidth="115px"
        titleHeight="20px"
        titleColor="#333333"
        titleFontWeight="700"
        titleFontSize="16px"
        titleLineHeight="20px"
        message={i18n("SubscriptionModals.AreYouSureYouWantToDeleteThisUser")}
        messageWidth="320px"
        messageHeight="44px"
        messageColor="#333333"
        messageFontWeight="400"
        messageFontSize="16px"
        messageLineHeight="21.79px"
        primaryAction={() => setShowDeleteUserModal(false)}
        primaryLabel={i18n("SubscriptionModals.CANCEL")}
        primaryLabelWidth="55px"
        primaryLabelHeight="20px"
        primaryLabelFontWeight="700"
        primaryLabelFontSize="14px"
        primaryLabelLineHeight="20px"
        primaryLabelColor="#828282"
        secondaryAction={deleteUserHandler}
        secondaryLabel={i18n("SubscriptionModals.CONFIRM")}
        secondaryLabelWidth="67px"
        secondaryLabelHeight="20px"
        secondaryLabelFontWeight="700"
        secondaryLabelFontSize="14px"
        secondaryLabelLineHeight="20px"
        secondaryLabelColor="#EB5757"
      />

      {/* trial expired modal */}
      <SubscriptionDialog
        modalWidth={"350px"}
        title={i18n("SubscriptionModals.TrialExpired")}
        titleWidth={"100px"}
        titleHeight={"20px"}
        titleColor={"#333333"}
        titleFontWeight={700}
        titleFontSize={"14px"}
        titleLineHeight={"20px"}
        modalHeight={"150px"}
        modalBackgroundColor={"#FFFFFF"}
        show={showTrialExpiredModal}
        onHide={handleCancel}
        message={i18n("SubscriptionModals.YourUserManagementTrialHasExpiredSubscribeNowToContinueUsingIt")}
        messageHeight={"44px"}
        messageFontSize={"16px"}
        messageFontWeight={"500"}
        messageLineHeight={"21.79px"}
        messageColor={"#333333"}
        primaryLabel={i18n("SubscriptionModals.CANCEL")}
        primaryAction={handleCancel}
        primaryLabelWidth={"55px"}
        primaryLabelHeight={"30px"}
        primaryLabelFontWeight={"700"}
        primaryLabelFontSize={"14px"}
        primaryLabelLineHeight={"29.57px"}
        primaryLabelColor={"#828282"}
        secondaryAction={handleSubscribe}
        secondaryLabel={i18n("global.SUBSCRIBE")}
        secondaryLabelWidth={"70px"}
        secondaryLabelHeight={"30px"}
        secondaryLabelFontWeight={"700"}
        secondaryLabelFontSize={"14px"}
        secondaryLabelLineHeight={"29.57px"}
        secondaryLabelColor={"#2D9CDB"}
        isTrialExpired={true}
      />

      {/* add payment modal */}
      <SubscriptionDialog
        modalWidth={"300px"}
        modalHeight={"150px"}
        modalBackgroundColor={"#FFFFFF"}
        show={showAddPaymentModal}
        onHide={() => setShowAddPaymentModal(false)}
        message={
          shouldShowPricingPlanModalAfterBackToFreePlan(subscriptionData)
            ? i18n("SubscriptionModals.YouNeedToSubscribeBeforeYouCanAddUser")
            : i18n("SubscriptionModals.YouNeedToAddPaymentCardBeforeYouCanSubscribe")
        }
        messageWidth={"260px"}
        messageHeight={"48px"}
        messageFontSize={"16px"}
        messageFontWeight={"500"}
        messageLineHeight={"21.79px"}
        messageColor={"#333333"}
        primaryLabel={i18n("SubscriptionModals.CANCEL")}
        primaryAction={() => setShowAddPaymentModal(false)}
        primaryLabelWidth={"55px"}
        primaryLabelHeight={"30px"}
        primaryLabelFontWeight={"700"}
        primaryLabelFontSize={"14px"}
        primaryLabelLineHeight={"29.57px"}
        primaryLabelColor={"#828282"}
        secondaryAction={() => {
          shouldShowPricingPlanModalAfterBackToFreePlan(subscriptionData) && localStorage.setItem("subscribedClicked", "true");
          history.push("/subscription/add-payment-card");
          setOpenUsersAddDialog(true);
          history.push("/users");
          setShowAddPaymentModal(false);
        }}
        secondaryLabel={i18n("Company.ADDCARD")}
        secondaryLabelHeight={"30px"}
        secondaryLabelFontWeight={"700"}
        secondaryLabelFontSize={"14px"}
        secondaryLabelLineHeight={"29.57px"}
        secondaryLabelColor={"#2D9CDB"}
      />

      {/* add user modal */}
      <SubscriptionDialog
        modalWidth={"300px"}
        modalHeight={"150px"}
        modalBackgroundColor={"#FFFFFF"}
        show={showAddUserModal}
        onHide={() => setShowAddUserModal(false)}
        message={
          subscriptionData?.subscription?.userTrial.isTrialStarted && subscriptionData?.subscription?.userTrial.isTrialExpired
            ? i18n("SubscriptionModals.YouNeedToCreateNewUserBeforeStartingSubscription")
            : i18n("SubscriptionModals.YouNeedToCreateNewUserBeforeStartingYourFreeTrial")
        }
        messageWidth={"262px"}
        messageHeight={"44px"}
        messageFontSize={"16px"}
        messageFontWeight={"500"}
        messageLineHeight={"21.79px"}
        messageColor={"#333333"}
        primaryLabel={i18n("SubscriptionModals.CANCEL")}
        primaryAction={() => setShowAddUserModal(false)}
        primaryLabelWidth={"55px"}
        primaryLabelHeight={"30px"}
        primaryLabelFontWeight={"700"}
        primaryLabelFontSize={"14px"}
        primaryLabelLineHeight={"29.57px"}
        primaryLabelColor={"#828282"}
        secondaryAction={navigateUser}
        secondaryLabel={i18n("SubscriptionModals.ADDUSER")}
        secondaryLabelWidth={"70px"}
        secondaryLabelHeight={"30px"}
        secondaryLabelFontWeight={"700"}
        secondaryLabelFontSize={"14px"}
        secondaryLabelLineHeight={"29.57px"}
        secondaryLabelColor={"#2D9CDB"}
      />
    </>
  );
};
