import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { fetchUsers } from "../../../../_redux/users/usersActions";
import { changePlan, getSubscription, updateSubscription } from "../../../../_redux/subscription/subscriptionService";
import { useHistory } from "react-router-dom";
import { i18n } from "../../private/languageSelector";
import moment from "moment/moment";
import { getSubscriptionInvoices } from "../../../../_redux/subscriptionInvoices/SubscriptionInvoicesService";
import {
  formatNumber,
  isFreePlanRunningAndTrialExpired,
  shouldRedirectToAddUser,
  shouldShowPricingPlanModalAfterBackToFreePlan,
  shouldShowPricingPlanModalConfirmAfterBackToFreePlan,
} from "../../../../helpers/constants";
import { getModalWidth } from "../../../../helpers/helpers";

const InvoicePreviewDialog = ({ showModal, onHide, selectedPlan, setSelectedPlan, handleCreateUser }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { language } = useSelector((state) => state.auth);
  const { subscriptionData } = useSelector((state) => state.subscription);
  const { subscriptionUsers } = useSelector((state) => state.subscription);
  const { changePlanLoading } = useSelector((state) => state.subscription);
  const [modalWidth, setModalWidth] = useState(getModalWidth());

  useEffect(() => {
    dispatch(fetchUsers());
  }, [dispatch]);

  let numberOfUsers = subscriptionUsers ? subscriptionUsers.length : 0;

  if (subscriptionData?.subscription?.subscriptionInfo?.isUnsubscribed) {
    numberOfUsers = 1;
  }

  if (isFreePlanRunningAndTrialExpired(subscriptionData)) {
    numberOfUsers = 1;
  }

  if (shouldRedirectToAddUser(subscriptionData)) {
    numberOfUsers = 1;
  }

  let baseAmount = selectedPlan === "Monthly" ? 19.0 * numberOfUsers : 190.0 * numberOfUsers;

  if (subscriptionData?.subscription?.subscriptionInfo?.isUnsubscribed) {
    baseAmount = selectedPlan === "Monthly" ? 19.0 : 190.0;
  }

  if (isFreePlanRunningAndTrialExpired(subscriptionData)) {
    baseAmount = selectedPlan === "Monthly" ? 19.0 : 190.0;
  }

  if (shouldRedirectToAddUser(subscriptionData)) {
    baseAmount = selectedPlan === "Monthly" ? 19.0 : 190.0;
  }

  const credit = parseFloat(subscriptionData?.subscription?.credit) || 0;
  const creditNew = calculateNewCredit(subscriptionData);

  const appliedBalance = Math.min(baseAmount, credit + creditNew);

  const TotalBeforeTax = baseAmount - appliedBalance;

  const tax = TotalBeforeTax * 0.15;

  const totalAmount = TotalBeforeTax + tax;
  const amountDue = TotalBeforeTax + tax;

  function calculateNewCredit(subscriptionData) {
    const subscriptionTimeDiff = moment(subscriptionData?.subscription?.subscriptionEndedAt).diff(new Date(), "hours");

    const planCharges = calculateUserPrice(subscriptionData);

    const planChargesHours = subscriptionData?.subscription?.currentPlan.plan === "Monthly" ? 30.44 * 24 : 12 * 30.44 * 24;

    return (subscriptionTimeDiff * planCharges * subscriptionData?.subscription?.currentPlan?.seats) / planChargesHours;
  }

  function calculateUserPrice(subscriptionData) {
    return subscriptionData?.subscription?.currentPlan.plan === "Annual" ? 190 : 19;
  }

  const subscriptionInTrialData = {
    currentPlan: {
      plan: selectedPlan,
    },
  };

  const changePlanData = {
    currentPlan: {
      plan: selectedPlan,
    },
  };

  const handleSubscription = async () => {
    history.push("/subscription");
    localStorage.removeItem("subscribedClicked");

    try {
      if (shouldShowPricingPlanModalConfirmAfterBackToFreePlan(subscriptionData)) {
        if (handleCreateUser) {
          await handleCreateUser();
        }
        await new Promise((resolve) => setTimeout(resolve, 2000));
        await dispatch(updateSubscription({ updatedData: subscriptionInTrialData }));
      } else if (shouldShowPricingPlanModalAfterBackToFreePlan(subscriptionData)) {
        if (handleCreateUser) {
          await handleCreateUser();
        }
        await new Promise((resolve) => setTimeout(resolve, 2000));
        await dispatch(updateSubscription({ updatedData: subscriptionInTrialData }));
      } else if (
        subscriptionData?.subscription?.currentPlan?.plan === "Trial" &&
        (subscriptionData?.subscription?.currentPlan?.status === "Running" || subscriptionData?.subscription?.currentPlan?.status === "Expired")
      ) {
        if (handleCreateUser) {
          await handleCreateUser();
        }
        await new Promise((resolve) => setTimeout(resolve, 2000));
        await dispatch(updateSubscription({ updatedData: subscriptionInTrialData }));
      } else if (subscriptionData?.subscription?.currentPlan?.plan === "Monthly" || subscriptionData?.subscription?.currentPlan?.plan === "Annual") {
        await dispatch(changePlan({ updatedData: changePlanData }));
      }
      await dispatch(getSubscription());
      await dispatch(getSubscriptionInvoices({ page: 1, limit: 10 }));
      onHide();
    } catch (error) {
      console.error("Error during subscription process:", error);
    }
  };

  const formattedTrialStartedAt = moment(subscriptionInTrialData?.subscription?.trialStartedAt).format("MMM D, YYYY");
  const formattedTrialEndedAt = moment(subscriptionInTrialData?.subscription?.trialEndedAt).format("MMM D, YYYY");

  const handleCloseInvoicePreviewModal = () => {
    onHide();
    setSelectedPlan(subscriptionData?.subscription?.currentPlan?.plan);
  };

  const baseAmountFormatted = formatNumber(baseAmount);
  const taxFormatted = formatNumber(appliedBalance >= baseAmount ? 0.0 : tax);
  const totalAmountFormatted = formatNumber(totalAmount);
  const latestCreditFormatted = formatNumber(appliedBalance);
  const TotalBeforeTaxFormatted = formatNumber(TotalBeforeTax);
  const amountDueFormatted = formatNumber(appliedBalance >= baseAmount ? 0.0 : amountDue);

  useEffect(() => {
    const updateModalWidth = () => {
      setModalWidth(getModalWidth());
    };

    // Set the initial width
    updateModalWidth();

    // Add event listener for window resize
    window.addEventListener("resize", updateModalWidth);

    // Cleanup the event listener on component unmount
    return () => window.removeEventListener("resize", updateModalWidth);
  }, []);

  return (
    <Modal
      dir={language === "arabic" ? "rtl" : "ltr"}
      isOpen={showModal}
      toggle={handleCloseInvoicePreviewModal}
      className="plan-dialog modal-main mx-auto"
      centered
      style={{
        width: modalWidth,
        height: "450px",
        backgroundColor: "background: #FFFFFF",
      }}
    >
      <ModalHeader
        style={{
          borderRadius: "20px",
          backgroundColor: "white",
          color: "black",
          flexDirection: "column",
          alignItems: "flex-start",
          padding: "20px",
          borderBottom: "none",
        }}
      >
        <p style={{ fontWeight: "700", fontSize: "18px", color: "#333333" }} className="d-flex justify-content-start">
          {i18n("SubscriptionModals.InvoicePreview")}
        </p>
        <p style={{ fontWeight: "400", fontSize: "14px", color: "#828282", textAlign: "start" }} className="d-flex justify-content-start">
          {subscriptionData?.subscription?.userTrial?.isTrialExpired ? (
            <span>{i18n("SubscriptionModals.SummaryChargesThatWillBeAppliedUponConfirmation")}</span>
          ) : (
            <span>{i18n("SubscriptionModals.SummaryFeesThatWillAppliedAtTheEndFreeTrial")}</span>
          )}
        </p>
        <div
          className="d-flex justify-content-between w-100"
          style={{ borderBottom: "1px solid #E0E0E0", paddingTop: "10px", alignItems: "flex-end" }}
        >
          <span
            style={{
              color: "#828282",
              fontWeight: "400",
              fontSize: "14px",
              marginBottom: "10px",
            }}
          >
            {i18n("global.Description")}
          </span>
          <span
            style={{
              color: "#828282",
              fontWeight: "400",
              fontSize: "14px",
              marginBottom: "10px",
            }}
          >
            {i18n("SubscriptionModals.Amount")}
          </span>
        </div>
      </ModalHeader>
      <ModalBody style={{ padding: "0 20px", marginTop: "-1rem" }}>
        <div
          className="d-flex justify-content-between"
          style={{
            padding: "10px 0px",
            alignItems: "flex-end",
          }}
        >
          <div
            style={{
              color: "#333333",
              fontWeight: "400",
              fontSize: "15px",
              marginBottom: "5px",
              textAlign: language ? "start" : "start",
            }}
          >
            {i18n("SubscriptionModals.UserManagement")}
            {formattedTrialStartedAt} - {formattedTrialEndedAt}
            <br />
            {language ? (
              <span
                style={{
                  fontWeight: "400",
                  fontSize: "14px",
                  color: "#828282",
                }}
              >
                <span className="mr-2">
                  {numberOfUsers} {i18n("SubscriptionModals.Users")}
                </span>
                <span className="mr-2">{selectedPlan === "Monthly" ? 19 : 190}.00</span>
                <span className="mr-2">x {i18n("SubscriptionModals.SAR")}</span>
              </span>
            ) : (
              <span
                style={{
                  fontWeight: "400",
                  fontSize: "14px",
                  color: "#828282",
                }}
              >
                {numberOfUsers} {i18n("SubscriptionModals.Users")} x {i18n("SubscriptionModals.SAR")} {selectedPlan === "Monthly" ? 19 : 190}.00
              </span>
            )}
          </div>
          <div
            style={{
              color: "#333333",
              fontWeight: "400",
              fontSize: "15px",
            }}
          >
            {language ? (
              <span>
                {baseAmountFormatted} {i18n("SubscriptionModals.SAR")}
              </span>
            ) : (
              <span>
                {i18n("SubscriptionModals.SAR")} {baseAmountFormatted}
              </span>
            )}
          </div>
        </div>
        <div className="d-flex flex-column align-items-end" style={{ borderTop: "1px solid #E0E0E0" }}>
          {appliedBalance > 0 && (
            <div className="d-flex justify-content-between align-items-center" style={{ padding: "10px 0", width: "281px" }}>
              <span
                style={{
                  color: "#333333",
                  fontWeight: "700",
                  fontSize: "16px",
                }}
                className="d-flex justify-content-end align-items-end mr-12"
              >
                {i18n("SubscriptionModals.Subtotal")}
              </span>
              {language === "arabic" ? (
                <span
                  style={{
                    color: "#333333",
                    fontWeight: "700",
                    fontSize: "16px",
                  }}
                  className="d-flex justify-content-end align-items-end"
                >
                  {baseAmountFormatted} {i18n("SubscriptionModals.SAR")}
                </span>
              ) : (
                <span
                  style={{
                    color: "#333333",
                    fontWeight: "700",
                    fontSize: "16px",
                  }}
                  className="d-flex justify-content-end align-items-end"
                >
                  {i18n("SubscriptionModals.SAR")} {baseAmountFormatted}
                </span>
              )}
            </div>
          )}
          {appliedBalance > 0 && (
            <div className="d-flex justify-content-between align-items-center" style={{ width: "280px" }}>
              <span
                style={{
                  color: "#333333",
                  fontWeight: "400",
                  fontSize: "16px",
                }}
                className="mr-12 d-flex justify-content-end align-items-end"
              >
                {i18n("SubscriptionModals.Credit")}
              </span>
              {language === "arabic" ? (
                <span
                  style={{
                    color: "#333333",
                    fontWeight: "400",
                    fontSize: "16px",
                  }}
                  className="d-flex justify-content-end align-items-end"
                >
                  ({latestCreditFormatted} {i18n("SubscriptionModals.SAR")})
                </span>
              ) : (
                <span
                  style={{
                    color: "#333333",
                    fontWeight: "400",
                    fontSize: "16px",
                  }}
                  className="d-flex justify-content-end align-items-end"
                >
                  ({i18n("SubscriptionModals.SAR")} {latestCreditFormatted})
                </span>
              )}
            </div>
          )}
          <div className="d-flex justify-content-between align-items-center" style={{ padding: "10px 0", width: "281px" }}>
            <span style={{ color: "#333333", fontWeight: "700", fontSize: "16px" }} className="d-flex justify-content-end align-items-end mr-12">
              {i18n("SubscriptionModals.TotalBeforeTax")}
            </span>
            {language === "arabic" ? (
              <span
                style={{
                  color: "#333333",
                  fontWeight: "700",
                  fontSize: "16px",
                }}
                className="d-flex justify-content-end align-items-end"
              >
                {TotalBeforeTaxFormatted} {i18n("SubscriptionModals.SAR")}
              </span>
            ) : (
              <span
                style={{
                  color: "#333333",
                  fontWeight: "700",
                  fontSize: "16px",
                }}
                className="d-flex justify-content-end align-items-end"
              >
                {i18n("SubscriptionModals.SAR")} {TotalBeforeTaxFormatted}
              </span>
            )}
          </div>

          <div
            className="d-flex justify-content-between align-items-center"
            style={{
              padding: "10px 0",
              width: "281px",
              borderBottom: "1px solid #E0E0E0",
            }}
          >
            <span
              style={{
                color: "#333333",
                fontWeight: "400",
                fontSize: "16px",
              }}
              className="d-flex justify-content-end align-items-end mr-12"
            >
              {i18n("SubscriptionModals.VAT15")}
            </span>
            {language ? (
              <span
                style={{
                  color: "#333333",
                  fontWeight: "400",
                  fontSize: "16px",
                }}
                className="d-flex justify-content-end align-items-end"
              >
                {taxFormatted} {i18n("SubscriptionModals.SAR")}
              </span>
            ) : (
              <span
                style={{
                  color: "#333333",
                  fontWeight: "400",
                  fontSize: "16px",
                }}
                className="d-flex justify-content-end align-items-end"
              >
                {i18n("SubscriptionModals.SAR")} {taxFormatted}
              </span>
            )}
          </div>
          <div className="d-flex justify-content-between align-items-center" style={{ padding: "10px 0", width: "281px" }}>
            <span style={{ color: "#333333", fontWeight: "700", fontSize: "16px" }} className="d-flex justify-content-end align-items-end mr-12">
              {i18n("SubscriptionModals.AmountDue")}
            </span>
            {language ? (
              <span
                style={{
                  color: "#333333",
                  fontWeight: "700",
                  fontSize: "16px",
                }}
                className="d-flex justify-content-end align-items-end"
              >
                {amountDueFormatted} {i18n("SubscriptionModals.SAR")}
              </span>
            ) : (
              <span
                style={{
                  color: "#333333",
                  fontWeight: "700",
                  fontSize: "16px",
                }}
                className="d-flex justify-content-end align-items-end"
              >
                {i18n("SubscriptionModals.SAR")} {amountDueFormatted}
              </span>
            )}
          </div>
        </div>
        {!subscriptionData?.subscription?.userTrial.isTrialCancelled && !subscriptionData?.subscription?.userTrial.isTrialExpired && (
          <p
            style={{
              fontWeight: "400",
              fontSize: "14px",
              color: "#828282",
              marginTop: "10px",
              textAlign: language === "arabic" ? "right" : "left",
            }}
          >
            {language ? (
              <>
                {i18n("SubscriptionModals.TheFreeTrialEndsOn")} {formattedTrialEndedAt} {i18n("SubscriptionModals.FollowedBySAR")}{" "}
                {totalAmountFormatted} {i18n("SubscriptionModals.MonthPerUser")}{" "}
                {i18n("SubscriptionModals.CancelAnytimeAutoChargedEachCycleUntilCanceled")}
              </>
            ) : (
              <>
                {i18n("SubscriptionModals.TheFreeTrialEndsOn")} {formattedTrialEndedAt}, {i18n("SubscriptionModals.FollowedBySAR")} SAR{" "}
                {totalAmountFormatted}
                {i18n("SubscriptionModals.MonthPerUser")} {i18n("SubscriptionModals.CancelAnytimeAutoChargedEachCycleUntilCanceled")}
              </>
            )}
          </p>
        )}
        {subscriptionData?.subscription?.userTrial.isTrialStarted &&
          subscriptionData?.subscription?.userTrial.isTrialExpired &&
          subscriptionData?.subscription?.subscriptionInfo.isSubscribed === false && (
            <p
              style={{
                fontWeight: "400",
                fontSize: "14px",
                color: "#828282",
                marginTop: "10px",
                textAlign: language === "arabic" ? "right" : "left",
              }}
            >
              {i18n("SubscriptionModals.YouWillBeChargedAutomaticallyStartEachBillingPeriodUntilYouCancel")}
            </p>
          )}
      </ModalBody>
      <ModalFooter style={{ padding: "10px 20px", justifyContent: "space-around" }}>
        <p
          onClick={handleCloseInvoicePreviewModal}
          style={{
            fontWeight: "600",
            fontSize: "16px",
            color: "#828282",
            cursor: "pointer",
            margin: "0 20px",
          }}
        >
          {i18n("SubscriptionModals.CANCEL")}
        </p>
        <p
          onClick={handleSubscription}
          style={{
            fontWeight: "600",
            fontSize: "16px",
            color: "#2D9CDB",
            cursor: "pointer",
            margin: "0 20px",
            pointerEvents: changePlanLoading && "none",
          }}
        >
          {i18n("SubscriptionModals.CONFIRM")}
        </p>
      </ModalFooter>
    </Modal>
  );
};

export default InvoicePreviewDialog;
