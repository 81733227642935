// import dropdown from "../../assets/images/lang-dropdown.svg";
import React, { useState, useEffect, useRef } from "react";
import { Storage } from "react-jhipster";
import dropdown from "../../../assets/img/icon-dropdown.svg";

function SelectLanguage({ changeLanguage }) {
  const [selectedLang, setSelectedLang] = useState("English");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const LANGUAGE = "language";
  useEffect(() => {
    const storedLang = Storage.local.get(LANGUAGE);
    if (storedLang === "english") {
      setSelectedLang("English");
    } else if (storedLang === "arabic") {
      setSelectedLang("العربية");
    }
  }, []);

  const languageChangeHandler = (language) => {
    if (language) {
      const refactoredLanguage = language === "english" ? "english" : "arabic";
      localStorage.setItem(LANGUAGE, refactoredLanguage);
      window.location.reload();
    }
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    if (isDropdownOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isDropdownOpen]);

  return (
    <div ref={dropdownRef} style={{ position: "relative" }}>
      <div
        onClick={toggleDropdown}
        className={`${
          selectedLang === "English" ? "min-w-[120px]" : "min-w-[120px]"
        } flex items-center justify-end gap-3 text-sm text-gray-800 cursor-pointer`}
      >
        <span>{selectedLang}</span>

        <img src={dropdown} alt="dropdown-icon" className="mx-2 cursor-pointer" onClick={toggleDropdown} />
      </div>

      {isDropdownOpen && (
        <div
          className=" mt-2 bg-white  select-language"
          style={{ position: "absolute", right: selectedLang === "English" && 0, left: selectedLang === "العربية" && 0 }}
        >
          <ul>
            <li
              onClick={() => {
                languageChangeHandler("english");
                setIsDropdownOpen(false);
              }}
              style={{ position: "absolute", background: selectedLang === "English" ? "#F2F2F2" : "white", right: 0, width: "100px", top: "-2.5rem" }}
              className={`${selectedLang === "English" ? "pl-1 text-left" : "pr-1 text-right"} cursor-pointer  boder-radius-top py-2 text-sm  `}
            >
              English
            </li>
            <li
              onClick={() => {
                languageChangeHandler("العربية");
                setIsDropdownOpen(false);
              }}
              style={{ position: "absolute", background: selectedLang === "العربية" ? "#F2F2F2" : "white", right: 0, width: "100px" }}
              className={`${selectedLang === "العربية" ? "pr-1 text-right" : "pl-1 text-left"} cursor-pointer boder-radius-bottom  py-2 text-sm `}
            >
              العربية
            </li>
          </ul>
        </div>
      )}
    </div>
  );
}

export default SelectLanguage;
