import { createSlice } from "@reduxjs/toolkit";

const initialStoreState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  storeForEdit: undefined,
  error: null,
  navigate: false,
  showLayout: true,
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const storesSlice = createSlice({
  name: "store",
  initialState: initialStoreState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      state.navigate = false;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    storeFetched: (state, action) => {
      state.actionsLoading = false;
      state.storeForEdit = {
        ...action.payload.storeForEdit,
      };
      state.error = null;
    },
    storesFetched: (state, action) => {
      const { entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
    },
    storeCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.navigate = true;
    },
    storeUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.navigate = true;
    },

    storeDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
    },
    showLayout: (state, action) => {
      state.showLayout = action.payload;
    },
  },
});
export const { showLayout } = storesSlice.actions;
