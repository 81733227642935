import React, { useState, useEffect } from "react";
import "./analytics.css";
import SideCard from "../../../components/Card/SideCard";
import InfoCard from "../../../components/Card/InfoCard";
import Table from "../../../components/Table/Table";
import { useDispatch, useSelector } from "react-redux";
import { getTableData } from "../../../../_redux/analytics/analyticsService";
import moment from "moment/moment";
import PageNavigator from "../../../components/pagination/PageNavigator";
import { exportTableToPDF } from "../../../../utils/pdfExportUtil";
import { i18n } from "../../private/languageSelector";
import FiltersDialog from "../filters-dialog/FiltersDialog";

const TaxReport = () => {
  const dispatch = useDispatch();
  const { analyticsTableData, openFiltersDialog } = useSelector((state) => state.analytics);
  const [allStores, setAllStores] = useState("all");
  const [allUsers, setAllUsers] = useState("all");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [tableType, setTableType] = useState("salesByTax");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [sortBy, setSortBy] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [timeZone, setTimeZone] = useState("Asia/Riyadh");

  useEffect(() => {
    setTimeZone(Intl.DateTimeFormat().resolvedOptions().timeZone);
  }, []);

  let startingDate = localStorage.getItem("startDate");
  let endingDate = localStorage.getItem("endDate");

  useEffect(() => {
    const fetchData = async () => {
      const formattedStartDate = startDate ? moment(new Date(startDate)).format("M-DD-yy") : "";
      const formattedEndDate = endDate ? moment(new Date(endDate)).format("M-DD-yy") : "";
      await dispatch(
        getTableData({
          tableType,
          page,
          limit,
          allStores,
          allUsers,
          startDate: formattedStartDate,
          endDate: formattedEndDate,
          sortBy,
          sortOrder,
          timeZone,
        })
      );
    };
    if (startDate && endDate) {
      fetchData();
    }
  }, [page, limit, allStores, allUsers, startDate, endDate, tableType, sortBy, sortOrder, startingDate, endingDate]);

  const totalData = analyticsTableData.find((item) => item.totalTaxableSales !== undefined);
  const totalTaxableSales = totalData?.totalTaxableSales || "0.00";
  const totalNonTaxableSales = totalData?.totalNonTaxableSales || "0.00";
  const totalNetSales = totalData?.totalNetSales || "0.00";

  const filteredData = analyticsTableData.filter((item) => item.taxName !== undefined);

  const grandTotalTaxAmount = filteredData
    .reduce((total, item) => {
      return item.taxAmount ? total + item.taxAmount : total;
    }, 0)
    .toFixed(2);

  const formatValue = (value) => {
    if (typeof value !== "number") return value; // Return as is if not a number
    return value.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const formattedData = filteredData.map((row) => ({
    ...row,
    taxableSales: formatValue(row.taxableSales),
    taxAmount: formatValue(row.taxAmount),
    taxRate: row.taxRate + "%", // No formatting needed for taxRate
  }));

  const columns = ["taxName", "taxRate", "taxableSales", "taxAmount"];

  const handleSelectStores = (storeIds) => {
    setAllStores(storeIds);
  };

  const handleSelectUsers = (userIds) => {
    setAllUsers(userIds);
  };
  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (newLimit) => {
    setLimit(newLimit);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const handleSort = (column) => {
    const newSortOrder = sortBy === column && sortOrder === "asc" ? "desc" : "asc";
    setSortBy(column);
    setSortOrder(newSortOrder);
  };

  const resetFilters = async () => {
    const formattedStartDate = startDate ? moment(new Date(startDate)).format("M-DD-yy") : "";
    const formattedEndDate = endDate ? moment(new Date(endDate)).format("M-DD-yy") : "";

    await dispatch(
      getTableData({
        tableType,
        page,
        limit,
        allStores: "all",
        allUsers: "all",
        startDate: formattedStartDate,
        endDate: formattedEndDate,
      })
    );
  };
  const { language } = useSelector((state) => state.auth);
  function numberWithCommas(n) {
    return n.toString().replace(/\B(?!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
  }
  return (
    <>
      <div className={`d-flex w-100 tax-card ${language === "arabic" ? "rtl" : "ltr"}`}>
        <SideCard
          handleSelectStores={handleSelectStores}
          handleSelectUsers={handleSelectUsers}
          allUser={allUsers}
          allStores={allStores}
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          handleStartDateChange={handleStartDateChange}
          handleEndDateChange={handleEndDateChange}
          page={page}
          limit={limit}
          tableType={tableType}
          showTimeRange={false}
          resetFilters={resetFilters}
        />

        <div className={`main-content w-100 ${language === "arabic" ? "pr-3" : "pl-3"}`}>
          <div className="info-cards">
            <InfoCard title={i18n("DASHBOARD.TaxableSales")} amount={formatValue(totalTaxableSales)} />
            <InfoCard title={i18n("DASHBOARD.NonTaxableSales")} amount={formatValue(totalNonTaxableSales)} />

            <InfoCard title={i18n("DASHBOARD.TotalNetSales")} amount={formatValue(totalNetSales)} className="net-sale-card" />
          </div>
          <div className="card tax-card mb-3">
            <div className="card-body">
              <h5 className="card-title text-primary mt-2 ml-2" role="button" onClick={() => exportTableToPDF("tableToExport", "sales_by_tax.pdf")}>
                {i18n("LandingPage.EXPORT")}
              </h5>
              <div id="tableToExport">
                <Table
                  columns={columns}
                  data={formattedData} // Pass formatted data
                  onSort={handleSort}
                  sortBy={sortBy}
                  sortOrder={sortOrder}
                />
                <div className="row mt-2">
                  <strong style={{ paddingLeft: "1.5rem" }} className="col">
                    {i18n("DASHBOARD.Total")}:
                  </strong>
                  <strong className="col-3 mr-md-9">{numberWithCommas(Number(formatValue(grandTotalTaxAmount)).toFixed(2))}</strong>
                </div>
              </div>
            </div>
            <nav className="mt-5">
              <PageNavigator
                page={page}
                limit={limit}
                handlePageChange={handlePageChange}
                handleLimitChange={handleLimitChange}
                data={formattedData}
              />
            </nav>
            {openFiltersDialog && (
              <FiltersDialog
                handleSelectStores={handleSelectStores}
                handleSelectUsers={handleSelectUsers}
                allUsers={allUsers}
                allStores={allStores}
                startDate={startDate}
                endDate={endDate}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                handleStartDateChange={handleStartDateChange}
                handleEndDateChange={handleEndDateChange}
                page={page}
                limit={limit}
                tableType={tableType}
                showTimeRange={false}
                resetFilters={resetFilters}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default TaxReport;
