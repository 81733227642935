/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router";
import { NavLink, Link } from "react-router-dom";
import { checkIsActive } from "../../../../_helpers";
import IconDashboard from "../../../../../assets/img/icon-dashboard.svg";
import IconCustomers from "../../../../../assets/img/icon-customers.svg";
// import IconInvoices from "../../../../../assets/img/icon-invoices.svg";
import IconInvoices from "../../../../../assets/img/invoiceIcon.svg";
import IconProducts from "../../../../../assets/img/icon-products.svg";
import IconAvatar from "../../../../../assets/img/avatar.svg";
import IconCompany from "../../../../../assets/img/icon-company.svg";
import BarIcon from "../../../../../assets/img/bar-icon.svg";
import { i18n } from "../../../../../app/pages/private/languageSelector";
import { shallowEqual, useSelector } from "react-redux";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useAside } from "../../AsideContext";
import { Popover, PopoverBody } from "reactstrap";
import { useHistory } from "react-router-dom";
import SubscriptionDialog from "../../../../../app/pages/company/company-edit-Dialog/SubscriptionDialog";
export function AsideMenuList({ layoutProps }) {
  const location = useLocation();
  const { setPopoverOpen, isAsideOpen, closeCompanyMenuList, openCompanyMenuList, togglePopover, popoverOpen } = useAside();
  const [rolePayload, setRolePayload] = useState([]);
  const [openCompanyMenu, setOpenCompanyMenu] = useState(false);
  const [isAnalyticsOpen, setIsAnalyticsOpen] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [openAvatar, setOpenAvatar] = useState(false);
  const [analyticsPopoverOpen, setAnalyticsPopoverOpen] = useState(false);
  const [companyPopoverOpen, setCompanyPopoverOpen] = useState(false);
  const [menuPopOverOpen, setMenuPopOverOpen] = useState(false);
  const [avatarPopOverOpen, setAvatarPopOverOpen] = useState(false);
  const [showLogoutDialog, setShowLogoutDialog] = useState(false);
  const popoverRef = useRef(null);
  const history = useHistory();
  const { role } = useSelector(
    (state) => ({
      role: state.auth.user?.rolePayload,
    }),
    shallowEqual
  );
  const { user } = useSelector((state) => state.auth);
  const { language } = useSelector((state) => state.auth);

  const { subscriptionData } = useSelector((state) => state.subscription);

  const isTrialPlan =
    subscriptionData?.subscription?.currentPlan?.plan === "Trial" && subscriptionData?.subscription?.userTrial?.isTrialStarted === true;

  const isTrialExpired =
    isTrialPlan &&
    subscriptionData?.subscription?.currentPlan?.status === "Expired" &&
    subscriptionData?.subscription?.userTrial?.isTrialExpired === true;

  const isSubscriptionPaused = subscriptionData?.subscription?.currentPlan?.status === "Paused";

  useEffect(() => {
    if (role && role.backOfficePayload) {
      setRolePayload([...Object.values(role.backOfficePayload)]);
    }
  }, [role]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (popoverRef.current && !popoverRef.current.contains(event.target)) {
        setPopoverOpen(false);
        setAnalyticsPopoverOpen(false); // Close analytics popover on outside click
        setMenuPopOverOpen(false);
        setAvatarPopOverOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [popoverRef]);

  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url) ? `${!hasSubmenu && "menu-item-active"} menu-item-open` : "";
  };

  const handleCompanyMenuClick = () => {
    setCompanyPopoverOpen(true);
    if (isAsideOpen === false) {
      closeCompanyMenuList();
      togglePopover();
      setIsAnalyticsOpen(false);
      setAnalyticsPopoverOpen(false);
      // setOpenAvatar(false);
      setAvatarPopOverOpen(false);
      setMenuPopOverOpen(false);
    } else {
      setOpenCompanyMenu((prev) => !prev);
      setIsAnalyticsOpen(false);
      setOpenMenu(false);
      setOpenAvatar(false);
    }
  };
  const toggleAnalyticsMenu = () => {
    setCompanyPopoverOpen(false);
    if (isAsideOpen) {
      setIsAnalyticsOpen((prev) => !prev);
      setOpenCompanyMenu(false);
      setOpenAvatar(false);
      setOpenMenu(false);
    } else {
      setAnalyticsPopoverOpen((prev) => !prev);
      setAvatarPopOverOpen(false);
      setMenuPopOverOpen(false);
      closeCompanyMenuList();
    }
  };
  const toggleMenu = () => {
    if (isAsideOpen) {
      setOpenMenu((prev) => !prev);
      setIsAnalyticsOpen(false);
      setOpenCompanyMenu(false);
      setOpenAvatar(false);
    } else {
      setMenuPopOverOpen((prev) => !prev);
      setAvatarPopOverOpen(false);
      setAnalyticsPopoverOpen(false);
      closeCompanyMenuList();
    }
  };
  const toggleAvatar = () => {
    if (isAsideOpen) {
      setOpenAvatar((prev) => !prev);
      setOpenMenu(false);
      setIsAnalyticsOpen(false);
      setOpenCompanyMenu(false);
    } else {
      setAvatarPopOverOpen((prev) => !prev);
      setMenuPopOverOpen(false);
      setAnalyticsPopoverOpen(false);
      closeCompanyMenuList();
    }
  };

  const handleLogout = () => {
    history.push("/logout");
  };
  const unSubscribeSubscription =
    subscriptionData?.subscription?.currentPlan?.plan === "Free" &&
    subscriptionData?.subscription?.currentPlan?.status === "Running" &&
    subscriptionData?.subscription?.subscriptionInfo?.isUnsubscribed;
  return (
    <>
      <ul className={`menu-nav ${layoutProps.ulClasses}`} style={{ direction: language === "arabic" ? "rtl" : "ltr" }}>
        {/* {rolePayload[0]?.selected && (
          <li
            style={{
              pointerEvents: (isTrialExpired || isSubscriptionPaused) && "none",
              opacity: (isTrialExpired || isSubscriptionPaused) && 0.6,
            }}
            onClick={() => setPopoverOpen(false)}
            className={`menu-item ${getMenuItemActive("/dashboard", false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/dashboard">
              <span className="menu-icon">
                <img src={IconDashboard} alt="#" title="Dashboard" width="20px" />
              </span>
              <span className="menu-text" style={{ fontSize: "initial" }}>
                {i18n("LandingPage.DASHBOARD")}
              </span>
            </NavLink>
          </li>
        )} */}
        {rolePayload[5]?.selected && (
          <li className={`menu-item menu-item-submenu ${isAnalyticsOpen ? "menu-item-open" : ""}`} aria-haspopup="true">
            <div
              style={{
                pointerEvents: (isTrialExpired || isSubscriptionPaused) && "none",
                opacity: (isTrialExpired || isSubscriptionPaused) && 0.6,
              }}
              className={`menu-link analytics-hover ${isAnalyticsOpen ? "bg-primary text-light" : ""}`}
              onClick={toggleAnalyticsMenu}
              id="AnalyticsPopover"
            >
              <span className="menu-icon">
                {isAnalyticsOpen ? (
                  <img src={BarIcon} alt="#" title="Analytics" width="20px" />
                ) : (
                  <img src={BarIcon} alt="#" title="Analytics" width="20px" />
                )}
              </span>
              <span className={`menu-text ${isAnalyticsOpen ? "text-light" : ""}`} style={{ fontSize: "initial" }}>
                {i18n("LandingPage.REPORTS")}
              </span>
              {isAsideOpen && <span className="menu-icon">{isAnalyticsOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}</span>}
            </div>
            {isAnalyticsOpen && isAsideOpen && (
              <div className="menu-submenu">
                <ul className="menu-subnav pt-0">
                  {rolePayload[6]?.selected && (
                    <li className={`menu-item ${getMenuItemActive("/analytics/sales-summary", false)}`} aria-haspopup="true">
                      <NavLink className="menu-link" to="/analytics/sales-summary">
                        <span className="menu-icon"></span>
                        <span className="menu-text " style={{ fontSize: "initial" }}>
                          {i18n("LandingPage.SalesSummary")}
                        </span>
                      </NavLink>
                    </li>
                  )}
                  {rolePayload[6]?.selected && (
                    <li className={`menu-item ${getMenuItemActive("/analytics/sales-by-product", false)}`} aria-haspopup="true">
                      <NavLink className="menu-link" to="/sales-by-product">
                        <span className="menu-icon"></span>
                        <span className="menu-text " style={{ fontSize: "initial" }}>
                          {i18n("LandingPage.SalesByProduct")}
                        </span>
                      </NavLink>
                    </li>
                  )}
                  {rolePayload[6]?.selected && (
                    <li className={`menu-item ${getMenuItemActive("/analytics/sales-by-category", false)}`} aria-haspopup="true">
                      <NavLink className="menu-link" to="/sales-by-category">
                        <span className="menu-icon"></span>
                        <span className="menu-text " style={{ fontSize: "initial", whiteSpace: "nowrap" }}>
                          {i18n("LandingPage.SalesByCategory")}
                        </span>
                      </NavLink>
                    </li>
                  )}
                  {rolePayload[6]?.selected && (
                    <li className={`menu-item ${getMenuItemActive("/analytics/sales-by-user", false)}`} aria-haspopup="true">
                      <NavLink className="menu-link" to="/sales-by-user">
                        <span className="menu-icon"></span>
                        <span className="menu-text " style={{ fontSize: "initial", whiteSpace: "nowrap" }}>
                          {i18n("LandingPage.SalesByUser")}
                        </span>
                      </NavLink>
                    </li>
                  )}
                  {rolePayload[6]?.selected && (
                    <li className={`menu-item ${getMenuItemActive("/analytics/sales-by-payment-channel", false)}`} aria-haspopup="true">
                      <NavLink className="menu-link" to="/sales-by-payment-channel">
                        <span className="menu-icon"></span>
                        <span className="menu-text" style={{ fontSize: "initial", whiteSpace: language === "arabic" && "nowrap" }}>
                          {i18n("LandingPage.SalesByPaymentChanel")}
                        </span>
                      </NavLink>
                    </li>
                  )}
                  {rolePayload[6]?.selected && (
                    <li className={`menu-item ${getMenuItemActive("/analytics/sales-by-time", false)}`} aria-haspopup="true">
                      <NavLink className="menu-link" to="/sales-by-time">
                        <span className="menu-icon"></span>
                        <span className="menu-text" style={{ fontSize: "initial", whiteSpace: "nowrap" }}>
                          {i18n("LandingPage.SalesByTime")}
                        </span>
                      </NavLink>
                    </li>
                  )}
                  {rolePayload[6]?.selected && (
                    <li className={`menu-item ${getMenuItemActive("/analytics/tax-report", false)}`} aria-haspopup="true">
                      <NavLink className="menu-link" to="/tax-report">
                        <span className="menu-icon"></span>
                        <span className="menu-text" style={{ fontSize: "initial", whiteSpace: "nowrap" }}>
                          {i18n("LandingPage.Taxes")}
                        </span>
                      </NavLink>
                    </li>
                  )}
                  {rolePayload[6]?.selected && (
                    <li className={`menu-item ${getMenuItemActive("/analytics/discount-report", false)}`} aria-haspopup="true">
                      <NavLink className="menu-link" to="/discount-report">
                        <span className="menu-icon"></span>
                        <span className="menu-text" style={{ fontSize: "initial", whiteSpace: "nowrap" }}>
                          {i18n("LandingPage.Discounts")}
                        </span>
                      </NavLink>
                    </li>
                  )}
                </ul>
              </div>
            )}
            {/* Popover for analytics menu when sidebar is closed */}
            {isAsideOpen === false && (
              <Popover
                placement="right-start"
                isOpen={analyticsPopoverOpen}
                target="AnalyticsPopover"
                toggle={() => setAnalyticsPopoverOpen(!analyticsPopoverOpen)}
                innerRef={popoverRef}
                className="report-popover"
              >
                <PopoverBody className={`${language === "arabic" && "body-arabic-font"}`}>
                  <ul className="menu-subnav report-popover-subnav pt-0">
                    {rolePayload[6]?.selected && (
                      <li className={`menu-item ${getMenuItemActive("/analytics/sales-summary", false)}`} aria-haspopup="true">
                        <NavLink className="menu-link" to="/analytics/sales-summary" onClick={() => setAnalyticsPopoverOpen(false)}>
                          <span className="menu-icon"></span>
                          <span className="menu-text popover-title" style={{ fontSize: "initial" }}>
                            {i18n("LandingPage.SalesSummary")}
                          </span>
                        </NavLink>
                      </li>
                    )}
                    {rolePayload[6]?.selected && (
                      <li className={`menu-item ${getMenuItemActive("/analytics/sales-by-product", false)}`} aria-haspopup="true">
                        <NavLink className="menu-link" to="/sales-by-product" onClick={() => setAnalyticsPopoverOpen(false)}>
                          <span className="menu-icon"></span>
                          <span className="menu-text popover-title" style={{ fontSize: "initial" }}>
                            {i18n("LandingPage.SalesByProduct")}
                          </span>
                        </NavLink>
                      </li>
                    )}
                    {rolePayload[6]?.selected && (
                      <li className={`menu-item ${getMenuItemActive("/analytics/sales-by-category", false)}`} aria-haspopup="true">
                        <NavLink className="menu-link" to="/sales-by-category" onClick={() => setAnalyticsPopoverOpen(false)}>
                          <span className="menu-icon"></span>
                          <span className="menu-text popover-title" style={{ fontSize: "initial" }}>
                            {i18n("LandingPage.SalesByCategory")}
                          </span>
                        </NavLink>
                      </li>
                    )}
                    {rolePayload[6]?.selected && (
                      <li className={`menu-item ${getMenuItemActive("/analytics/sales-by-user", false)}`} aria-haspopup="true">
                        <NavLink className="menu-link" to="/sales-by-user" onClick={() => setAnalyticsPopoverOpen(false)}>
                          <span className="menu-icon"></span>
                          <span className="menu-text popover-title" style={{ fontSize: "initial" }}>
                            {i18n("LandingPage.SalesByUser")}
                          </span>
                        </NavLink>
                      </li>
                    )}
                    {rolePayload[6]?.selected && (
                      <li className={`menu-item ${getMenuItemActive("/analytics/sales-by-payment-channel", false)}`} aria-haspopup="true">
                        <NavLink className="menu-link" to="/sales-by-payment-channel" onClick={() => setAnalyticsPopoverOpen(false)}>
                          <span className="menu-icon"></span>
                          <span className="menu-text popover-title" style={{ fontSize: "initial" }}>
                            {i18n("LandingPage.SalesByPaymentChanel")}
                          </span>
                        </NavLink>
                      </li>
                    )}
                    {rolePayload[6]?.selected && (
                      <li className={`menu-item ${getMenuItemActive("/analytics/sales-by-time", false)}`} aria-haspopup="true">
                        <NavLink className="menu-link" to="/sales-by-time" onClick={() => setAnalyticsPopoverOpen(false)}>
                          <span className="menu-icon"></span>
                          <span className="menu-text popover-title" style={{ fontSize: "initial" }}>
                            {i18n("LandingPage.SalesByTime")}
                          </span>
                        </NavLink>
                      </li>
                    )}
                    {rolePayload[6]?.selected && (
                      <li className={`menu-item ${getMenuItemActive("/analytics/tax-report", false)}`} aria-haspopup="true">
                        <NavLink className="menu-link" to="/tax-report" onClick={() => setAnalyticsPopoverOpen(false)}>
                          <span className="menu-icon"></span>
                          <span className="menu-text popover-title" style={{ fontSize: "initial" }}>
                            {i18n("LandingPage.Taxes")}
                          </span>
                        </NavLink>
                      </li>
                    )}
                    {rolePayload[6]?.selected && (
                      <li className={`menu-item ${getMenuItemActive("/analytics/discount-report", false)}`} aria-haspopup="true">
                        <NavLink className="menu-link" to="/discount-report" onClick={() => setAnalyticsPopoverOpen(false)}>
                          <span className="menu-icon"></span>
                          <span className="menu-text popover-title" style={{ fontSize: "initial" }}>
                            {i18n("LandingPage.Discounts")}
                          </span>
                        </NavLink>
                      </li>
                    )}
                  </ul>
                </PopoverBody>
              </Popover>
            )}
          </li>
        )}
        {rolePayload[2]?.selected && (
          <li
            style={{
              pointerEvents: (isTrialExpired || isSubscriptionPaused) && "none",
              opacity: (isTrialExpired || isSubscriptionPaused) && 0.6,
            }}
            onClick={() => setPopoverOpen(false)}
            className={`menu-item ${getMenuItemActive("/invoices", true)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/invoices">
              <span className="menu-icon">
                <img src={IconInvoices} alt="#" title="Invoice" width="18px" />
              </span>
              <span className="menu-text" style={{ fontSize: "initial" }}>
                {i18n("LandingPage.INVOICES")}
              </span>
            </NavLink>
          </li>
        )}
        {rolePayload[1]?.selected && (
          <li
            style={{
              pointerEvents: (isTrialExpired || isSubscriptionPaused) && "none",
              opacity: (isTrialExpired || isSubscriptionPaused) && 0.6,
            }}
            onClick={() => setPopoverOpen(false)}
            className={`menu-item ${getMenuItemActive("/customers", true)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/customers">
              <span className="menu-icon">
                <img src={IconCustomers} alt="#" title="Customer" width="20px" />
              </span>
              <span className="menu-text" style={{ fontSize: "initial" }}>
                {i18n("LandingPage.CUSTOMER")}
              </span>
            </NavLink>
          </li>
        )}
        {rolePayload[3]?.selected && (
          <>
            <li
              style={{
                pointerEvents: (isTrialExpired || isSubscriptionPaused) && "none",
                opacity: (isTrialExpired || isSubscriptionPaused) && 0.6,
              }}
              onClick={toggleMenu}
              className={`menu-item`}
              aria-haspopup="true"
              id="Popover2"
            >
              <a className="menu-link" href="#" onClick={(e) => e.preventDefault()}>
                <span className="menu-icon">
                  <img src={IconProducts} alt="#" title="Product" width="20px" />
                </span>
                <span className="menu-text" style={{ fontSize: "initial" }}>
                  {i18n("LandingPage.MENU")}
                </span>
                {isAsideOpen && <span className="menu-icon">{openMenu ? <ExpandLessIcon /> : <ExpandMoreIcon />}</span>}
              </a>
            </li>
            {openMenu && isAsideOpen ? (
              <>
                <li onClick={() => setPopoverOpen(false)} className={`menu-item ${getMenuItemActive("/products", true)}`} aria-haspopup="true">
                  <NavLink className="menu-link" to="/products">
                    <span className="menu-text" style={{ fontSize: "initial", marginLeft: "40px" }}>
                      {i18n("Company.Products")}
                    </span>
                  </NavLink>
                </li>
                <li className={`menu-item ${getMenuItemActive("/categories", true)}`} aria-haspopup="true">
                  <NavLink className="menu-link" to="/categories">
                    <span className="menu-text" style={{ fontSize: "initial", marginLeft: "40px" }}>
                      {i18n("global.Categories")}
                    </span>
                  </NavLink>
                </li>
                <li className={`menu-item ${getMenuItemActive("/discounts", true)}`} aria-haspopup="true">
                  <NavLink className="menu-link" to="/discounts">
                    <span className="menu-text" style={{ fontSize: "initial", marginLeft: "40px" }}>
                      {i18n("LandingPage.Discounts")}
                    </span>
                  </NavLink>
                </li>
              </>
            ) : null}
          </>
        )}
        {isAsideOpen === false && menuPopOverOpen && (
          <div className="position-fixed">
            <Popover
              flip
              isOpen={menuPopOverOpen}
              target="Popover2"
              placement="right-start"
              className="aside-popover"
              innerRef={popoverRef}
              dir={user && user?.language === "arabic" ? "rtl" : "ltr"}
            >
              <PopoverBody className={`aside-menu-list h-auto ${language === "arabic" && "body-arabic-font"}`}>
                {[
                  { path: "/products", label: i18n("Company.Products") },
                  {
                    path: "/categories",
                    label: i18n("global.Categories"),
                  },
                  {
                    path: "/discounts",
                    label: i18n("LandingPage.Discounts"),
                  },
                ].map(({ path, label }, index) => {
                  const isDisabled = (isTrialExpired || isSubscriptionPaused) && !["/users", "/subscription"].includes(path);
                  return (
                    <p
                      key={index}
                      style={{
                        pointerEvents: isDisabled ? "none" : "auto",
                        opacity: isDisabled ? 0.5 : 1,
                        fontSize: "14px",
                      }}
                      onClick={() => setMenuPopOverOpen(false)}
                    >
                      <Link to={path}>{label}</Link>
                    </p>
                  );
                })}
              </PopoverBody>
            </Popover>
          </div>
        )}{" "}
        {rolePayload[4]?.selected && (
          <li id="Popover1" onClick={handleCompanyMenuClick} className={`menu-item ${getMenuItemActive("/company", true)}`} aria-haspopup="true">
            <a className="menu-link" href="#" onClick={(e) => e.preventDefault()}>
              <span className="menu-icon">
                <img src={IconCompany} alt="#" title="Company" width="20px" />
              </span>
              <span className="menu-text" style={{ fontSize: "initial" }}>
                {i18n("LandingPage.COMPANY")}
              </span>
              {isAsideOpen && <span className="menu-icon">{openCompanyMenu ? <ExpandLessIcon /> : <ExpandMoreIcon />}</span>}
            </a>
          </li>
        )}
        {isAsideOpen && openCompanyMenu && (
          <>
            {rolePayload[5]?.selected && (
              <li
                style={{
                  pointerEvents: (isTrialExpired || isSubscriptionPaused) && "none",
                  opacity: (isTrialExpired || isSubscriptionPaused) && 0.6,
                }}
                className={`menu-item ${getMenuItemActive("/information", true)}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/information">
                  <span className="menu-text" style={{ fontSize: "initial", marginLeft: "40px" }}>
                    {i18n("Company.Information")}
                  </span>
                </NavLink>
              </li>
            )}
            <li className={`menu-item ${getMenuItemActive("/subscription", true)}`} aria-haspopup="true">
              <NavLink className="menu-link" to="/subscription">
                <span className="menu-text" style={{ fontSize: "initial", marginLeft: "40px" }}>
                  {i18n("Company.Subscription")}
                </span>
              </NavLink>
            </li>
            <li
              style={{
                pointerEvents: (isTrialExpired || isSubscriptionPaused) && "none",
                opacity: (isTrialExpired || isSubscriptionPaused) && 0.6,
              }}
              className={`menu-item ${getMenuItemActive("/payment-channels", true)}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/payment-channels">
                <span className="menu-text" style={{ fontSize: "initial", marginLeft: "40px" }}>
                  {i18n("global.PaymentChannels")}
                </span>
              </NavLink>
            </li>
            <li
              style={{
                pointerEvents: (isTrialExpired || isSubscriptionPaused) && "none",
                opacity: (isTrialExpired || isSubscriptionPaused) && 0.6,
              }}
              className={`menu-item ${getMenuItemActive("/taxes", true)}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/taxes">
                <span className="menu-text" style={{ fontSize: "initial", marginLeft: "40px" }}>
                  {i18n("LandingPage.Taxes")}
                </span>
              </NavLink>
            </li>
            <li
              style={{
                pointerEvents: (isTrialExpired || isSubscriptionPaused) && "none",
                opacity: (isTrialExpired || isSubscriptionPaused) && 0.6,
              }}
              className={`menu-item ${getMenuItemActive("/stores", true)}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/stores">
                <span className="menu-text" style={{ fontSize: "initial", marginLeft: "40px" }}>
                  {i18n("Company.Stores")}
                </span>
              </NavLink>
            </li>
            <li
              style={{
                pointerEvents: (isTrialExpired || isSubscriptionPaused) && "none",
                opacity: (isTrialExpired || isSubscriptionPaused) && 0.6,
              }}
              className={`menu-item ${getMenuItemActive("/pos-devices", true)}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/pos-devices">
                <span className="menu-text" style={{ fontSize: "initial", marginLeft: "40px" }}>
                  {i18n("Company.PosDevice")}
                </span>
              </NavLink>
            </li>
            <li className={`menu-item ${getMenuItemActive("/users", true)}`} aria-haspopup="true">
              <NavLink className="menu-link" to="/users">
                <span className="menu-text" style={{ fontSize: "initial", marginLeft: "40px" }}>
                  {i18n("Company.Users")}
                </span>
              </NavLink>
            </li>
            <li
              style={{
                pointerEvents: (isTrialExpired || isSubscriptionPaused) && "none",
                opacity: (isTrialExpired || isSubscriptionPaused) && 0.6,
              }}
              className={`menu-item ${getMenuItemActive("/roles", true)}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/roles">
                <span className="menu-text" style={{ fontSize: "initial", marginLeft: "40px" }}>
                  {i18n("Company.Roles")}
                </span>
              </NavLink>
            </li>
            <li
              style={{
                pointerEvents: (isTrialExpired || isSubscriptionPaused) && "none",
                opacity: (isTrialExpired || isSubscriptionPaused) && 0.6,
              }}
              className={`menu-item ${getMenuItemActive("/log-book", true)}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/log-book">
                <span className="menu-text" style={{ fontSize: "initial", marginLeft: "40px" }}>
                  {i18n("Company.CompanyLogBook")}
                </span>
              </NavLink>
            </li>
          </>
        )}
        {rolePayload[3]?.selected && (
          <>
            <li onClick={toggleAvatar} className={`menu-item`} aria-haspopup="true" id="Popover3">
              <a className="menu-link" href="#" onClick={(e) => e.preventDefault()}>
                <span className="menu-icon">
                  <img src={IconAvatar} alt="#" title="Product" width="20px" />
                </span>
                <span className="menu-text" style={{ fontSize: "initial" }}>
                  {i18n("global.Welcome")} {user?.name?.first}
                </span>
                {isAsideOpen && <span className="menu-icon">{openAvatar ? <ExpandLessIcon /> : <ExpandMoreIcon />}</span>}
              </a>
            </li>
            {openAvatar && isAsideOpen ? (
              <>
                <li onClick={() => setPopoverOpen(false)} className={`menu-item ${getMenuItemActive("/products", true)}`} aria-haspopup="true">
                  <NavLink className="menu-link" to="/user-settings">
                    <span className="menu-text" style={{ fontSize: "initial", marginLeft: "40px" }}>
                      {i18n("DASHBOARD.Settings")}
                    </span>
                  </NavLink>
                </li>
                <li className={`menu-item ${getMenuItemActive("/categories", true)}`} aria-haspopup="true">
                  <NavLink className="menu-link" to="/help">
                    <span className="menu-text" style={{ fontSize: "initial", marginLeft: "40px" }}>
                      {i18n("DASHBOARD.Help")}
                    </span>
                  </NavLink>
                </li>
                <li className={`menu-item ${getMenuItemActive("/logout", true)}`} aria-haspopup="true">
                  <a
                    className="menu-link"
                    href="#"
                    onClick={() => {
                      setShowLogoutDialog(true);
                    }}
                  >
                    <span className="menu-text" style={{ fontSize: "initial", marginLeft: "40px" }}>
                      {i18n("DASHBOARD.Logout")}
                    </span>
                  </a>
                </li>
              </>
            ) : null}
          </>
        )}
      </ul>
      {isAsideOpen === false && openCompanyMenuList === true && companyPopoverOpen === true && (
        <div className="position-fixed">
          <Popover
            flip
            isOpen={popoverOpen}
            target="Popover1"
            placement="right-start"
            className="aside-popover"
            innerRef={popoverRef}
            dir={user && user?.language === "arabic" ? "rtl" : "ltr"}
          >
            <PopoverBody className={`aside-menu-list ${language === "arabic" && "body-arabic-font"}`}>
              {[
                { path: "/information", label: i18n("Company.Information") },
                {
                  path: "/subscription",
                  label: i18n("Company.Subscription"),
                },
                {
                  path: "/payment-channels",
                  label: i18n("global.PaymentChannels"),
                },
                { path: "/taxes", label: i18n("LandingPage.Taxes") },
                { path: "/stores", label: i18n("Company.Stores") },
                { path: "/pos-devices", label: i18n("Company.PosDevice") },
                { path: "/users", label: i18n("Company.Users") },
                { path: "/roles", label: i18n("Company.Roles") },
                { path: "/log-book", label: i18n("Company.CompanyLogBook") },
              ].map(({ path, label }, index) => {
                const isDisabled = (isTrialExpired || isSubscriptionPaused) && !["/users", "/subscription"].includes(path);

                return (
                  <p
                    key={index}
                    style={{
                      pointerEvents: isDisabled ? "none" : "auto",
                      opacity: isDisabled ? 0.5 : 1,
                      fontSize: "14px",
                    }}
                    onClick={() => setPopoverOpen(false)}
                  >
                    <Link to={path}>{label}</Link>
                  </p>
                );
              })}
            </PopoverBody>
          </Popover>
        </div>
      )}
      {isAsideOpen === false && avatarPopOverOpen && (
        <div className="position-fixed">
          <Popover
            flip
            isOpen={avatarPopOverOpen}
            target="Popover3"
            placement="right-start"
            className={`aside-popover`}
            innerRef={popoverRef}
            dir={user && user?.language === "arabic" ? "rtl" : "ltr"}
          >
            <PopoverBody className={`aside-menu-list h-auto ${language === "arabic" && "body-arabic-font"}`}>
              {[
                { path: "/user-settings", label: i18n("DASHBOARD.Settings") },
                {
                  path: "/help",
                  label: i18n("DASHBOARD.Help"),
                },
                {
                  path: "#",
                  label: i18n("DASHBOARD.Logout"),
                },
              ].map(({ path, label }, index) => {
                return (
                  <p
                    key={index}
                    style={{
                      fontSize: "14px",
                    }}
                    onClick={() => {
                      setAvatarPopOverOpen(false);
                      if (path === "#") {
                        setShowLogoutDialog(true);
                      }
                    }}
                  >
                    <Link to={path}>{label}</Link>
                  </p>
                );
              })}
            </PopoverBody>
          </Popover>
        </div>
      )}{" "}
      {isAsideOpen && unSubscribeSubscription && (
        <div
          style={{
            height: !openCompanyMenu && "53%",
            display: "flex",
            alignItems: "end",
          }}
        >
          <span className="ml-4 d-flex justify-content-center align-items-center sidebar-subscribe-button">
            {i18n("SubscriptionModals.Subscribe")}
          </span>
        </div>
      )}
      <SubscriptionDialog
        modalWidth={"300px"}
        modalHeight={"150px"}
        modalBackgroundColor={"#FFFFFF"}
        show={showLogoutDialog}
        onHide={() => setShowLogoutDialog(false)}
        message={i18n("UserProfile.AreYouSureToLogout")}
        messageWidth={"262px"}
        messageHeight={"auto"}
        messageFontSize={"14px"}
        messageFontWeight={"400"}
        messageLineHeight={"21.79px"}
        messageColor={"#333333"}
        primaryLabel={i18n("SubscriptionModals.CANCEL")}
        primaryAction={() => setShowLogoutDialog(false)}
        primaryLabelWidth={"55px"}
        primaryLabelHeight={"30px"}
        primaryLabelFontWeight={"700"}
        primaryLabelFontSize={"14px"}
        primaryLabelLineHeight={"29.57px"}
        primaryLabelColor={"#828282"}
        secondaryAction={handleLogout}
        secondaryLabel={i18n("UserProfile.Logout")}
        secondaryLabelWidth={"auto"}
        secondaryLabelHeight={"30px"}
        secondaryLabelFontWeight={"700"}
        secondaryLabelFontSize={"14px"}
        secondaryLabelLineHeight={"29.57px"}
        secondaryLabelColor={"#EB5757"}
      />
    </>
  );
}
