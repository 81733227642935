import React, { useEffect, useState } from "react";
import EditCompanyInformation from "./information-edit-dialog/EditCompanyInformation";
import EditInformation from "./information-edit-dialog/EditInformation";
import { getCompany } from "../../../../_redux/company/companyCrud";
import { i18n } from "../../private/languageSelector";
import { useSelector } from "react-redux";
import CompanyView from "../company-edit-Dialog/companyView";

const Information = () => {
  const { language } = useSelector((state) => state.auth);

  const [companyInformationData, setCompanyInformationData] = useState({});
  const [openEditCompanyInformationDialog, setOpenEditCompanyInformationDialog] = useState(false);
  const [openEditInformationDialog, setOpenEditInformationDialog] = useState(false);
  const [openEditInformationDialogValue, setOpenEditInformationDialogValue] = useState("");

  const fetchCompanyInformation = async () => {
    const response = await getCompany();
    setCompanyInformationData(response?.data?.company);
  };

  useEffect(() => {
    fetchCompanyInformation();
  }, []);

  useEffect(() => {
    if (!openEditInformationDialog && !openEditCompanyInformationDialog) {
      fetchCompanyInformation();
    }
  }, [openEditInformationDialog, openEditCompanyInformationDialog]);

  return (
    <>
      <CompanyView />
      <div
        className="row d-flex justify-content-between"
        style={{
          direction: language === "arabic" ? "rtl" : "ltr",
        }}
      >
        <div className="col-lg-6 col-md-12  d-flex flex-column">
          <div
            className={`card ${language === "arabic" ? "rtl" : "ltr"}`}
            style={{
              backgroundColor: "#FFFFFF",
            }}
          >
            <h5 className="card-title" style={{ fontSize: "18px", fontWeight: "600", color: "#4F4F4F" }}>
              {i18n("Company.CompanyInformation")}
            </h5>
            <div className="d-flex row pb-11">
              <span className="col-md-4 col whitespace-nowrap">{i18n("global.BusinessName")}:</span>
              <span className="col-md-4 col-8 text-start">{companyInformationData?.companyName}</span>
            </div>
            <div className="d-flex row pb-11">
              <span className="col-md-4 col whitespace-nowrap">{i18n("Company.EmailAddress")}:</span>
              <span className="col-md-4 col-8 text-start">{companyInformationData?.email}</span>
            </div>
            <div className="d-flex row pb-11">
              <span className="col-md-4 col whitespace-nowrap">{i18n("global.PhoneNumber")}:</span>
              <span className="col-md-4 col-8 text-start">{companyInformationData?.mobile}</span>
            </div>
            <div className="d-flex row pb-11">
              <span className="col-md-4 col whitespace-nowrap">{i18n("global.TaxNumber")}:</span>
              <span className="col-md-4 col-8 text-start">{companyInformationData?.taxNumber}</span>
            </div>
            <div className="d-flex row pb-11">
              <span className="col-md-4 col whitespace-nowrap">{i18n("global.Currency")}:</span>
              <span className="col-md-4 col-8 text-start">{companyInformationData?.currency}</span>
            </div>
            <div className="d-flex row pb-11">
              <span className="col-md-4 col whitespace-nowrap">{i18n("Company.Country")}:</span>
              <span className="col-md-4 col-8 text-start">{companyInformationData?.country}</span>
            </div>
            <div className="d-flex row pb-11">
              <span className="col-md-4 col whitespace-nowrap">{i18n("global.Address")}:</span>
              <span className="col-md-4 col-8 text-start">{companyInformationData?.address}</span>
            </div>
            <div className="flex-row d-flex justify-content-end">
              <p onClick={() => setOpenEditCompanyInformationDialog(true)} className="company-edit-text">
                {i18n("Company.Edit")}
              </p>
            </div>
          </div>
          <div className={`card ${language === "arabic" ? "rtl" : "ltr"}`} style={{ backgroundColor: "#FFFFFF" }}>
            <h5 className="card-title" style={{ fontSize: "18px", fontWeight: "600", color: "#4F4F4F" }}>
              {i18n("Company.CompanyDefaultEmail/SMSMessage")}
            </h5>
            <p>
              {companyInformationData?.defaultSms?.messageEnglish !== "" ? (
                <span
                  style={{
                    fontWeight: "500",
                    fontSize: "12px",
                    lineHeight: "21px",
                    color: "#4F4F4F",
                  }}
                >
                  {companyInformationData?.defaultSms?.messageEnglish}
                </span>
              ) : (
                <span
                  style={{
                    fontWeight: "500",
                    fontSize: "12px",
                    color: "#BDBDBD",
                  }}
                >
                  {i18n("Company.YourDefaultEmail/SMSMessageGoesHere")}
                </span>
              )}
            </p>
            <div className="flex-row d-flex justify-content-end">
              <p
                onClick={() => {
                  setOpenEditInformationDialog(true);
                  setOpenEditInformationDialogValue("SMS_MESSAGE");
                }}
                className="company-edit-text"
              >
                {i18n("Company.Edit")}
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-md-12 d-flex flex-column">
          <div className={`card ${language === "arabic" ? "rtl" : "ltr"}`} style={{ backgroundColor: "#FFFFFF" }}>
            <h5 className="card-title" style={{ fontSize: "18px", fontWeight: "600", color: "#4F4F4F" }}>
              {i18n("SubscriptionModals.TermsAndConditions")}
            </h5>

            <p>
              {companyInformationData?.termsAndConditions !== "" ? (
                <span
                  style={{
                    fontWeight: "500",
                    fontSize: "12px",
                    lineHeight: "21px",
                    color: "#4F4F4F",
                  }}
                >
                  {companyInformationData?.termsAndConditions}
                </span>
              ) : (
                <span
                  style={{
                    fontWeight: "500",
                    fontSize: "12px",
                    color: "#BDBDBD",
                  }}
                >
                  {i18n("Company.YourTermsAndConditionsGoHere")}
                </span>
              )}
            </p>
            <div className="flex-row d-flex justify-content-end">
              <p
                onClick={() => {
                  setOpenEditInformationDialog(true);
                  setOpenEditInformationDialogValue("TERM_CONDITIONS");
                }}
                className="company-edit-text"
              >
                {i18n("Company.Edit")}
              </p>
            </div>
          </div>
          <div className={`card ${language === "arabic" ? "rtl" : "ltr"}`} style={{ backgroundColor: "#FFFFFF" }}>
            <h5 className="card-title" style={{ fontSize: "18px", fontWeight: "600", color: "#4F4F4F" }}>
              {i18n("Company.ReturnPolicy")}
            </h5>
            <p>
              {companyInformationData?.returnPolicy !== "" ? (
                <span
                  style={{
                    fontWeight: "500",
                    fontSize: "12px",
                    lineHeight: "21px",
                    color: "#4F4F4F",
                  }}
                >
                  {companyInformationData?.returnPolicy}
                </span>
              ) : (
                <span
                  style={{
                    fontWeight: "500",
                    fontSize: "12px",
                    color: "#BDBDBD",
                  }}
                >
                  {i18n("Company.YourReturn&ReplacementPolicyGoesHere")}
                </span>
              )}
            </p>
            <div className="flex-row d-flex justify-content-end">
              <p
                onClick={() => {
                  setOpenEditInformationDialog(true);
                  setOpenEditInformationDialogValue("RETURN_POLICY");
                }}
                className="company-edit-text"
              >
                {i18n("Company.Edit")}
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Edit Company Information */}
      <EditCompanyInformation
        companyInformationData={companyInformationData}
        openEditCompanyInformationDialog={openEditCompanyInformationDialog}
        setOpenEditCompanyInformationDialog={setOpenEditCompanyInformationDialog}
      />

      {/* Edit other information */}
      <EditInformation
        openEditInformationDialogValue={openEditInformationDialogValue}
        openEditInformationDialog={openEditInformationDialog}
        setOpenEditInformationDialog={setOpenEditInformationDialog}
        companyInformationData={companyInformationData}
      />
    </>
  );
};

export default Information;
