import React, { useState, useEffect } from "react";
import "./analytics.css";
import SideCard from "../../../components/Card/SideCard";
import Table from "../../../components/Table/Table";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { getTableData } from "../../../../_redux/analytics/analyticsService";
import PageNavigator from "../../../components/pagination/PageNavigator";
import { exportTableToPDF } from "../../../../utils/pdfExportUtil";
import { i18n } from "../../private/languageSelector";
import FiltersDialog from "../filters-dialog/FiltersDialog";

const DiscountsReport = () => {
  const dispatch = useDispatch();
  const [allStores, setAllStores] = useState("all");
  const [allUsers, setAllUsers] = useState("all");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [tableType, setTableType] = useState("salesByDiscount");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [sortBy, setSortBy] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [timeZone, setTimeZone] = useState("Asia/Riyadh");

  const { analyticsTableData, openFiltersDialog } = useSelector((state) => state.analytics);

  const columns = ["discountName", "discountRate", "discountsApplied", "amountDiscounted"];

  useEffect(() => {
    setTimeZone(Intl.DateTimeFormat().resolvedOptions().timeZone);
  }, []);

  // Format data before passing to Table component
  const formattedData = analyticsTableData.map((row) => ({
    ...row,
    amountDiscounted:
      row.amountDiscounted !== undefined
        ? row.amountDiscounted === 0
          ? "0.00"
          : row.amountDiscounted.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")
        : "",
    discountRate: row.discountRate
      ? String(row.discountRate).includes("%")
        ? row.discountRate === "0%" || row?.discountRate === "null%"
          ? "%"
          : row.discountRate
        : `${row.discountRate} SAR`
      : "SAR",
  }));

  // Calculate Grand Totals
  const calculateGrandTotals = (data) => {
    return data.reduce(
      (totals, row) => {
        totals.discountTotal += parseFloat(row.discountsApplied) || 0;
        totals.amountTotal += parseFloat(row.amountDiscounted.replace(/,/g, "")) || 0;
        // totals.amountTotal += parseFloat(row.amountDiscounteds) || 0;
        return totals;
      },
      { discountTotal: 0, amountTotal: 0 }
    );
  };

  const { discountTotal, amountTotal } = calculateGrandTotals(formattedData);

  let startingDate = localStorage.getItem("startDate");
  let endingDate = localStorage.getItem("endDate");

  useEffect(() => {
    const fetchData = async () => {
      const formattedStartDate = startDate ? moment(new Date(startDate)).format("M-DD-yy") : "";
      const formattedEndDate = endDate ? moment(new Date(endDate)).format("M-DD-yy") : "";
      await dispatch(
        getTableData({
          tableType,
          page,
          limit,
          allStores,
          allUsers,
          startDate: formattedStartDate,
          endDate: formattedEndDate,
          sortBy,
          sortOrder,
          timeZone,
        })
      );
    };
    if (startDate && endDate) {
      fetchData();
    }
  }, [page, limit, allStores, allUsers, startDate, endDate, tableType, sortBy, sortOrder, startingDate, endingDate]);

  const handleSort = (column) => {
    const newSortOrder = sortBy === column && sortOrder === "asc" ? "desc" : "asc";
    setSortBy(column);
    setSortOrder(newSortOrder);
  };

  const handleSelectStores = (storeIds) => {
    setAllStores(storeIds);
  };

  const handleSelectUsers = (userIds) => {
    setAllUsers(userIds);
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (newLimit) => {
    setLimit(newLimit);
  };
  const { language } = useSelector((state) => state.auth);
  function numberWithCommas(n) {
    return n.toString().replace(/\B(?!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
  }
  return (
    <div className={`d-flex disc-card ${language === "arabic" ? "rtl" : "ltr"}`}>
      <SideCard
        handleSelectStores={handleSelectStores}
        handleSelectUsers={handleSelectUsers}
        allUser={allUsers}
        allStores={allStores}
        startDate={startDate}
        endDate={endDate}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        handleStartDateChange={handleStartDateChange}
        handleEndDateChange={handleEndDateChange}
        page={page}
        limit={limit}
        tableType={tableType}
      />
      <div className={`main-content discount-main  w-100 ${language === "arabic" ? "pr-3" : "pl-3"}`}>
        <div className="card discount-card mb-3">
          <div className="card-body discount-cardbody">
            <div className="d-flex justify-content-between">
              <h5
                role="button"
                className="card-title text-primary mt-2 ml-3"
                onClick={() => exportTableToPDF("tableToExport", "sales_by_discount.pdf")}
              >
                {i18n("LandingPage.EXPORT")}
              </h5>
            </div>
            <div id="tableToExport">
              <Table
                columns={columns}
                data={formattedData} // Use the formatted data here
                onSort={handleSort}
                sortBy={sortBy}
                sortOrder={sortOrder}
              />
            </div>

            {/* Grand Total Display */}
            <div className="row  mt-2">
              <strong className="col" style={{ paddingLeft: "1.8rem" }}>
                {i18n("DASHBOARD.Total")}:
              </strong>
              <strong className="col">{numberWithCommas(discountTotal.toFixed(0))}</strong>
              <strong className="col-3" style={{ marginRight: "4.3rem", whiteSpace: "nowrap" }}>
                {numberWithCommas(Number(amountTotal).toFixed(2))}
              </strong>
            </div>
          </div>
          <nav className="mt-5">
            <PageNavigator page={page} limit={limit} handlePageChange={handlePageChange} handleLimitChange={handleLimitChange} data={formattedData} />
          </nav>
          {openFiltersDialog && (
            <FiltersDialog
              handleSelectStores={handleSelectStores}
              handleSelectUsers={handleSelectUsers}
              allUsers={allUsers}
              allStores={allStores}
              startDate={startDate}
              endDate={endDate}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              handleStartDateChange={handleStartDateChange}
              handleEndDateChange={handleEndDateChange}
              page={page}
              limit={limit}
              tableType={tableType}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default DiscountsReport;
