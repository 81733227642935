// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useEffect, useMemo, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, { PaginationProvider } from "react-bootstrap-table2-paginator";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../../_redux/customers/customersActions";
import {
  getSelectRow,
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses,
} from "../../../../_metronic/_helpers";
import * as uiHelpers from "../CustomersUIHelpers";
import * as columnFormatters from "./column-formatters";
import { Pagination } from "../../../../_metronic/_partials/controls";
import { useCustomersUIContext } from "../CustomersUIContext";
import { i18n } from "../../../pages/private/languageSelector";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import CustomerHeader from "./CustomerHeader";
import noCustomerIcon from "../../../../assets/img/noCategory.svg";
import PageNavigator from "../../../components/pagination/PageNavigator";

export function CustomersTable() {
  // Customers UI Context
  const customersUIContext = useCustomersUIContext();
  const customersUIProps = useMemo(() => {
    return {
      ids: customersUIContext.ids,
      setIds: customersUIContext.setIds,
      queryParams: customersUIContext.queryParams,
      setQueryParams: customersUIContext.setQueryParams,
      openEditCustomerDialog: customersUIContext.openEditCustomerDialog,
      openDeleteCustomerDialog: customersUIContext.openDeleteCustomerDialog,
      openViewCustomerDialog: customersUIContext.openViewCustomerDialog,
      openCustomerActionsDialog: customersUIContext.openCustomerActionsDialog,
    };
  }, [customersUIContext]);
  const { language } = useSelector((state) => state.auth);

  // Getting curret state of customers list from store (Redux)
  const { currentState } = useSelector((state) => ({ currentState: state.customers }), shallowEqual);
  const { totalCount, entities, listLoading } = currentState;
  const [searchValue, setSearchValue] = useState("");

  // Customers Redux state
  const dispatch = useDispatch();
  useEffect(() => {
    // clear selections list
    customersUIProps.setIds([]);
    // server call by queryParams
    dispatch(actions.fetchCustomers(customersUIProps.queryParams));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customersUIProps.queryParams, dispatch]);

  // Table columns
  const columns = [
    {
      dataField: "sr_no",
      text: i18n("Customer.ID"),
      sort: true,
      sortCaret: sortCaret,
      headerClasses: "text-price ",
      headerAlign: "start",
      headerStyle: {
        textAlign: "start",
        fontWeight: "400",
        fontSize: "14px",
        color: "#828282",
      },
      align: "start",
      style: {
        paddingTop: "15px",
        paddingBottom: "15px",
        minWidth: "70px",
      },
      // headerSortingClasses,
    },
    {
      dataField: "name",
      text: i18n("Customer.NAME"),
      sort: true,
      sortCaret: sortCaret,
      headerClasses: "text-price ",
      headerAlign: "start",
      headerStyle: {
        textAlign: "start",
        fontWeight: "400",
        fontSize: "14px",
        color: "#828282",
      },
      align: "start",
      style: {
        paddingTop: "15px",
        paddingBottom: "15px",
        minWidth: "70px",
      },
      // headerSortingClasses,
      formatter: (cell, row) => (
        <>
          <div>{cell && cell.length > 20 ? cell.substring(0, 15) + "..." : cell}</div>
        </>
      ),
    },
    {
      dataField: "mobile",
      text: i18n("Customer.MOBILE"),
      sort: true,
      headerClasses: "text-price ",
      headerAlign: "start",
      headerStyle: {
        textAlign: "start",
        fontWeight: "400",
        fontSize: "14px",
        color: "#828282",
      },
      align: "start",
      style: {
        paddingTop: "15px",
        paddingBottom: "15px",
        minWidth: "70px",
      },
      sortCaret: sortCaret,
      // headerSortingClasses,
      formatter: (cell, row) => (
        <>
          <div>{cell && cell.length > 15 ? cell.substring(0, 13) + "..." : cell}</div>
        </>
      ),
    },
    {
      dataField: "email",
      text: i18n("Customer.EMAIL"),
      sort: true,
      sortCaret: sortCaret,
      headerClasses: "text-price ",
      headerAlign: "start",
      headerStyle: {
        textAlign: "start",
        fontWeight: "400",
        fontSize: "14px",
        color: "#828282",
      },
      align: "start",
      style: {
        paddingTop: "15px",
        paddingBottom: "15px",
        minWidth: "70px",
      },
      // headerSortingClasses,
      formatter: (cell, row) => (
        <>
          <div>{cell && cell.length > 20 ? cell.substring(0, 15) + "..." : cell}</div>
        </>
      ),
    },
    {
      dataField: "first_invoice",
      text: i18n("Customer.FIRSTINVOICE"),
      sort: true,
      sortCaret: sortCaret,
      headerClasses: "text-price ",
      headerAlign: "start",
      headerStyle: {
        textAlign: "start",
        fontWeight: "400",
        fontSize: "14px",
        color: "#828282",
      },
      align: "start",
      style: {
        paddingTop: "15px",
        paddingBottom: "15px",
        minWidth: "70px",
      },
      // headerSortingClasses,
    },
    {
      dataField: "last_invoice",
      text: i18n("Customer.LASTINVOICE"),
      sort: true,
      sortCaret: sortCaret,
      headerClasses: "text-price ",
      headerAlign: "start",
      headerStyle: {
        textAlign: "start",
        fontWeight: "400",
        fontSize: "14px",
        color: "#828282",
      },
      align: "start",
      style: {
        paddingTop: "15px",
        paddingBottom: "15px",
        minWidth: "70px",
      },
      // headerSortingClasses,
    },
    {
      dataField: "total_invoices",
      text: i18n("Customer.TOTALINVOICE"),
      sort: true,
      sortCaret: sortCaret,
      headerClasses: "text-price ",
      headerAlign: "start",
      headerStyle: {
        textAlign: "start",
        fontWeight: "400",
        fontSize: "14px",
        color: "#828282",
      },
      align: "start",
      style: {
        paddingTop: "15px",
        paddingBottom: "15px",
        minWidth: "70px",
      },
      // headerSortingClasses,
    },
    {
      dataField: "total_sales",
      text: i18n("Customer.TotalSales"),
      sort: true,
      sortCaret: sortCaret,
      headerClasses: "text-price ",
      // headerAlign: "right",
      headerAlign: language === "english" ? "right" : "left",
      headerStyle: {
        // textAlign: "right",
        textAlign: language === "english" ? "right" : "left",
        fontWeight: "400",
        fontSize: "14px",
        color: "#828282",
      },
      // align: "left",
      align: language === "arabic" ? "left" : "right",
      style: {
        paddingTop: "15px",
        paddingBottom: "15px",
        minWidth: "70px",
      },
      // headerSortingClasses,
      formatter: (value, row) => <div className={`${language === "arabic" ? "text-left" : "text-right"}`}>{value ? value?.toFixed(2) : "0.00"}</div>,
    },
    // {
    //   dataField: "action",
    //   text: i18n("Customer.ACTIONS"),
    //   formatter: columnFormatters.ActionsColumnFormatter,
    //   formatExtraData: {
    //     openEditCustomerDialog: customersUIProps.openEditCustomerDialog,
    //     openDeleteCustomerDialog: customersUIProps.openDeleteCustomerDialog,
    //     openViewCustomerDialog: customersUIProps.openViewCustomerDialog,
    //     openCustomerActionsDialog: customersUIProps.openCustomerActionsDialog,
    //   },
    //   classes: "text-right pr-0",
    //   headerClasses: "text-right pr-3 text-width",
    //   style: {
    //     minWidth: "100px",
    //   },
    // },
  ];
  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: customersUIProps.queryParams.pageSize,
    page: customersUIProps.queryParams.pageNumber,
  };
  const handleEditCustomerClick = (row) => {
    customersUIProps.openEditCustomerDialog(row?._id);
  };

  let filteredCustomers = entities?.filter((item) => {
    let searchText = searchValue?.toLowerCase();
    return (
      item?.name?.toLowerCase()?.includes(searchText) ||
      item?.email?.toLowerCase()?.includes(searchText) ||
      item?.mobile?.toLowerCase()?.includes(searchText) ||
      item?.email?.toLowerCase()?.includes(searchText) ||
      item?.sr_no?.toLowerCase()?.includes(searchText)
    );
  });

  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            // <Pagination isLoading={listLoading} paginationProps={paginationProps}>
            // </Pagination>
            <>
              <CustomerHeader searchValue={searchValue} setSearchValue={setSearchValue} />
              <BootstrapTable
                wrapperClasses="table-responsive"
                bordered={false}
                classes={`table table-head-custom table-vertical-center overflow-hidden ${language === "arabic" ? "rtl" : "ltr"}`}
                bootstrap4
                remote
                keyField="_id"
                data={entities === null ? [] : filteredCustomers}
                columns={columns}
                defaultSorted={uiHelpers.defaultSorted}
                onTableChange={getHandlerTableChange(customersUIProps.setQueryParams)}
                selectRow={getSelectRow({
                  entities,
                  ids: customersUIProps.ids,
                  setIds: customersUIProps.setIds,
                })}
                rowEvents={{
                  onClick: (e, row, rowIndex) => {
                    if (e.target.type !== "checkbox") {
                      handleEditCustomerClick(row, e);
                    }
                  },
                }}
                {...paginationTableProps}
              >
                <PleaseWaitMessage entities={entities} />
                <NoRecordsFoundMessage entities={entities} />
              </BootstrapTable>
              {entities && entities.length > 0 && (
                <div className="pb-3 d-flex justify-content-end mr-3 ml-3">
                  <PageNavigator
                    page={paginationProps?.page}
                    limit={paginationProps?.sizePerPage}
                    handlePageChange={paginationProps?.onPageChange}
                    handleLimitChange={paginationProps?.onSizePerPageChange}
                    data={filteredCustomers}
                  />
                </div>
              )}

              {entities && entities?.length < 1 && (
                <>
                  <div className="d-flex flex-column justify-content-center align-items-center mt-5 mb-5">
                    <div className="category-icon-bg d-flex justify-content-center align-items-center ">
                      <img src={noCustomerIcon} alt="noCustomerIcon" />
                    </div>
                    <p className="py-3 you-have-no-category">{i18n("Customer.NoCustomers")}</p>
                  </div>
                  <div className="pb-3 d-flex justify-content-end mr-3 ml-3">
                    <PageNavigator
                      page={paginationProps?.page}
                      limit={paginationProps?.sizePerPage}
                      handlePageChange={paginationProps?.onPageChange}
                      handleLimitChange={paginationProps?.onSizePerPageChange}
                      data={filteredCustomers}
                    />
                  </div>
                </>
              )}
            </>
          );
        }}
      </PaginationProvider>
    </>
  );
}
