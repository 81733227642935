import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import PageNavigator from "../../../../components/pagination/PageNavigator";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import DeleteIcon from "../../../../../assets/img/company/Vector.svg";
import { i18n } from "../../../private/languageSelector";
import SubscriptionDialog from "../../../company/company-edit-Dialog/SubscriptionDialog";
import * as storeActions from "../../../../../_redux/stores/storeActions";
import { StoresEditForm } from "./StoresEditForm";
import CustomSearch from "../../../../components/searchBar/CustomSearch";
import { sortCaret } from "../../../../../_metronic/_helpers";
import { storesSlice } from "../../../../../_redux/stores/storeSlice";

const StoresPage = () => {
  const dispatch = useDispatch();
  const { language } = useSelector((state) => state.auth);
  const [openStoreAddDialog, setOpenStoreAddDialog] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [sortBy, setSortBy] = useState("desc");
  const [sortAt, setSortAt] = useState("created_at");

  const { stores, showLayout } = useSelector(
    (state) => ({
      stores: state.stores?.entities,
      showLayout: state.stores?.showLayout,
    }),
    shallowEqual
  );
  useEffect(() => {
    dispatch(storeActions.fetchStores(limit, page, sortBy, sortAt));
  }, [dispatch, limit, page, sortAt, sortBy]);

  const [searchValue, setSearchValue] = useState("");
  const [showSearchInput, setShowSearchInput] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [edit, setEdit] = useState(false);
  const [selectedStore, setSelectedStore] = useState(null);
  const [showDeleteStoreModal, setShowDeleteStoreModal] = useState(false);
  const [isStoreDeleteErr, setIsStoreDeleteErr] = useState(false);
  const [showDeleteErrModal, setShowDeleteErrModal] = useState(false);

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };
  const handleLimitChange = (newLimit) => {
    setLimit(newLimit);
  };

  const handleEditStoreClick = (row) => {
    setEdit(true);
    setSelectedStore(row);
    setOpenStoreAddDialog(true);
    dispatch(storesSlice.actions.showLayout(false));
  };
  const highlightMatch = (text, query) => {
    if (!query) return text;
    const parts = text.split(new RegExp(`(${query})`, "gi"));
    return parts.map((part, index) => (part.toLowerCase() === query.toLowerCase() ? <b key={index}>{part}</b> : part));
  };

  const columns = [
    {
      dataField: "name",
      text: i18n("Customer.NAME"),
      align: language === "arabic" ? "start" : "left",
      headerStyle: {
        textAlign: "start",
        fontWeight: "400",
        fontSize: "14px",
        color: "#828282",
      },
      style: {
        paddingTop: "15px",
        paddingBottom: "15px",
      },
      formatter: (cell) => highlightMatch(cell, searchValue),
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "address",
      text: i18n("global.Address"),
      align: language === "arabic" ? "start" : "left",
      headerStyle: {
        textAlign: "start",
        fontWeight: "400",
        fontSize: "14px",
        color: "#828282",
      },
      style: {
        paddingTop: "15px",
        paddingBottom: "15px",
      },
      formatter: (cell) => {
        const formattedCell = `${cell}`;
        return highlightMatch(formattedCell, searchValue);
      },
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "numberOfPos",
      text: i18n("global.Pos"),
      align: language === "arabic" ? "left" : "right",
      headerStyle: {
        textAlign: language === "arabic" ? "left" : "right",
        fontWeight: "400",
        fontSize: "14px",
        color: "#828282",
      },
      style: {
        paddingTop: "15px",
        paddingBottom: "15px",
      },
      formatter: (cell) => {
        const formattedCell = `${cell}`;
        return highlightMatch(formattedCell, searchValue);
      },
      sort: true,
      sortCaret: sortCaret,
    },
  ];

  const selectRow = {
    mode: "checkbox",
    clickToSelect: false,
    onSelect: (row, isSelected) => {
      const newSelectedRows = isSelected ? [...selectedRows, row._id] : selectedRows.filter((id) => id !== row._id);
      setSelectedRows(newSelectedRows);
      return true;
    },
    onSelectAll: (isSelected, rows) => {
      const newSelectedRows = isSelected ? rows.map((row) => row._id) : [];
      setSelectedRows(newSelectedRows);
    },
  };

  const handleSearchInputChange = (e) => {
    setSearchValue(e.target.value);
  };

  if (!stores) {
    return null;
  }

  const confirmDelete = () => {
    if (selectedRows.length > 0 || openStoreAddDialog) {
      if (selectedStore?._id) {
        dispatch(storeActions.deleteStore(selectedStore?._id))
          .then((res) => {
            dispatch(storeActions.fetchStores(limit, page));
          })
          .catch((error) => {
            error.clientMessage = "Can't delete store";
            setIsStoreDeleteErr(true);
            setShowDeleteErrModal(true);
          });
      } else {
        for (let id of selectedRows) {
          dispatch(storeActions.deleteStore(id))
            .then((res) => {
              dispatch(storeActions.fetchStores(limit, page));
            })
            .catch((error) => {
              error.clientMessage = "Can't delete store";
              setIsStoreDeleteErr(true);
              setShowDeleteErrModal(true);
            });
        }
      }
      setSelectedRows([]);
      setShowDeleteStoreModal(false);
      setOpenStoreAddDialog(false);
      dispatch(storesSlice.actions.showLayout(true));
      setSelectedStore(null);
      setIsStoreDeleteErr(false);
    }
  };

  let filteredStores = stores?.filter((store) => {
    let searchText = searchValue?.toLowerCase();
    return store?.name?.toLowerCase()?.includes(searchText);
  });

  const handleSorting = (type, sortOrder, sortField, data, page, sizePerPage) => {
    if (type === "sort") {
      setSortAt(sortField);
      setSortBy(sortOrder);
    }
  };

  return (
    <>
      {!openStoreAddDialog ? (
        <div className={language === "arabic" ? "rtl" : "ltr"}>
          <div className="table-width" style={{ backgroundColor: "#FFFFFF", borderRadius: "0.42rem" }}>
            <div className="pl-4 pr-4 py-4 mb-3 d-flex justify-content-between align-items-center">
              {/* <div style={{ cursor: "pointer" }} className="d-flex justify-content-between align-items-center">
              <img onClick={() => setShowSearchInput(!showSearchInput)} src={SearchIcon} alt="SearchImage" />
              {showSearchInput && (
                <div ref={searchInputRef} className="position-relative">
                  <input
                    style={{
                      width: "400px",
                      border: "none",
                      borderBottom: "2px solid #2D9CDB",
                      borderRadius: "0",
                    }}
                    className="form-control mr-sm-2 ml-3"
                    type="search"
                    placeholder={"Search..."}
                    aria-label="Search"
                    value={searchValue}
                    onChange={handleSearchInputChange}
                  />
                </div>
              )}
            </div> */}
              <CustomSearch
                handleSearchInputChange={handleSearchInputChange}
                searchValue={searchValue}
                setSearchValue={setSearchValue}
                setShowSearchInput={setShowSearchInput}
                showSearchInput={showSearchInput}
              />
              <div>
                {selectedRows.length > 0 && (
                  <span className="mr-5 cursor-pointer" onClick={() => setShowDeleteStoreModal(true)}>
                    <img src={DeleteIcon} alt="Delete" />
                  </span>
                )}
                <span
                  className="add-text-btn"
                  onClick={() => {
                    setEdit(false);
                    setSelectedStore(null);
                    setOpenStoreAddDialog(true);
                    dispatch(storesSlice.actions.showLayout(false));
                  }}
                >
                  {i18n("global.AddStore")}
                </span>
              </div>
            </div>
            <BootstrapTable
              wrapperClasses="table-responsive"
              classes="table table-head-custom table-vertical-center overflow-hidden"
              remote
              bordered={false}
              bootstrap4
              keyField="_id"
              columns={columns}
              data={filteredStores}
              // selectRow={selectRow}
              rowClasses="cursor-pointer"
              onTableChange={(type, { page, sizePerPage, sortField, sortOrder, data }) =>
                handleSorting(type, sortOrder, sortField, data, page, sizePerPage)
              }
              rowEvents={{
                onClick: (e, row, rowIndex) => {
                  if (e.target.type !== "checkbox") {
                    handleEditStoreClick(row);
                  }
                },
              }}
            />

            <div className="pb-3 d-flex justify-content-end mr-3 ml-3">
              <PageNavigator
                page={page}
                limit={limit}
                handlePageChange={handlePageChange}
                handleLimitChange={handleLimitChange}
                data={filteredStores}
              />
            </div>
          </div>
        </div>
      ) : (
        <>
          <StoresEditForm
            edit={edit}
            store={edit ? selectedStore : undefined}
            openStoresAddDialog={openStoreAddDialog}
            setOpenStoresAddDialog={setOpenStoreAddDialog}
            setShowDeleteStoreModal={setShowDeleteStoreModal}
          />
        </>
      )}
      {/* delete store modal  */}
      <SubscriptionDialog
        show={showDeleteStoreModal}
        onHide={() => setShowDeleteStoreModal(false)}
        modalWidth="350px"
        modalHeight="185px"
        message={i18n("global.AreYouSureToDeleteStore")}
        messageWidth="320px"
        messageHeight="44px"
        messageColor="#333333"
        messageFontWeight="400"
        messageFontSize="16px"
        messageLineHeight="21.79px"
        primaryAction={() => setShowDeleteStoreModal(false)}
        primaryLabel={i18n("SubscriptionModals.CANCEL")}
        primaryLabelWidth="55px"
        primaryLabelHeight="20px"
        primaryLabelFontWeight="700"
        primaryLabelFontSize="14px"
        primaryLabelLineHeight="20px"
        primaryLabelColor="#828282"
        secondaryAction={confirmDelete}
        secondaryLabel={i18n("Discount.DELETE")}
        secondaryLabelWidth="67px"
        secondaryLabelHeight="20px"
        secondaryLabelFontWeight="700"
        secondaryLabelFontSize="14px"
        secondaryLabelLineHeight="20px"
        secondaryLabelColor="#EB5757"
        isCenteredOnMobile
      />
      {/* error dialog for store having pos linked with it */}

      <SubscriptionDialog
        show={showDeleteErrModal}
        onHide={() => setShowDeleteErrModal(false)}
        modalWidth="350px"
        modalHeight="185px"
        message={"Remove the POS linked to this store to be able to delete it"}
        messageWidth="320px"
        messageHeight="44px"
        messageColor="#333333"
        messageFontWeight="400"
        messageFontSize="16px"
        messageLineHeight="21.79px"
        primaryAction={() => setShowDeleteErrModal(false)}
        primaryLabel={i18n("Customer.Close")}
        primaryLabelWidth="55px"
        primaryLabelHeight="20px"
        primaryLabelFontWeight="700"
        primaryLabelFontSize="14px"
        primaryLabelLineHeight="20px"
        primaryLabelColor="#828282"
        title={"Can’t Delete Store"}
        titleColor={"#EB5757"}
        isCenteredOnMobile
      />
    </>
  );
};

export default StoresPage;
