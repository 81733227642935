import React, { useState, useMemo, useRef, useEffect } from "react";
import DeleteIcon from "../../../../assets/img/company/Vector.svg";
import { useProductsUIContext } from "../ProductsUIContext";
import { useDispatch, useSelector } from "react-redux";
import SubscriptionDialog from "../../company/company-edit-Dialog/SubscriptionDialog";
import { i18n } from "../../private/languageSelector";
import toast from "react-hot-toast";
import * as actions from "../../../../_redux/products/productsActions";
import CustomSearch from "../../../components/searchBar/CustomSearch";
import { productsSlice } from "../../../../_redux/products/productsSlice";
import { FilterDialogeProducts } from "../products-filter/FilterDialogeProducts";
import filterIcon from "../../../../assets/img/filterIcon.svg";
import filterActiveIcon from "../../../../assets/img/filterIconBlue.svg";
function ProductHeader({ searchValue, setSearchValue, setOpenAddProductPage, setSelectedProduct }) {
  const [showDeleteProductModal, setShowDeleteProductModal] = useState(false);
  const [showSearchInput, setShowSearchInput] = useState(false);
  const [filterDialog, setFilterDialog] = useState(false);
  const [filterActive, setFilterActive] = useState(false);
  const dispatch = useDispatch();
  const productsUIContext = useProductsUIContext();
  const filterRef = useRef();
  const productsUIProps = useMemo(() => {
    return {
      ids: productsUIContext.ids,
      setIds: productsUIContext.setIds,
      queryParams: productsUIContext.queryParams,
      setQueryParams: productsUIContext.setQueryParams,
      openEditProductDialog: productsUIContext.openEditProductDialog,
      openDeleteProductDialog: productsUIContext.openDeleteProductDialog,
      openViewProductDialog: productsUIContext.openViewProductDialog,
      openViewProductActionDialog: productsUIContext.openViewProductActionDialog,
      newProductButtonClick: productsUIContext.newProductButtonClick,
    };
  }, [productsUIContext]);

  const handleSearchInputChange = (e) => {
    setSearchValue(e.target.value);
  };

  const confirmDelete = () => {
    if (productsUIProps?.ids?.length) {
      dispatch(actions.deleteProducts(productsUIProps?.ids)).then(() => {
        toast.success("Products Deleted Successfully");
        productsUIProps.setIds([]);
      });
    } else {
      toast.error("No Product Selected");
    }
    setShowDeleteProductModal(false);
  };
  const { language } = useSelector((state) => state.auth);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (filterRef && !filterRef?.current?.contains(event.target)) {
        setFilterDialog(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [filterRef]);

  return (
    <div className={`pl-4 pr-4 py-4 mb-3 d-flex justify-content-between align-items-center ${language === "arabic" ? "rtl" : "ltr"}`}>
      <div className="d-flex" style={{ gap: "5rem" }}>
        <CustomSearch
          handleSearchInputChange={handleSearchInputChange}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          setShowSearchInput={setShowSearchInput}
          showSearchInput={showSearchInput}
        />
        <div>
          <img src={filterActive ? filterActiveIcon : filterIcon} className="cursor-pointer" alt="iconFilter" onClick={() => setFilterDialog(true)} />
          <div ref={filterRef} style={{ position: "relative" }}>
            <FilterDialogeProducts
              show={filterDialog}
              title={i18n("MENU.FilterProductsList")}
              subTitle="Clear All Filters"
              primaryAction={() => setFilterDialog(false)}
              setFilterActive={setFilterActive}
              secondaryAction={() => setFilterDialog(false)}
              classes="modal-drawer"
              primaryActionTitle={i18n("MENU.Apply")}
              secondaryActionTitle="No"
            />
          </div>
        </div>
      </div>

      <div>
        {productsUIProps.ids?.length > 0 && (
          <span className={` cursor-pointer ${language === "arabic" ? "ml-5" : "mr-5"}`} onClick={() => setShowDeleteProductModal(true)}>
            <img src={DeleteIcon} alt="Delete" />
          </span>
        )}
        {/* <span className="add-text-btn" onClick={productsUIProps.newProductButtonClick}> */}
        <span
          className="add-text-btn"
          onClick={() => {
            setSelectedProduct(undefined);
            setTimeout(() => {
              setOpenAddProductPage(true);
              dispatch(productsSlice.actions.showProductsLayout(false));
            }, 500);
          }}
        >
          {i18n("MENU.AddProduct")}
        </span>
      </div>
      <SubscriptionDialog
        show={showDeleteProductModal}
        onHide={() => setShowDeleteProductModal(false)}
        modalWidth="350px"
        modalHeight="185px"
        message={productsUIProps?.ids?.length === 1 ? i18n("MENU.DeleteSelectedProducts") : i18n("MENU.DeleteSelectedProduct")}
        messageWidth="320px"
        messageHeight="44px"
        messageColor="#333333"
        messageFontWeight="400"
        messageFontSize="16px"
        messageLineHeight="21.79px"
        primaryAction={() => setShowDeleteProductModal(false)}
        primaryLabel={i18n("SubscriptionModals.CANCEL")}
        primaryLabelWidth="55px"
        primaryLabelHeight="20px"
        primaryLabelFontWeight="700"
        primaryLabelFontSize="14px"
        primaryLabelLineHeight="20px"
        primaryLabelColor="#828282"
        secondaryAction={confirmDelete}
        secondaryLabel={i18n("Discount.DELETE")}
        secondaryLabelWidth="67px"
        secondaryLabelHeight="20px"
        secondaryLabelFontWeight="700"
        secondaryLabelFontSize="14px"
        secondaryLabelLineHeight="20px"
        secondaryLabelColor="#EB5757"
        isCenteredOnMobile
      />
    </div>
  );
}

export default ProductHeader;
