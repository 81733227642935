import { i18n } from "../../app/pages/private/languageSelector";
import * as requestFromServer from "./usersCrud";
import { usersSlice, callTypes } from "./usersSlice";
import toast from "react-hot-toast";

const { actions } = usersSlice;
export const fetchUsers = (limit = 10, page = 1, sortOrder = "desc", sortField = "created_at") => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getAllUsers(limit, page, sortOrder, sortField)
    .then((response) => {
      const { users } = response.data;
      dispatch(
        actions.usersFetched({
          entities: users,
        })
      );
    })
    .catch((error) => {
      // toast.error(error?.response?.data?.message);
      error.clientMessage = "Can't find users";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const createUser = (userForCreation) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer.createUser(userForCreation);
};

export const fetchUser = (id) => (dispatch) => {
  if (!id) {
    return dispatch(actions.userFetched({ userForEdit: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getUserById(id)
    .then((response) => {
      const { User } = response.data;
      dispatch(actions.userFetched({ userForEdit: User }));
    })
    .catch((error) => {
      toast.error(error?.response?.data?.message);
      error.clientMessage = "Can't find User";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteUser = (id) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer.deleteUser(id);
};

export const deleteSubscriptionUsers = (id) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer.deleteSubscriptionUsers(id);
};

export const updateUser = (id, user, stateEmptyHandler, resetForm) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateUser(id, user)
    .then(() => {
      dispatch(fetchUsers());
      toast.success(i18n("SubscriptionToast.UserUpdatedSuccessfully"));
      stateEmptyHandler(resetForm);
    })
    .catch((error) => {
      toast.error(error?.response?.data?.message);
      error.clientMessage = "Can't update User";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
export const updateUserPosPin = (id, user) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer.updateUserPosPin(id, user).catch((error) => {
    toast.error(error?.response?.data?.message);
    error.clientMessage = "Can't update User";
    dispatch(actions.catchError({ error, callType: callTypes.action }));
  });
};

export const updateUsersWithRole = (ids, role) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateUsersWithRole(ids, role)
    .then(() => {
      dispatch(fetchUsers());
      //toast.success("Updated");
    })
    .catch((error) => {
      toast.error(error?.response?.data?.message);
      error.clientMessage = "Can't update User";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const fetchSetupUserDetail = (token) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer.setupAccountDetail(token).catch((error) => {
    toast.error(error?.response?.data?.message);
    error.clientMessage = "Can't setup account";
    dispatch(actions.catchError({ error, callType: callTypes.action }));
  });
};

export const setupUserAccount = (token, data) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return (
    requestFromServer
      .setupAccount(token, data)
      // .then(() => {
      //   toast.success("Success");
      // })
      .catch((error) => {
        // toast.error(error?.response?.data?.message);
        error.clientMessage = "Can't setup account";
        dispatch(actions.catchError({ error, callType: callTypes.action }));
      })
  );
};
