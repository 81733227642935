import React from "react";
import { NavLink } from "react-router-dom";
import { i18n } from "../../../pages/private/languageSelector";

const ForgotPasswordNext = () => {
  const selectedLanguage = localStorage.getItem("language");

  return (
    <div className="forget-password-next" style={{ background: "#F2F2F2", height: "100vh" }}>
      <div className="d-flex justify-content-center align-items-center h-100">
        <div
          className="login-form"
          style={{
            maxWidth: "350px",
            width: "auto",
            background: "white",
            textAlign: selectedLanguage === "arabic" ? "right" : "left",
            height: "206px",
            borderRadius: "10px",
            padding: "25px 18px 25px 18px",
            boxShadow: " 0px 4px 4px 0px #00000040",
          }}
        >
          <p style={{ color: "#333333", fontSize: "18px" }}>{i18n("ForgotPasswordNext.PasswordResetLinkSent")}</p>
          <p>{i18n("ForgotPasswordNext.YourRequestHaveBeenSuccessfullyRecievedPleaseCheckYourEmailToResetYourPassword")}</p>
          <NavLink className="menu-link" to="/logout">
            <p
              style={{
                color: "blue",
              }}
            >
              <button className={`btn font-weight-bold px-9 py-4 my-3 w-100 auth-btn`}>
                <span>{i18n("ForgotPasswordNext.GoToLogin")}</span>
              </button>
            </p>
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default ForgotPasswordNext;
