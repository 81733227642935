import React, { useEffect, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../../_redux/customers/customersActions";
// import { CustomerEditDialogHeader } from "./CustomerEditDialogHeader";
import { CustomerEditForm } from "./CustomerEditForm";
import { useCustomersUIContext } from "../CustomersUIContext";
// import SubscriptionDialog from "../company-edit-Dialog/SubscriptionDialog";
import { i18n } from "../../../pages/private/languageSelector";
import SubscriptionDialog from "../../company/company-edit-Dialog/SubscriptionDialog";
import toast from "react-hot-toast";

const initCustomer = {
  id: undefined,
  firstName: "",
  lastName: "",
  email: "",
  mobile: "",
  city: "",
  country: "",
  address: "",
  state: "",
  zip: "",
  taxNumber: "",
  businessName: "",
};

export function CustomerEditDialog({ id, view, onHide, forInvoice, customerName, showCustomerAddDialoag, setShowCustomerAddDialoag }) {
  const [invoiceCustomerName, setInvoiceCustomerName] = useState({
    customerFirstName: "",
    customerSecondName: "",
    customerThirdName: "",
  });
  const [showCustomerDeleteDialog, setShowCustomerDeleteDialog] = useState(false);
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [showConfrmationDialog, setShowConfrmationDialog] = useState(false);

  useEffect(() => {
    if (customerName) {
      let firstName = customerName.split(" ").slice(0, 1);
      let secondName = customerName.split(" ").slice(1, 2);
      let thirdName = customerName.split(" ").slice(2, 3);
      setInvoiceCustomerName({
        customerFirstName: firstName[0],
        customerSecondName: secondName[0],
        customerThirdName: thirdName[0],
      });
    }
  }, [customerName]);

  // Customers UI Context
  const customersUIContext = useCustomersUIContext();
  const customersUIProps = useMemo(() => {
    return {
      queryParams: customersUIContext?.queryParams,
    };
  }, [customersUIContext]);

  // Customers Redux state
  const dispatch = useDispatch();
  const { actionsLoading, customerForEdit, navigate } = useSelector(
    (state) => ({
      actionsLoading: state.customers.actionsLoading,
      customerForEdit: state.customers.customerForEdit,
      navigate: state.customers.navigate,
    }),
    shallowEqual
  );
  useEffect(() => {
    if (navigate) {
      onHide();
    }
    // eslint-disable-next-line
  }, [navigate]);

  useEffect(() => {
    // server call for getting Customer by id
    dispatch(actions.fetchCustomer(id));
  }, [id, dispatch]);

  // server request for saving customer
  const saveCustomer = (customer, closeModal, showEmailError, showMobileError) => {
    if (!id) {
      // server request for creating customer
      dispatch(actions.createCustomer(customer, closeModal, showEmailError, showMobileError, forInvoice ? {} : customersUIProps.queryParams));
    } else {
      // server request for updating customer
      dispatch(actions.updateCustomer(customer, closeModal, showEmailError, showMobileError, customersUIProps.queryParams));
    }
  };

  const confirmDelete = () => {
    if (id) {
      dispatch(actions.deleteCustomer(id)).then(() => {
        toast.success("Customer deleted successfully");
        dispatch(actions.fetchCustomers());
      });
      setShowCustomerDeleteDialog(false);
      setShowCustomerAddDialoag(false);
    }
  };
  const handleClose = () => {
    if (isFormDirty) {
      setShowConfrmationDialog(true);
    } else {
      onHide();
    }
  };
  const { language } = useSelector((state) => state.auth);
  return (
    <Modal
      size="lg"
      show={showCustomerAddDialoag}
      onHide={handleClose}
      aria-labelledby="example-modal-sizes-title-lg"
      // className="modal-drawer"
      className={`${language === "arabic" ? "modal-dialog-arabic modal-drawer" : " modal-drawer"}  my-profile invoice-drawer`}
    >
      {/* <CustomerEditDialogHeader
        id={id}
        view={view}
        onHide={onHide}
        showCustomerAddDialoag={showCustomerAddDialoag}
        setShowCustomerAddDialoag={setShowCustomerAddDialoag}
      /> */}
      <CustomerEditForm
        setShowCustomerDeleteDialog={setShowCustomerDeleteDialog}
        saveCustomer={saveCustomer}
        actionsLoading={actionsLoading}
        customer={
          customerForEdit && customerForEdit._id
            ? customerForEdit
            : {
                ...initCustomer,
                firstName: invoiceCustomerName.customerFirstName ? invoiceCustomerName.customerFirstName : "",
                lastName:
                  invoiceCustomerName.customerSecondName && invoiceCustomerName.customerThirdName
                    ? invoiceCustomerName.customerSecondName + " " + invoiceCustomerName.customerThirdName
                    : invoiceCustomerName.customerSecondName
                    ? invoiceCustomerName.customerSecondName
                    : "",
              }
        }
        view={view}
        onHide={onHide}
        showCustomerAddDialoag={showCustomerAddDialoag}
        setShowCustomerAddDialoag={setShowCustomerAddDialoag}
        setIsFormDirty={setIsFormDirty}
        showConfrmationDialog={showConfrmationDialog}
        setShowConfrmationDialog={setShowConfrmationDialog}
      />
      <SubscriptionDialog
        show={showCustomerDeleteDialog}
        onHide={() => setShowCustomerDeleteDialog(false)}
        modalWidth="350px"
        modalHeight="185px"
        message={
          customersUIProps?.ids?.length === 1 ? i18n("Customer.AreYouSureToDeleteSingleCustomer") : i18n("Customer.AreYouSureToDeleteCustomers")
        }
        messageWidth="320px"
        messageHeight="44px"
        messageColor="#333333"
        messageFontWeight="400"
        messageFontSize="16px"
        messageLineHeight="21.79px"
        primaryAction={() => setShowCustomerDeleteDialog(false)}
        primaryLabel={i18n("SubscriptionModals.CANCEL")}
        primaryLabelWidth="55px"
        primaryLabelHeight="20px"
        primaryLabelFontWeight="700"
        primaryLabelFontSize="14px"
        primaryLabelLineHeight="20px"
        primaryLabelColor="#828282"
        secondaryAction={confirmDelete}
        secondaryLabel={i18n("Discount.DELETE")}
        secondaryLabelWidth="67px"
        secondaryLabelHeight="20px"
        secondaryLabelFontWeight="700"
        secondaryLabelFontSize="14px"
        secondaryLabelLineHeight="20px"
        secondaryLabelColor="#EB5757"
      />
    </Modal>
  );
}
