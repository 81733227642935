import React, { useState, useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import IconClose from "../../../../assets/img/icon-close.svg";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import { i18n } from "../../private/languageSelector";
import FormControl from "@material-ui/core/FormControl";
import { useProductsUIContext } from "../ProductsUIContext";
import FilterIcon from "../../../../assets/img/Icon-filter.png";
import * as storesActions from "../../../../_redux/stores/storeActions";
import * as categoryActions from "../../../../_redux/category/categoryActions";
import * as productsActions from "../../../../_redux/products/productsActions";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 300,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export const FilterDialogeProducts = ({
  title,
  classes,
  size = "sm",
  show = false,
  setFilterActive,
  primaryActionTitle = "yes",
  secondaryAction = () => {},
}) => {
  const dispatch = useDispatch();
  const classess = useStyles();

  const productsUIContext = useProductsUIContext();
  const productsUIProps = useMemo(() => {
    return {
      queryParams: productsUIContext.queryParams,
    };
  }, [productsUIContext]);

  const [state, setState] = useState({
    store: "",
    status: "",
    category: "",
  });

  const statusType = [
    { label: "Active ", value: true },
    { label: "In-Active", value: false },
  ];

  const { category, filter, stores, productFilterState } = useSelector(
    (state) => ({
      stores: state?.stores?.entities,
      filter: state?.products?.filterOn,
      productFilterState: state?.products?.productFilterState,
      category: state?.category?.categoryForEdit,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(storesActions.fetchStores());
    dispatch(categoryActions.fetchCategories());
  }, [dispatch]);

  useEffect(() => {
    setFilterActive(filter);
    // eslint-disable-next-line
  }, [filter]);

  const handleChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });
  };

  const handleFilter = () => {
    dispatch(productsActions.filterProducts(state, productsUIProps.queryParams));
    dispatch(productsActions.setProductFilterState(state));
    dispatch(productsActions.setProductFilterOnState(true));
    secondaryAction();
    setFilterActive(true);
  };
  const clerFilterHandler = () => {
    dispatch(productsActions.clearProductFilterState());
    dispatch(productsActions.setProductFilterOnState(false));
    dispatch(
      productsActions.fetchProducts(
        {
          category: "",
          status: "",
          store: "",
        },
        productsUIProps.queryParams
      )
    );
    setState({
      category: "",
      status: "",
      store: "",
    });
    secondaryAction();
    setFilterActive(false);
  };
  const { language } = useSelector((state) => state.auth);

  return (
    <>
      {/* <Modal
        size={size}
        show={show}
        backdrop={true}
        onHide={() => {}}
        className={`f-confirmation-modal ${classes}`}
        aria-labelledby="example-modal-sizes-title-lg"> */}
      {/* <Modal.Header closeButton>
          <button type="button" className="btn btn-close" onClick={() => secondaryAction(false)}>
            <img src={IconClose} alt="loading" />
          </button>
          <Modal.Title id="example-modal-sizes-title-lg">{title}</Modal.Title>
        </Modal.Header> */}
      {/* <Modal.Body className="overlay overlay-block cursor-default">
          <br />
          <br /> */}
      {show ? (
        <div className={` ${language === "arabic" ? "product-filter-wrapper-arabic" : "product-filter-wrapper"}`}>
          {" "}
          <form>
            {/* <div
              type="submit"
              className="mb-5"
              onClick={() => {
                clerFilterHandler();
              }}
            >
              <img src={FilterIcon} alt="loading" style={{ cursor: "pointer", height: "25px" }} />
              <span
                style={{
                  color: "#2D9CDB",
                  fontSize: "16px",
                  cursor: "pointer",
                }}
              >
                {i18n("MENU.ClearAllFilters")}
              </span>
            </div> */}
            <FormControl className={classess.formControl}>
              {/* <InputLabel  htmlFor="status-native-simple">{i18n("MENU.Status")}</InputLabel> */}
              <div className="d-flex" style={{ gap: "1rem", alignItems: "center" }}>
                <div>{i18n("MENU.Status")}</div>
                <Select
                  native
                  displayEmpty
                  value={state.status}
                  onChange={handleChange}
                  inputProps={{
                    name: "status",
                    id: "status-native-simple",
                    "aria-label": "Without label",
                  }}
                  className="w-100"
                  style={{
                    border: "1px solid #E0E0E0",
                    marginLeft: language === "english" && "1.7rem",
                    marginRight: language === "arabic" && "1.7rem",
                  }}
                  variant="outlined"
                >
                  <option value="" disabled>
                    {i18n("global.Select")}{" "}
                  </option>
                  {statusType.map((type, i) => (
                    <option key={i} value={type.value}>
                      {type.label}
                    </option>
                  ))}
                </Select>
              </div>
            </FormControl>
            <FormControl className={classess.formControl}>
              <div className="d-flex" style={{ gap: "1rem", alignItems: "center" }}>
                <div>{i18n("global.Category")}</div>
                <Select
                  native
                  displayEmpty
                  value={state.category}
                  onChange={handleChange}
                  inputProps={{
                    name: "category",
                    id: "category-native-simple",
                    "aria-label": "Without label",
                  }}
                  className="w-100"
                  style={{ border: "1px solid #E0E0E0", marginRight: language === "arabic" && "2rem" }}
                  variant="outlined"
                >
                  <option value="" disabled>
                    {i18n("global.Select")}{" "}
                  </option>{" "}
                  {category?.map((item, i) => (
                    <option key={i} value={item?._id}>
                      {item?.name}
                    </option>
                  ))}
                </Select>
              </div>
            </FormControl>
            <FormControl className={classess.formControl}>
              {/* <InputLabel htmlFor="store-native-simple">{i18n("MENU.Store")}</InputLabel> */}
              <div className="d-flex" style={{ gap: "1rem", alignItems: "center" }}>
                <div>{i18n("Discount.Store")}</div>
                <Select
                  native
                  value={state.store}
                  onChange={handleChange}
                  inputProps={{
                    name: "store",
                    id: "store-native-simple",
                  }}
                  className="w-100"
                  style={{
                    border: "1px solid #E0E0E0",
                    marginLeft: language === "english" && "2rem",
                    marginRight: language === "arabic" && "1.7rem",
                  }}
                  variant="outlined"
                >
                  <option value="" disabled>
                    {i18n("global.Select")}{" "}
                  </option>{" "}
                  {stores?.map((item, i) => (
                    <option key={i} value={item?._id}>
                      {item?.name}
                    </option>
                  ))}
                </Select>
              </div>
            </FormControl>
          </form>
          <div className="d-flex my-4 justify-content-between align-items-center" style={{ borderRadius: "5px" }}>
            <button
              style={{
                color: "#828282",
                fontSize: "16px",
                cursor: "pointer",
                outline: "none",
                border: "none",
                fontWeight: "700",
                background: "transparent",
              }}
              onClick={() => clerFilterHandler()}
            >
              {i18n("global.CLEAR")}{" "}
            </button>
            <button
              type="submit"
              style={{
                color: state?.category?.length > 0 || state?.status?.length > 0 || state?.store?.length > 0 ? "#2D9CDB" : "#2D9CDB80",
                fontSize: "16px",
                cursor: "pointer",
                outline: "none",
                border: "none",
                fontWeight: "700",
                background: "transparent",
                pointerEvents: state?.category?.length > 0 || state?.status?.length > 0 || state?.store?.length > 0 ? "all" : "none",
              }}
              onClick={() => handleFilter()}
            >
              {i18n("MENU.Apply")}
            </button>
          </div>
        </div>
      ) : null}

      {/* </Modal.Body> */}
      {/* <Modal.Footer>
          <div className="text-center">
            <button type="submit" onClick={() => handleFilter()} className="btn btn-primary btn-elevate mt-5">
              {primaryActionTitle}
            </button>
          </div>
        </Modal.Footer> */}
      {/* </Modal> */}
    </>
  );
};
