/* eslint-disable no-useless-concat */
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import axios from "axios";
import * as Yup from "yup";
import { Card, Input, Mobile, TextArea } from "../../../../_metronic/_partials/controls";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import "react-phone-input-2/lib/style.css";
import "react-phone-number-input/style.css";
import IconEye from "../../../../assets/img/eye.svg";
import { i18n } from "../../private/languageSelector";
import IconView from "../../../../assets/img/IconCopy.svg";
// import IconClose from "../../../../assets/img/icon-close.svg";
import IconDownload from "../../../../assets/img/IconPdf.svg";
import IconClose from "../../../../assets/img/closeIconInvoice.svg";

import { AsYouType } from "libphonenumber-js/max";
import { cloneDeep } from "lodash";

let asYouType = new AsYouType();

Yup.addMethod(Yup.mixed, "validatePhoneNumber", function(msg) {
  return this.test({
    name: "validatePhoneNumber",
    message: msg,
    test: (value) => {
      if (value) {
        asYouType.reset();
        asYouType.input(value);
        return asYouType.getNumber().isValid();
      } else {
        return true;
      }
    },
  });
});
// Validation schema
const pdfInvoiceDialog = Yup.object().shape({
  email: Yup.string()
    .email(i18n("Validation.WrongEmailFormat"))
    .matches(
      // eslint-disable-next-line
      /^\w+[\w-\.]*\@\w+((-\w+)|(\w*))\.[a-z]{2,3}$/,
      "Must contain e.g (example._-@example.com)"
    ),
  mobile: Yup.string().validatePhoneNumber(i18n("Validation.INVALID_PHONE")),
});
const initPDFInvoiceDialog = {
  email: "",
  mobile: "",
  sendViaSMS: "",
  smsMessage: "",
  emailMessage: "",
  sendViaEmail: true,
  // arabicLang: false,
  // englishLang: false,
  invoiceUrl: `${process.env.REACT_APP_URL}view/invoiceHtml/`,
  posInvoiceUrl: `${process.env.REACT_APP_URL}posInvoice/`,
};

function SendPdfInvoiceDialog({ id, secondaryAction, sendPdfInvoiceDialog, setSendPdfInvoiceDialog }) {
  const dispatch = useDispatch();

  const { invoice } = useSelector((state) => ({
    invoice: state?.invoices.invoiceForEdit,
  }));

  const [showSms, setShowSms] = useState(false);
  const [showEmail, setShowEmail] = useState(false);
  const [englishLanguage, setEnglishLanguage] = useState(false);
  const [arabicLanguage, setArabicLanguage] = useState(false);
  const [emailMessage, setEmailMessage] = useState("");
  const [smsMessage, setSmsMessage] = useState("");

  useEffect(() => {
    setEnglishLanguage(invoice?.selectedInvoiceLanguage === "en" ? true : false);
    setArabicLanguage(invoice?.selectedInvoiceLanguage === "ar" ? true : false);
  }, [invoice]);

  let resetFromState;

  let copyToClipboard = (textToCopy) => {
    if (navigator.clipboard && window.isSecureContext) {
      return navigator.clipboard.writeText(textToCopy);
    } else {
      let textArea = document.createElement("textarea");
      textArea.value = textToCopy;
      textArea.style.position = "fixed";
      textArea.style.left = "-999999px";
      textArea.style.top = "-999999px";
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      return new Promise((res, rej) => {
        document.execCommand("copy") ? res() : rej();
        textArea.remove();
      });
    }
  };

  const { language } = useSelector((state) => state.auth);

  const handleChangeInvoiceLanguage = async () => {
    await axios
      .patch(`api/invoice/${invoice?._id}`, { ...invoice, selectedInvoiceLanguage: englishLanguage && !arabicLanguage ? "ar" : "en" })
      .then((res) => {
        toast.success(res.data.message);
      });
  };

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{ initPDFInvoiceDialog }}
        validationSchema={pdfInvoiceDialog}
        onSubmit={(values) => {
          if (values?.sendViaSMS === true && values?.sendViaEmail === true) {
            const smsMessageDetails = {
              recipient: "+923364184194",
              sender: "+19042951659",
              content:
                invoice?.invoiceData?.channel === "web"
                  ? smsMessage + " " + "Your Invoice URL is here " + initPDFInvoiceDialog.invoiceUrl + id
                  : smsMessage + " " + "Your Invoice URL is here " + initPDFInvoiceDialog.posInvoiceUrl + id,
              invoiceLanguage: englishLanguage && !arabicLanguage ? "en" : "ar",
            };
            const emailMessageDetails = {
              recipient: values.email,
              subject: "suppot@fatoraty.com",
              content:
                emailMessage + invoice?.invoiceData?.channel === "web"
                  ? "Your Invoice URL is here " + initPDFInvoiceDialog.invoiceUrl + id
                  : "Your Invoice URL is here " + initPDFInvoiceDialog.posInvoiceUrl + id,
              invoiceLanguage: englishLanguage && !arabicLanguage ? "en" : "ar",
            };
            dispatch(
              axios
                .post("api/message", smsMessageDetails)
                .then(
                  dispatch(
                    axios
                      .post("api/email", emailMessageDetails)
                      .then(
                        toast.success("Url send Via Email and SMS"),
                        resetFromState.resetForm(initPDFInvoiceDialog),
                        setEmailMessage(""),
                        setSmsMessage("")
                      )
                  )
                )
            );
          } else if (values?.sendViaEmail === true) {
            const emailMessageDetails = {
              recipient: values.email,
              subject: "suppot@fatoraty.com",
              content:
                invoice?.invoiceData?.channel === "web" ? `${initPDFInvoiceDialog.invoiceUrl + id}` : `${initPDFInvoiceDialog.posInvoiceUrl + id}`,
              invoiceId: id,
              invoiceLanguage: englishLanguage && !arabicLanguage ? "en" : "ar",
            };

            dispatch(
              axios
                .post("api/email", emailMessageDetails)
                .then(toast.success("Url send Via Email"), resetFromState.resetForm(initPDFInvoiceDialog), setEmailMessage(""))
            );
          } else if (values?.sendViaSMS === true) {
            const smsMessageDetails = {
              recipient: "+923364184194",
              sender: "+19042951659",
              content:
                invoice?.invoiceData?.channel === "web"
                  ? smsMessage + " " + "Your Invoice URL is here " + initPDFInvoiceDialog.invoiceUrl + id
                  : smsMessage + " " + "Your Invoice URL is here " + initPDFInvoiceDialog.posInvoiceUrl + id,
              invoiceLanguage: englishLanguage && !arabicLanguage ? "en" : "ar",
            };
            dispatch(
              axios
                .post("api/message", smsMessageDetails)
                .then(toast.success("Url send Via SMS"), resetFromState.resetForm(initPDFInvoiceDialog), setSmsMessage(""))
            );
          }
        }}
      >
        {({ handleSubmit, values, setValues, setFieldValue, errors, ...rest }) => {
          resetFromState = rest;
          return (
            <>
              <Form className="form form-label-right">
                <Modal
                  className={`${language === "arabic" ? "modal-dialog-arabic modal-drawer" : " modal-drawer"}  my-profile invoice-drawer`}
                  size="lg"
                  show={sendPdfInvoiceDialog}
                  onHide={() => {}}
                  aria-labelledby="example-modal-sizes-title-lg"
                >
                  {/* <Modal.Header>
                    <button
                      type="button"
                      className="btn btn-close"
                      onClick={() => {
                        setSendPdfInvoiceDialog(false);
                        setShowEmail(false);
                        setShowSms(false);
                      }}>
                      <img src={IconClose} alt="loading" />
                    </button>

                    {<Modal.Title>{i18n("INVOICES.ShareInvoice")}</Modal.Title>}
                  </Modal.Header> */}
                  <div className="bg-white">
                    <div className="d-flex align-items-center p-4">
                      <div
                        onClick={() => {
                          setSendPdfInvoiceDialog(false);
                          setShowEmail(false);
                          setShowSms(false);
                        }}
                        className="cursor-pointer"
                      >
                        <img src={IconClose} alt="close" />
                      </div>

                      <Modal.Title className="ml-auto mr-auto custom-text-invoice" style={{ color: "#3f4254" }}>
                        {i18n("INVOICES.ShareInvoice")}
                      </Modal.Title>
                    </div>
                  </div>

                  <Modal.Body className="overlay overlay-block cursor-default" style={{ background: "#F2F2F2" }}>
                    <Card className="rounded mx-1">
                      <div className={`row ${language === "arabic" ? "flex-row-reverse" : "flex-row"}`}>
                        {/* <div className="col-lg-12">
                          <p>{i18n("INVOICES.CopyOrPreviewInvoiceLink")}</p>
                        </div> */}
                        <div
                          className="col-7 p-3 d-flex justify-content-center align-items-center"
                          style={{ border: "1px solid rgb(242, 242, 242)" }}
                        >
                          <small>{`${process.env.REACT_APP_URL}view/invoice/${id}`?.slice(0, 20) + "..."}</small>
                          <div className="cursor-pointer ml-5">
                            <img
                              onClick={() => {
                                // eslint-disable-next-line no-lone-blocks
                                {
                                  invoice?.invoiceData?.channel === "web"
                                    ? copyToClipboard(`${process.env.REACT_APP_URL}view/invoiceHtml/${id}`)
                                    : copyToClipboard(`${process.env.REACT_APP_URL}posInvoice/${id}`);
                                }
                                toast.success("Copied to clipboard");
                              }}
                              src={IconView}
                              alt="loading"
                            />
                          </div>
                        </div>
                        <div className="d-flex justify-content-between align-items-center col">
                          <div
                            className="ml-4 cursor-pointer"
                            onClick={() => {
                              // eslint-disable-next-line no-lone-blocks
                              {
                                invoice?.invoiceData?.channel === "web"
                                  ? window.open(`/view/invoiceHtml/${id}`, "_blank")
                                  : window.open(`/posInvoice/${id}`, "_blank");
                              }
                            }}
                          >
                            <img src={IconEye} alt="loading" />
                          </div>
                          <div
                            className=" mr-3 cursor-pointer"
                            onClick={(e) => {
                              e.preventDefault();
                              window.open(`${process.env.REACT_APP_URL}view/invoice/${id}`, "_blank");
                            }}
                          >
                            <img src={IconDownload} alt="loading" />
                          </div>
                        </div>
                      </div>
                    </Card>

                    <Card className="rounded mx-1">
                      <div className={`row ${language === "arabic" ? "flex-row-reverse text-right" : "text-left flex-row"}`}>
                        <div className={`col-lg-12 $`}>
                          <p>{i18n("INVOICES.InvoiceLanguage")}</p>
                        </div>
                        <div className={`col-6 d-flex ${language === "arabic" ? "flex-row-reverse" : "flex-row"}`}>
                          <Field
                            type="radio"
                            // name="engishLang"
                            value={englishLanguage}
                            checked={englishLanguage}
                            onChange={(e) => {
                              setEnglishLanguage((p) => !p);
                              setArabicLanguage(false);
                              handleChangeInvoiceLanguage();
                            }}
                            style={{ textAlign: language === "arabic" ? "end" : "start" }}
                          />
                          <label className={`${language === "arabic" ? "mr-4" : "ml-4"}`}>{i18n("INVOICES.ENGLISH")}</label>
                        </div>
                        <div className={`col d-flex ${language === "arabic" ? "flex-row-reverse" : "flex-row"}`}>
                          <Field
                            type="radio"
                            // name="arabicLang"
                            checked={arabicLanguage}
                            value={arabicLanguage}
                            onChange={(e) => {
                              setArabicLanguage((p) => !p);
                              setEnglishLanguage(false);
                              handleChangeInvoiceLanguage();
                            }}
                            style={{ textAlign: language === "arabic" ? "end" : "start" }}
                          />
                          <label className={`${language === "arabic" ? "mr-4" : "ml-4"}`}>{i18n("INVOICES.ARABIC")}</label>
                        </div>
                      </div>
                    </Card>

                    <Card className="rounded mx-1">
                      <div className={`row ${language === "arabic" ? "flex-row-reverse text-right" : "text-left flex-row"}`}>
                        <div className="col-lg-12">
                          <p>{i18n("global.ShareVia")}</p>
                        </div>
                        <div className={`col d-flex ${language === "arabic" ? "flex-row-reverse" : "flex-row"}`}>
                          <Field
                            name="sendViaEmail"
                            type="radio"
                            onChange={(e) => {
                              setFieldValue("sendViaEmail", e.target.checked);
                              setShowEmail((p) => !p);
                              setShowSms(false);
                            }}
                            value={showEmail}
                            style={{ textAlign: language === "arabic" ? "end" : "start" }}
                          />
                          <label className={`${language === "arabic" ? "mr-4" : "ml-4"}`}>{i18n("global.Email")}</label>
                        </div>
                        <div className={`col d-flex ${language === "arabic" ? "flex-row-reverse" : "flex-row"}`}>
                          <Field
                            name="sendViaSMS"
                            type="radio"
                            onChange={(e) => {
                              setFieldValue("sendViaSMS", e.target.checked);
                              setShowSms((p) => !p);
                              setShowEmail(false);
                            }}
                            value={showSms}
                            style={{ textAlign: language === "arabic" ? "end" : "start" }}
                          />
                          <label className={`${language === "arabic" ? "mr-4" : "ml-4"}`}>{i18n("INVOICES.Sms")}</label>
                        </div>

                        <>
                          {showEmail ? (
                            <>
                              <div className="col-lg-12 mt-4 form-group">
                                <Field
                                  style={{
                                    textAlign: language === "arabic" ? "end" : "start",
                                    paddingRight: language === "arabic" && "0px",
                                    right: 0,
                                  }}
                                  type="email"
                                  name="email"
                                  label={i18n("global.Email")}
                                  isArabicStyle={true}
                                  placeholder={i18n("global.Email")}
                                  component={Input}
                                />
                              </div>
                              <div className="col-lg-12 mt-4 form-group">
                                {emailMessage?.length > 0 ? (
                                  <div
                                    className={`${
                                      document?.activeElement?.name === "emailMessage" ? "store-small-text-active" : "store-small-text-normal"
                                    }`}
                                  >
                                    {i18n("global.Message")}
                                  </div>
                                ) : (
                                  <div className="store-small-text"> {i18n("global.Message")}</div>
                                )}
                                <Field
                                  name="emailMessage"
                                  label={i18n("global.Message")}
                                  placeholder={i18n("global.Message")}
                                  component={TextArea}
                                  style={{
                                    textAlign: language === "arabic" ? "end" : "start",
                                    paddingRight: language === "arabic" && "0px",
                                    fieldSizing: "content",
                                    height: "auto",
                                  }}
                                  value={emailMessage}
                                  onChange={(e) => {
                                    setEmailMessage(e?.target?.value);
                                  }}
                                />
                              </div>
                            </>
                          ) : showSms ? (
                            <>
                              <div className="col-lg-12 mt-4 form-group w-100 " style={{ paddingRight: language === "arabic" && "0px" }}>
                                <Field
                                  placeholder="Enter mobile"
                                  label="Mobile"
                                  component={Mobile}
                                  name="mobile"
                                  international
                                  defaultCountry="SA"
                                  style={{
                                    display: "flex",
                                    flexDirection: language === "arabic" ? "row-reverse" : "row",
                                    paddingRight: language === "arabic" && "0px",
                                    marginRight: "0px",
                                    width: "100%",
                                  }}
                                  value={values.mobile}
                                  onChange={(value) => {
                                    setValues({ ...values, mobile: value });
                                  }}
                                />
                                {errors?.mobile?.length > 0 ? (
                                  <span style={{ fontSize: "0.9rem", color: "#F64E60" }}>{i18n("Validation.INVALID_PHONE")}</span>
                                ) : (
                                  ""
                                )}
                              </div>
                              <div className={`col-lg-12 mt-4 form-group ${language === "arabic" ? "text-end" : "text-start"}`}>
                                {smsMessage?.length > 0 ? (
                                  <div
                                    className={`${
                                      document?.activeElement?.name === "smsMessage" ? "store-small-text-active" : "store-small-text-normal"
                                    }`}
                                  >
                                    {i18n("global.Message")}
                                  </div>
                                ) : (
                                  <div className="store-small-text"> {i18n("global.Message")}</div>
                                )}
                                <Field
                                  name="smsMessage"
                                  label={i18n("global.Message")}
                                  placeholder={i18n("global.Message")}
                                  component={TextArea}
                                  style={{ textAlign: language === "arabic" ? "end" : "start", fieldSizing: "content", height: "auto" }}
                                  value={smsMessage}
                                  onChange={(e) => {
                                    setSmsMessage(e?.target?.value);
                                  }}
                                />
                              </div>
                            </>
                          ) : null}
                          {showEmail || showSms ? (
                            <button
                              type="submit"
                              onClick={() => {
                                handleSubmit();
                                secondaryAction();
                                setSendPdfInvoiceDialog(false);
                                setShowEmail(false);
                                setShowSms(false);
                              }}
                              className={`border-0 bg-transparent ${language === "arabic" ? "mr-auto" : "ml-auto"}`}
                              style={{
                                color:
                                  (showEmail && !showSms && (values?.email?.length === 0 || !values?.email)) ||
                                  (showSms && !showEmail && (values?.mobile?.length === 0 || !values?.mobile || errors?.mobile?.length > 0))
                                    ? "#2D9CDB80"
                                    : "#2D9CDB",
                                pointerEvents:
                                  (showEmail && !showSms && (values?.email?.length === 0 || !values?.email)) ||
                                  (showSms && !showEmail && (values?.mobile?.length === 0 || !values?.mobile || errors?.mobile?.length > 0))
                                    ? "none"
                                    : "auto",
                                fontWeight: 700,
                                textAlign: language === "arabic" ? "start" : "end",
                              }}
                            >
                              {i18n("INVOICES.SEND")}
                            </button>
                          ) : null}
                        </>
                      </div>
                    </Card>
                  </Modal.Body>
                </Modal>
              </Form>
            </>
          );
        }}
      </Formik>
    </>
  );
}
export default SendPdfInvoiceDialog;
