import React from "react";
import "./ToggleSwitch.scss";

export const ToggleSwitch = ({ currency, toggleSelected, selected }) => {
  return (
    <div className="toggle-container" onClick={toggleSelected}>
      <div className={`dialog-button ${selected ? "" : "disabled"}`}>{selected ? (currency ? currency : "123") : "%"}</div>
      {/* <span className="percentage text-muted text-center">%</span> */}
      <span className="percentage text-muted text-center"> {!selected ? currency : "%"}</span>
      <span className="currency text-muted text-center">{!selected && currency ? currency : "%"}</span>
    </div>
  );
};
